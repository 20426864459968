<template>
<div>

    <div class="row mt-3 mb-5">
        <label for="password" class="col-sm-3 font-weight-bold col-form-label">{{$t('user.password')}}</label>
        <div class="col-sm-9">
            <b-form-input type="password" id="password" class="form-control" :state="validateState($v.oldPassword)" :lazy="true" :trim="true" v-model="$v.oldPassword.$model" />
            <div class="invalid-feedback" v-if="!$v.oldPassword.required">{{$t('user.passwordRequired')}}</div>
        </div>
    </div>

    <div class="row mt-3 mb-3">
        <label for="new-password" class="col-sm-3 font-weight-bold col-form-label">{{$t('user.newPassword')}}</label>
        <div class="col-sm-9">
            <b-form-input type="password" id="new-password" class="form-control" :state="validateState($v.newPassword1)" :trim="true" v-model="$v.newPassword1.$model" />
            <div class="invalid-feedback" v-if="!$v.newPassword1.notSameAs">{{$t('user.newPasswordDifferent')}}</div>
            <div class="invalid-feedback" v-if="!$v.newPassword1.required">{{$t('user.passwordRequired')}}</div>
            <div class="invalid-feedback" v-if="!$v.newPassword1.minLength">{{$t('user.passwordAtLeast')}} {{ $v.newPassword1.$params.minLength.min }} {{$t('general.characters')}}</div>
        </div>

    </div>

    <div class="row mt-3 mb-3">
        <label for="confirm-password" class="col-sm-3 font-weight-bold col-form-label">{{$t('user.confirmPassword')}}</label>
        <div class="col-sm-9">
            <b-form-input type="password" id="confirm-password" class="form-control" :state="validateState($v.newPassword2)" :trim="true" v-model="$v.newPassword2.$model" />
            <div class="invalid-feedback" v-if="!$v.newPassword2.required">{{$t('user.passwordRequired')}}</div>
            <div class="invalid-feedback" v-if="!$v.newPassword2.sameAsPassword">{{$t('user.passwordNotIdentical')}}</div>
        </div>
    </div>

    <div class="fixed-top mt-3 d-flex justify-content-center">
        <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
            {{ feedbackMessage }}
        </b-alert>
    </div>



    <div class="float-right">
        <button type="button" class="btn btn-danger btn-sm mr-3" @click="close()">{{$t('general.cancel')}}</button>
        <button type="button" class="btn btn-primary btn-sm" @click="saveNewPassword()">{{$t('general.save')}}</button>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'
import Feedback from '../mixins/Feedback.js'
import {
    required,
    minLength,
    not,
    sameAs
} from 'vuelidate/lib/validators'

export default {
    name: 'ChangePassword',

    mixins: [Feedback, CommonVariablesAndFunctions],

    data() {
        return {
            oldPassword: "",
            newPassword1: "",
            newPassword2: ""
        }
    },
    props: {
        userName: {
            type: String,
            required: true
        }
    },
    methods: {
        saveNewPassword() {

            this.$v.$touch();
            if (this.$v.$anyError) {
                return;
            }

            let newPassword = {
                userName: this._props.userName,
                oldPassword: this.oldPassword,
                newPassword1: this.newPassword1,
                newPassword2: this.newPassword2
            }

            axios.put('user/changePassword', newPassword)
                .then(() => {
                    this.succesAlert()
                    this.close();
                })
                .catch((error) => {
                    this.errorAlert(error);
                })

        },

        close() {
            this.$root.$emit('bv::hide::modal', 'password')
        }

    },
    computed: {

    },
    validations: {

        oldPassword: {
            required
        },
        newPassword1: {
            required,
            minLength: minLength(6),
            notSameAs: not(sameAs(function() {
                return this.oldPassword
            }))
        },
        newPassword2: {
            required,
            sameAsPassword: sameAs(function() {
                return this.newPassword1
            })
        }
    }
}
</script>



<style scoped>


</style>
