import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {

    data() {
        return {

        }
    },

    methods: {
        FormulatPdf() {

            let client = this.client;
            let project = this.project;
            let product = this.selectedProduct;
            let formula = this.formula;
            let formulationData = this.gridFormulationData;
            let formulaQuantity = this.toWeight;
            let formulaStatus = this.formulaStatus;
            let price = this.totalPrice;

            //translation variables
            let clientNameTrad = this.$t('general.clientName');
            let phaseTrad = this.$t('formulation.phase');
            let materialIdTrad = 'ID';
            let tradeNameTrad = this.$t('material.tradeName');
            let inciTrad = "INCI";
            let percentageTrad = '%';
            let toWeightTrad = this.$t('formulation.quantityToWeight');
            let weighedTrad = this.$t('formulation.weighedQuantity');
            let lotTrad = this.$t('formulation.lotNumber');
            let versionTrad = this.$t('formulation.version');
            let formulaNameTrad = this.$t('formulation.formulationName');
            let statusTrad = this.$t('general.status');
            let projectNameTrad = this.$t('project.projectName');
            let productNameTrad = this.$t('product.productName');
            let testQuantityTrad = this.$t('formulation.testQt');
            let processTrad = this.$tc('process.process', 1);
            let priceTrad = this.$t('general.priceKg');

            function status() {
                let status = formulaStatus.filter(status => status.id == formula.status)[0].name;
                return status
            }

            function createBody() {
                var body = []
                for (var i = 0; i < formulationData.length; i++) {
                    body.push({
                        phase: formulationData[i].phase,
                        materialId: formulationData[i].prefixId,
                        tradeName: formulationData[i].tradeName.name,
                        inci: formulationData[i].inci.join('; '),
                        percentage: formulationData[i].percentage,
                        toWeight: (formulationData[i].percentage / 100 * formulaQuantity).toFixed(3),
                        Weighed: '',
                        lot: ''
                    })
                }

                return body.sort((a,b) => (a.phase > b.phase) ? 1 : ((b.phase > a.phase) ? -1 : 0));

            }

            function createHeader() {
                return [{
                    phase: phaseTrad,
                    materialId: materialIdTrad,
                    tradeName: tradeNameTrad,
                    inci: inciTrad,
                    percentage: percentageTrad,
                    toWeight: toWeightTrad,
                    Weighed: weighedTrad,
                    lot: lotTrad
                }, ]
            }

            function createHeaderProcess() {
                let table = JSON.parse(formula.process)

                var header = []
                for (var i = 0; i < table[0].length; i++) {
                    header.push(table[0][i])
                }

                return [header]
            }

            function createBodyProcess() {
                let table = JSON.parse(formula.process)

                var body = table.slice(1)

                return body
            }

            const doc = new jsPDF({
                format: 'letter',
                unit: 'mm',
                orientation: 'L'
            })

            doc.setFontSize(16)
            doc.setTextColor(82, 128, 120)
            doc.text('Galenit', 250, 15)

            doc.setTextColor(0)
            doc.setFontSize(10)
            doc.setFont('helvetica', 'bold');
            doc.text('ID', 15, 25)
            doc.text(versionTrad, 60, 25)
            doc.text(formulaNameTrad, 100, 25)
            doc.text(statusTrad, 200, 25)
            doc.setFont('helvetica', 'normal');
            doc.text(formula.prefixId, 15, 32)
            doc.text(formula.version.toString(), 60, 32)
            doc.text(formula.name, 100, 32)
            doc.text(status(), 200, 32)

            doc.setFontSize(10)
            doc.setFont('helvetica', 'bold');
            doc.text(projectNameTrad, 15, 45)
            doc.text(productNameTrad, 100, 45)
            doc.text(clientNameTrad, 200, 45)
            doc.setFont('helvetica', 'normal');
            doc.text(project.projectName, 15, 52)
            doc.text(product.productName, 100, 52)
            doc.text(client.companyName, 200, 52)

            doc.setFontSize(10)
            doc.setFont('helvetica', 'bold');
            doc.text(testQuantityTrad, 15, 65)
            doc.setFont('helvetica', 'normal');
            doc.text(formulaQuantity, 60, 65)

            doc.setFontSize(10)
            doc.setFont('helvetica', 'bold');
            doc.text(priceTrad, 100, 65)
            doc.setFont('helvetica', 'normal');
            doc.text(price, 120, 65)


            doc.autoTable({
                startY: 70,
                headStyles: {
                    fillColor: [82, 128, 120],
                },
                head: createHeader(),
                body: createBody(),
            })

            doc.setFontSize(10)
            doc.setFont('helvetica', 'bold');
            doc.text(processTrad, 15, doc.lastAutoTable.finalY + 10)
            doc.setFont('helvetica', 'normal');

            doc.autoTable({
                startY: doc.lastAutoTable.finalY + 15,
                headStyles: {
                    fillColor: [82, 128, 120],
                },
                head: createHeaderProcess(),
                body: createBodyProcess(),
            })

            doc.save(formula.prefixId + 'v' + formula.version + ' - formula.pdf')

        },
    }
};
