<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h2 class="text-left">{{$tc('general.formulation', 1)}}</h2>
                <LayoutCard :title="$t('prefix.prefix')" active="true">
                    <div class="loader" v-if="loading">

                    </div>

                    <fieldset :disabled="loading">
                        <form @change="save()">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputName">{{ $t('general.name') }}</label>
                                    <b-form-input v-can:input="'Update_MaterialPrefix'" type="text" class="form-control" id="inputName" :state="validateState($v.objectData.name)" :placeholder="$t('general.name')" v-model="objectData.name" @input="getName(objectData.name)" :trim="true" />
                                    <div class="invalid-feedback" v-if="!$v.objectData.name.isNameUnique">{{$t('prefix.prefixAlreadyExist')}}</div>
                                </div>
                            </div>
                        </form>
                    </fieldset>

                </LayoutCard>

                <div v-if="isMod">
                    <SaveCancelDeleteButtons permission="Create_MaterialPrefix" @clicked="getClicked" @isMod="isMod" :isError="$v.$anyError"></SaveCancelDeleteButtons>
                </div>

                <div class="fixed-top mt-3 d-flex justify-content-center">
                    <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                        {{ feedbackMessage }}
                    </b-alert>
                </div>

            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import LayoutCard from '../components/LayoutCard.vue'
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

function isNameUnique(value) {
    if (this.prefixes.filter(e => e.name.toLowerCase() === value.toLowerCase()).length == 0) {
        return true
    }
    return false
}

window.axios = require("axios");

export default {
    name: 'MaterialPrefix',

    mixins: [Feedback, CommonVariablesAndFunctions],

    components: {
        LayoutCard,
        SaveCancelDeleteButtons,
    },

    data() {
        return {
            title: "materialPrefix",
            endpoint: "materialPrefix",
            value: "",

            objectData: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: null,
                name: "",
            },

            prefixes: []
        };
    },
    methods: {

        getData() {
            this.loading = true;

        },

        processForm() {

            let prefix = {
                name: this.objectData.name,
            };
            return prefix

        },

        postData(endpoint, data) {
            axios
                .post(endpoint, data)
                .then(() => {
                    this.isMod = false
                    this.succesAlert()
                    this.$router.push('/setup-prefix/material');
                })
                .catch((error) => {
                    this.errorAlert(error)
                })
        },

        async getName(input) {
            if (input.length > 1) {
                var filter = "/search/findByNameContainingIgnoreCase";
                var paramValue = {
                    "name": input,
                };
                let response = await axios.get("materialPrefix" + filter, {
                    params: paramValue
                })
                this.prefixes = response.data._embedded.materialPrefix;
            }

        },

    },

    created() {
        if (this.$route.params.id) {
            this.getData();
        } else {
            this.loading = false;
        }
    },

    validations: {
        objectData: {
            name: {
                isNameUnique
            }
        }

    }
};
</script>

<style scoped>
.form-group {
    text-align: left;
    font-weight: bold;
}
</style>
