<template>
<div v-can="'Read_Product'">

    <div class="container-fluid">

        <h2 class="text-left"><router-link to="/products">{{$tc('general.product', 1)}}</router-link></h2>

        <LayoutCard :title="$tc('general.informations', 1)" active="true">

            <template v-slot:header-options>
                <DeleteButton v-can="'Delete_Product'" endpoint="product" :dataToDisplay="product.productName" searchValue="null" :relationship="$store.state.product.associatedData"></DeleteButton>
            </template>

            <div class="loader" v-if="loading">
            </div>

            <fieldset :disabled="loading">
                <form @change="save">

                    <div class="form-row">
                        <div class="form-group col-md-7">
                            <label>{{$t('product.productName')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="text" class="form-control" :placeholder="$t('product.productName')" :state="validateState($v.product.productName)" :lazy="true" :trim="true" v-model="$v.product.productName.$model" />
                            <div class="invalid-feedback" v-if="!$v.product.productName.required">{{$t('errorMsg.required')}}</div>
                            <div class="invalid-feedback" v-if="!$v.product.productName.isProductNameUnique">{{$t('errorMsg.productAlreadyExist')}}</div>

                        </div>

                        <div class="form-group col-md-3">
                            <label>{{$t('product.targetDate')}}</label>
                            <b-form-datepicker v-can:datepicker="'Update_Product'" @input="save" id="example-inputTarget" :placeholder="$t('product.targetDate')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="product.targetDate" class="mb-2">
                            </b-form-datepicker>
                        </div>

                        <div class="form-group col-md-2">
                            <label>{{$t('general.status')}}</label>
                            <v-select id="inputProductStatus" :placeholder="$t('general.selectStatus')" v-model="$v.product.status.$model" :reduce="status => status.id" :selectable="status => status.selectable" :clearable="false" :options="productStatus" label="name" @input="save" :class="validateStatus($v.product.status)"
                                class="vselect">
                            </v-select>
                            <div class="error" id="status-required" v-if="!$v.product.status.required && $v.product.status.$dirty">{{$t('errorMsg.required')}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label v-if='!loading && this.$route.params.id'><router-link :to="'/project/' + selectedProject._links.self.href.split('/').pop()">{{$t('project.projectName')}}</router-link></label>
                            <label v-else>{{$t('project.projectName')}}</label>
                            <span class="ml-2">
                                <font-awesome-icon icon="search" class="btn-icon" @click="showModal('project')" /></span>
                            <b-form-input v-can:input="'Update_Product'" type="text" autocomplete="off" list="projects-list" @input="getProjects(projectName); setProject(null)" :state="validateState($v.projectName)" class="form-control" placeholder="Shampoo, cream..." :trim="true"
                                v-model="projectName" />
                            <div class="invalid-feedback" v-if="!$v.projectName.required">{{$t('errorMsg.required')}}</div>
                            <div class="invalid-feedback" v-if="!$v.projectName.projectNameMustExist">Supplier must exist</div>

                            <datalist id="projects-list">
                                <option v-for="project in projects" :key="project._links.self.href" field-value="project._links.self.href"> {{ project.projectName }}</option>
                            </datalist>

                        </div>

                        <div v-if="isProjectSet == true && $v.projectName.projectNameMustExist" class="form-group col-md-6">
                            <label v-if='!loading && this.$route.params.id'><router-link :to="'/client/' + client._links.self.href.split('/').pop()">{{$tc('general.client',1)}}</router-link></label>
                            <label v-else>{{$tc('general.client',1)}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="text" readonly class="form-control" placeholder="Face, body..." :lazy="true" :trim="true" v-model="client.companyName" />
                        </div>

                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputDescription">{{$t('product.description')}}</label>
                            <b-form-textarea v-can:input="'Update_Product'" rows="5" max-rows="10" debounce="500" class="form-control" id="inputDescription" placeholder="Describe the product" v-model="product.description"></b-form-textarea>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputBenchmark">{{$t('product.benchmark')}}</label>
                            <b-form-textarea v-can:input="'Update_Product'" rows="5" max-rows="10" debounce="500" class="form-control" id="inputBenchmark" placeholder="Describe the product" v-model="product.benchmark"></b-form-textarea>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputClaimst">{{$t('product.claim')}}</label>
                            <b-form-textarea v-can:input="'Update_Product'" rows="5" max-rows="10" debounce="500" class="form-control" id="inputClaimst" placeholder="Claims related to the product" v-model="product.claims"></b-form-textarea>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputParticularity">{{$t('product.particularity')}}</label>
                            <b-form-textarea v-can:input="'Update_Product'" rows="5" max-rows="10" debounce="500" class="form-control" id="inputParticularity" placeholder="Some particularity in this product" v-model="product.particularity"></b-form-textarea>
                        </div>
                    </div>

                    <div class="form-row">
                        <div v-can="'Read_FileManager'" class="form-group col-md-12" v-if="this.$route.params.id && !loading">
                            <label for="inputFiles">{{$t('fileManager.file')}}</label>
                            <FileManager repositoryName="product" :pathIdList="[this.$route.params.id]"
                                acceptExtensions=".doc, .docx, .txt, .pdf, .xls, .xlsx" permission="Update_Product"/>
                        </div>
                    </div>

                </form>
            </fieldset>

        </LayoutCard>

        <LayoutCard :title="$tc('general.formulation', 1)" active="false">

            <div class="loader" v-if="loading">
            </div>

            <fieldset :disabled="loading">
                <form @change="save">

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label>{{$t('product.productType')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="text" class="form-control" placeholder="Shampoo, cream..." :lazy="true" :trim="true" v-model="product.productType" />
                        </div>
                        <div class="form-group col-md-4">
                            <label>{{$t('product.applicationZone')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="text" class="form-control" placeholder="Face, body..." :lazy="true" :trim="true" v-model="product.applicationZone" />
                        </div>
                        <div class="form-group col-md-4">
                            <label>{{$t('product.priceLimit')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="number" :formatter="formatterPositiveValue" step="0.01" class="form-control" placeholder="Set the price limit" :lazy="true" v-model="product.priceLimit" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label>{{$t('product.galenic')}}</label>
                            <b-form-textarea v-can:input="'Update_Product'" rows="5" max-rows="10" debounce="500" class="form-control" id="inputComments" placeholder="Information about the galenic" v-model="product.galenic"></b-form-textarea>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label>{{$t('product.perfume')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="text" class="form-control" placeholder="Perfum description" :lazy="true" :trim="true" v-model="product.perfume" />
                        </div>
                        <div class="form-group col-md-4">
                            <label>{{$t('product.color')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="text" class="form-control" placeholder="Color information" :lazy="true" :trim="true" v-model="product.color" />
                        </div>
                        <div class="form-group col-md-4">
                            <label>{{$t('product.preservative')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="text" class="form-control" placeholder="Preservative to use" :lazy="true" :trim="true" v-model="product.preservative" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>{{$t('product.packaging')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="text" class="form-control" placeholder="Packaging type (jar, bottle...)" :lazy="true" :trim="true" v-model="product.packaging" />
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{$t('product.packTransparency')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="text" class="form-control" placeholder="Is the pack is transparent ?" :lazy="true" :trim="true" v-model="product.transparencyPackage" />
                        </div>
                    </div>

                </form>
            </fieldset>

        </LayoutCard>

        <LayoutCard :title="$t('product.firstFabrication')" active="false">

            <div class="loader" v-if="loading">
            </div>

            <fieldset :disabled="loading">
                <form @change="save">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>{{$t('product.releaseQt')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="number" step="0.1" class="form-control" placeholder="Release quantity" :lazy="true" :trim="true" v-model="product.qntRelease" />
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{$t('product.particularNeeds')}}</label>
                            <b-form-input v-can:input="'Update_Product'" type="number" step=".1" class="form-control" placeholder="Does the client need specific quantity ?" :lazy="true" :trim="true" v-model="product.particularNeed" />
                        </div>
                    </div>
                </form>
            </fieldset>

        </LayoutCard>

        <div v-if="isMod">

            <SaveCancelDeleteButtons permission="Create_Product" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>

        </div>

        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                {{ feedbackMessage }}
            </b-alert>
        </div>

        <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('layoutCard.associatedFormulations')">
            <ListingGrid :column="formulaColumn" :endpoint=' "/product/" + this.$route.params.id + "/formulas" ' dataObject="formula" redirectUrl="/formulation" :parentData="this.$route.params.id"></ListingGrid>
        </LayoutCard>

        <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('general.history')">
            <History :dataType="endpoint" :addBy="product.addBy" :addDate="product.addDate" :lastModBy="product.modBy" :lastMod="product.modDate" />
        </LayoutCard>

    </div>

    <ModalWindow v-show="openedModal === 'project'" :title="$t('general.search')" @close="openedModal = null">
        <MainGrid gridTitle="Project" objectNodeName="entityModels" endpoint="/project" :gridColumns="columns" :filter="filter" formUrl="/project" :queryByFilter="true" :customOrderBy="true" @close="openedModal = null" @selectValue="setProject"></MainGrid>
    </ModalWindow>

</div>
</template>

<script>
import axios from 'axios'
import History from '../components/History.vue'
import LayoutCard from '../components/LayoutCard.vue'
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'
import DeleteButton from '../components/DeleteButton.vue'
import ModalWindow from '../components/ModalWindow.vue';
import MainGrid from '../components/MainGrid.vue';
import ListingGrid from '../components/ListingGrid.vue';
import FileManager from '../components/FileManager';
import moment from 'moment'
import {
    required
} from 'vuelidate/lib/validators';

import {
    mapActions
} from "vuex";

function isProductNameUnique(value) {
    if (this.products.filter(e => e.productName.toLowerCase() === value.toLowerCase()).length == 0) {
        return true
    }
    return false
}

function projectNameMustExist(value) {
    if (this.projects.filter(e => e.projectName === value).length != 0) {
        return true
    }
    return false
}

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

window.axios = require("axios");

export default {
    name: 'Product',
    endpoint: "product",

    mixins: [Feedback, CommonVariablesAndFunctions],

    components: {
        History,
        LayoutCard,
        DeleteButton,
        SaveCancelDeleteButtons,
        MainGrid,
        FileManager,
        ListingGrid,
        ModalWindow,
    },

    data() {
        return {
            title: "Product",
            endpoint: "product",

            openedModal: null,

            projectName: "",
            selectedProject: {},
            isProjectSet: false,
            projects: [],

            client: {},
            clientName: "",

            products: [],

            product: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: null,
                productName: "",
                targetDate: "",
                description: "",
                galenic: "",
                claims: "",
                applicationZone: "",
                productType: "",
                packaging: "",
                transparencyPackage: "",
                qntRelease: null,
                priceLimit: null,
                particularNeed: "",
                particularity: "",
                perfume: "",
                color: "",
                preservative: "",
                benchmark: "",
                status: "",
            },


            columns: [{
                    label: this.$t('project.projectName'),
                    key: "projectName",
                    sortable: true,
                    initial_sort: true
                },
                {
                    label: this.$t('general.clientName'),
                    key: "clientName",
                    sortable: true,
                },
                {
                    label: this.$t('project.targetDate'),
                    key: "targetDate",
                    sortable: true,
                    slot_name: "date"
                },
                {
                    label: this.$t('project.statusName'),
                    key: "statusName",
                    sortable: true,
                    slot_name: "status"
                },
                {
                    key: "returnButton",
                }
            ],
            filter: [{
                method: "findByProjectNameOrTargetDateOrStatusOrClient",
                params: ["searchValue"]
            }],

            formulaColumn: [{
                    key: 'prefixId',
                    sortable: true
                },
                {
                    key: 'version',
                    sortable: true
                },
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'status',
                    sortable: true
                },
                {
                    key: 'goTo',
                    sortable: false
                },
            ]

        };
    },

    computed: {
        targetDateToIso: function() {
            let date = moment(this.product.targetDate).toISOString()
            return date
        },

        productStatus: function() {

            if (this.$store.getters.getStatusFormulaProjectProduct.product.length == 0) {
                this.statusFormulaProjectProduct('product')
            }

            return this.$store.getters.getStatusFormulaProjectProduct.product
        },

    },

    methods: {

        ...mapActions(["statusFormulaProjectProduct"]),

        formatterPositiveValue(value) {
            return Math.abs(value)
        },

        async getData() {
            this.loading = true;

            let response = await axios.get(this.endpoint + '/' + this.$route.params.id)
            this.product = response.data

            //get project Data
            let project = await axios.get(this.product._links.project.href)
            this.selectedProject = project.data
            this.projectName = project.data.projectName

            //this is needed for the validation projectNameMustExist
            this.projects.push(project.data)
            this.$v.projectName.$touch();

            //get client Name
            let client = await axios.get(project.data._links.client.href)
            this.client = client.data
            // this.clientName = client.data.companyName

            this.isProjectSet = true;

            this.loading = false;
            this.isMod = false;

        },


        processForm() {

            let product = {
                productName: this.product.productName.charAt(0).toUpperCase() + this.product.productName.slice(1),
                targetDate: this.targetDateToIso,
                description: this.product.description,
                galenic: this.product.galenic,
                claims: this.product.claims,
                applicationZone: this.product.applicationZone,
                productType: this.product.productType,
                packaging: this.product.packaging,
                transparencyPackage: this.product.transparencyPackage,
                qntRelease: this.product.qntRelease,
                priceLimit: this.product.priceLimit,
                particularNeed: this.product.particularNeed,
                particularity: this.product.particularity,
                perfume: this.product.perfume,
                color: this.product.color,
                preservative: this.product.preservative,
                benchmark: this.product.benchmark,
                status: this.product.status,

                project: this.selectedProject._links.self.href
            };

            return product

        },

        async postData(endpoint, data) {

            try {
                //project informations data
                let product = await axios.post(endpoint, data);

                let id = product.data._links.self.href.split("/").pop();

                this.isMod = false;
                this.succesAlert()
                this.$router.push(this.endpoint + '/' + id);

            } catch (error) {
                console.log(error);
                // this.errorAlert(error);
            }

        },

        async putData(endpoint, data) {
            try {
                //Update informations
                await axios.put(endpoint, data);

                this.isMod = false;
                this.succesAlert()

            } catch (error) {
                this.errorAlert(error);
            }

        },

        async getProjects(projectName) {
            if (projectName.length > 1) {
                var filter = "/search/findByProjectNameContainingIgnoreCaseOrderByProjectName";
                var paramValue = {
                    "projectName": projectName,
                };
                let responseProjects = await axios.get("project" + filter, {
                    params: paramValue
                })
                this.projects = responseProjects.data._embedded.project;
            }
        },


        async setProject(value) {

            //check if the user delete his input
            if (this.projectName.length == 0 && value == null) {
                return
            }

            //if the user type the formulation ID
            if (value == null) {
                this.selectedProject = this.projects.filter(project => project.projectName == this.projectName)[0]
                if (this.selectedProject === undefined) {
                    return
                }
            }
            // if the user use the search function
            else {
                let responseProject = await axios.get(value);
                this.selectedProject = responseProject.data

                this.projects = []
                //this is needed for the validation formulationIdMustExist
                this.projects.push(responseProject.data)

                this.projectName = responseProject.data.projectName
            }

            //get client information
            let responseClient = await axios.get(this.selectedProject._links.client.href);
            this.client = responseClient.data

            this.isProjectSet = true

            this.getProducts(this.selectedProject._links.products.href)

            this.save()
        },

        showModal(type) {
            this.openedModal = type
        },

        async getProducts(endpoint){
           let response = await axios.get(endpoint)
           this.products = response.data._embedded.product
        }


    },

    created() {

        if (this.$route.params.id) {
            this.getData();
        } else {

            if (this.$route.query.parentData) {
                axios
                    .get('project/' + this.$route.query.parentData)
                    .then((response) => {
                        this.selectedProject = response.data
                        this.projects.push(response.data)
                        this.projectName = response.data.projectName
                        
                        //get all products name of the project
                        this.getProducts(this.selectedProject._links.products.href)

                        //get client information
                        axios
                            .get(this.selectedProject._links.client.href)
                            .then((response) => {
                                this.client = response.data
                            })
                        this.isProjectSet = true
                    })
            }
            this.loading = false;
        }
    },
    validations: {
        product: {
            productName: {
                required,
                isProductNameUnique
            },
            status: {
                required,
            }
        },
        projectName: {
            required,
            projectNameMustExist
        }

    }
};
</script>

<style scoped>
.form-group {
    text-align: left;
    font-weight: bold;
}
</style>
