<template>
<div v-can="'Read_FormulaProcessType'">

    <MainGrid :gridTitle="$tc('process.process', 2)" objectNodeName="formulaProcessType" endpoint="formulaProcessType" :gridColumns="columnsProcess" :filter="filterProcess" formUrl="/setup-process-type" deletedataToDisplay="name">
    </MainGrid>

</div>
</template>

<script>

import MainGrid from '../components/MainGrid'
import Feedback from '../mixins/Feedback.js'

export default {
    name: 'SetupTemplateProcessesType',

    mixins: [Feedback],

    components: {
        MainGrid,
    },
    data() {
        return {

            columnsProcess: [{
                    label: this.$t('process.processType'),
                    key: "name",
                    sortable: true,
                },
                {
                    key: "actionButtons",
                    label: ""
                }
            ],
            filterProcess: [{
                method: "findByNameContainingIgnoreCaseOrderByName",
                params: ["name"]
            }],

            selectedItem: {}
        }
    },
    methods: {


    }
}
</script>

<style>
.btn-icon {
    cursor: pointer;
    margin: 0px 5px;
}
</style>
