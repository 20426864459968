import axios from "axios";
import {
  router
} from "../../router/index.js"
import i18n from '../../i18n'

const state = {
  accessedUrl: "",

  credentialIncorrect: false,

  authenticated: null,

  licence: {
    valid: null
  },

  user: null,

  permission: null

};
const getters = {
  getAccessedUrl: state => {
    return state.accessedUrl
  },

  isAuthenticated: state => state.authenticated,

  isCredentialIncorrect: state => !!state.credentialIncorrect,

  getUser: state => state.user,

  getPermission: state => state.permission,

  getLicence: state => state.licence,

};
const actions = {
  setAccessedUrl(context, url) {
    context.commit("setAccessedUrl", url);
  },


  async logIn({
    commit,
    state,
    dispatch
  }, user) {

    try {

      let response = await axios.get('/userSession', {
        headers: {
          Authorization: 'Basic ' + btoa(user.username + ':' + user.password)
        }
      })

      await commit('setPermission', response.data)

      var filter = "/search/findByUserName";
      var paramValue = {
        "userName": response.data.principal.username,
      };
      let logUser = await axios.get("user" + filter, {
        params: paramValue
      })

      await commit('setUser', logUser.data)

      i18n.locale = logUser.data.language.toLowerCase()

      await commit('setAuthenticated', true)


    } catch (error) {
      console.log(error)
      commit('setCredentialIncorrect', true)
      axios.defaults.withCredentials = false;

      return
    }

    try {
      let licence = await axios.get('/licenseValidate')
      await commit('setLicence', licence.data)

    } catch (error) {
      await commit('setLicence', error.response.data)
      axios.defaults.withCredentials = false;

      return
    }

    if(state.authenticated == true && state.licence.valid == true){
      dispatch('redirect', state.accessedUrl)
    }


  },

  async logOut({
    commit
  }) {
    commit('logout')
  },

  async redirect(context, url) {
    if (url == "" || url == null || url == "/") {
      router.push("/home?fromLogin=true");
    } else {
      router.push(url);
    }
  }

};
const mutations = {
  setAccessedUrl(state, value) {
    state.accessedUrl = value;
  },

  setUser(state, user) {
    state.user = user
  },

  setLicence(state, licence) {
    state.licence = licence
  },

  setPermission(state, permission) {
    state.permission = permission
  },

  setUserLanguage(state, language) {
    state.user.preferences.language = language
    i18n.locale = language
  },

  setCredentialIncorrect(state, value) {
    state.credentialIncorrect = value
  },

  setAuthenticated(state, value) {
    state.authenticated = value
  },

  logout(state) {
    state.user = null
    state.authenticated = false
  },

};
export default {
  state,
  getters,
  actions,
  mutations
};
