<template>
<div v-can="'Read_Contact'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-left">{{$tc('general.contact', 2)}}</h1>
        <MainGrid gridTitle="Contact" objectNodeName="contact" endpoint="contact" :gridColumns="columns" :filter="filter" formUrl="/contact" deletedataToDisplay="firstName" deleteSearchValue="lastName"
          :deleteAssociatedData="$store.state.contact.associatedData"></MainGrid>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import MainGrid from '../components/MainGrid'

export default {
  name: 'Contacts',
  components: {
    MainGrid,
  },
  data() {
    return {
      title: 'Contacts',

      columns: [{
          label: this.$t('contact.firstname'),
          key: "firstName",
          sortable: true,
          initial_sort: true
        },
        {
          label: this.$t('contact.lastName'),
          key: "lastName",
          sortable: true,
        },
        {
          label: "Email",
          key: "email",
          sortable: true,
        }, {
          key: "actionButtons",
          label: "",
        }
      ],

      filter: [{
        method: "findByFirstNameContainingIgnoreCaseOrLastNameContainingIgnoreCaseOrEmailContainingIgnoreCase",
        params: ["firstName", "lastName", "email"]
      }]
    }
  }
}
</script>

<style>
.btn-icon {
  cursor: pointer;
  margin: 0px 5px;
}
</style>
