<template>
  <div>

    <div class="form-row centered mt-4">

      <div class="form-group col-md-6">
        <b-input-group>
          <b-form-input id="filter-input" v-model="queryParams.global_search" @input="onChangeQuery(queryParams)"
            type="search" placeholder="Type to Search"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!queryParams.global_search"
              @click="queryParams.global_search = '', onChangeQuery(queryParams)">{{ $t('general.clear') }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <div class="form-group col-md-6 text-right">
        <div v-can="'Create_' + _props.endpoint.charAt(0).toUpperCase() + _props.endpoint.slice(1)" role="group"
          aria-label="Basic example">
          <span
            v-if="_props.endpoint == 'contact' || _props.endpoint == 'distributor' || _props.endpoint == 'client' || _props.endpoint == 'supplier' || _props.endpoint == 'ingredient' || _props.endpoint == 'material'">
            <span v-if="$store.getters.getUser.roleName == 'Administrator'">
              <button type="button" class="btn btn-outline-primary mr-2" @click="onImport()">{{
                $t('general.import')
              }}</button>
            </span>
          </span>
          <button type="button" class="btn btn-primary" @click="onCreate">{{ $t('general.create') }}</button>
        </div>
      </div>

    </div>

    <b-table hover ref="table" :items="fetchItems" :filter="queryParams.global_search" :fields="columns" :current-page="queryParams.page" :per-page="queryParams.per_page" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">

      <template #cell(actionButtons)="row">
        <font-awesome-icon
          v-if="$store.getters.getPermission.authorities.some(e => e.authority == 'Update_' + _props.endpoint.charAt(0).toUpperCase() + _props.endpoint.slice(1))"
          class="btn-icon" :title="$t('general.RightClickNewTab')"
          @contextmenu.prevent="onRightClick(row.item._links.self.href)" @click="onEdit(row.item._links.self.href)"
          icon="edit" />
        <font-awesome-icon v-else class="btn-icon" @click="onEdit(row.item._links.self.href)"
          @contextmenu.prevent="onRightClick(row.item._links.self.href)" :title="$t('general.RightClickNewTab')"
          icon="eye" />
        <DeleteButton v-can="'Delete_' + _props.endpoint.charAt(0).toUpperCase() + _props.endpoint.slice(1)"
          :endpoint="_props.endpoint" :data="row.item._links.self.href" :dataToDisplay="row.item[deletedataToDisplay]"
          :searchValue="row.item[_props.deleteSearchValue]" :relationship="_props.deleteAssociatedData"
          :toDeleteWithRegister="_props.deleteEndpointToDelete" @delete="deleteItems"></DeleteButton>
      </template>

      <template #cell(returnButton)="row">
        <button type="button" class="btn btn-outline-primary btn-sm" @click="onSelected(row.item._links.self.href)">{{
          $t('general.select')
        }}</button>
      </template>

      <template #cell(modalButton)="row">
        <font-awesome-icon
          v-if="$store.getters.getPermission.authorities.some(e => e.authority == 'Update_' + _props.endpoint.charAt(0).toUpperCase() + _props.endpoint.slice(1))"
          class="btn-icon" @click="onModal(row.item)" icon="edit" />
        <font-awesome-icon v-else class="btn-icon" @click="onModal(row.item)" icon="eye" />
        <DeleteButton v-can="'Delete_' + _props.endpoint.charAt(0).toUpperCase() + _props.endpoint.slice(1)"
          :endpoint="_props.endpoint" :data="row.item._links.self.href" :dataToDisplay="row.item[deletedataToDisplay]"
          :searchValue="row.item[_props.deleteSearchValue]" :relationship="_props.deleteAssociatedData"
          :toDeleteWithRegister="_props.deleteEndpointToDelete" @delete="deleteItems"></DeleteButton>
      </template>


      <template #cell(tradeName)="row">
        {{ row.value }} <span v-if="row.item.ingredientsName !== null && row.item.ingredientsName.length > 0"
          v-b-tooltip :title="row.item.ingredientsName.join(', ')"><font-awesome-icon class="btn-icon"
            icon="info-circle" /></span>
      </template>

      <template #cell(familiesName)="row">
        {{ row.value !== null && row.value.length > 0 ? row.value.join(', ') : '' }}
      </template>

      <template #cell(targetDate)="row">
        {{ row.unformatted | moment("L") }}
      </template>

      <template #cell(resultDate)="row">
        {{ row.unformatted | moment("L") }}
      </template>

      <template #cell(addDate)="row">
        {{ row.unformatted | moment("L") }}
      </template>

      <template #cell(modDate)="row">
        {{ row.unformatted | moment("L") }}
      </template>

      <template #cell(statusName)="row">
        <PillStatus :status="row.value"></PillStatus>
      </template>

      <template #cell(status)="row">
        <PillStatus :status="row.value"></PillStatus>
      </template>


    </b-table>

    <div class="form-row centered">
      <div class="form-group col-md-4">
        <b-pagination v-model="queryParams.page" :total-rows="total_rows" :per-page="queryParams.per_page" align="fill"
          size="sm" class="my-0"></b-pagination>
      </div>

      <div class="form-group col-md-2">
        <b-form-select id="per-page-select" v-model="queryParams.per_page" :options="per_page_options"
          size="sm"></b-form-select>
      </div>
    </div>

    <b-modal ref="import-data" size="xl" scrollable :title="_props.gridTitle">
      <ImportCsv :endpoint="_props.endpoint"></ImportCsv>
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()">OK</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from 'axios'
import VueBootstrap4Table from 'vue-bootstrap4-table';
import PillStatus from '../components/PillStatus.vue'
import DeleteButton from '../components/DeleteButton.vue';
import ImportCsv from '../components/ImportCsv.vue'

window.axios = require("axios");

const PER_PAGE = 15;

export default {
  name: 'MainGrid',

  props: {
    gridTitle: {
      type: String,
      required: true
    },
    objectNodeName: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    gridColumns: {
      type: Array,
      required: true
    },
    filter: {
      type: Array,
      required: true
    },
    formUrl: {
      type: String,
      required: true
    },
    queryByFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    customOrderBy: {
      type: Boolean,
      required: false,
      default: false
    },

    // the following props are for DeletteButton component
    deleteSearchValue: {
      type: String,
      required: false,
    },
    deletedataToDisplay: {
      type: String,
      required: false
    },
    deleteAssociatedData: {
      type: Array,
      required: false
    },
    deleteEndpointToDelete: {
      type: Array,
      required: false
    },

  },
  components: {
    VueBootstrap4Table,
    ImportCsv,
    DeleteButton,
    PillStatus
  },
  data() {
    return {
      title: this._props.gridTitle,

      columns: this._props.gridColumns,

      rows: [],
      total_rows: 0,

      sortBy: this._props['filter'][0]['params'][0],
      sortDesc: false,

      per_page_options: [5, 10, 15, 20, 30, 40, 50, 100],

      queryParams: {
        filters: [],
        global_search: "",
        per_page: PER_PAGE,
        page: 1,
      },

    };
  },

  methods: {

    onRightClick(url) {
      let id = url.split("/").pop()
      let routeData = this.$router.resolve(this._props.formUrl + '/' + id);
      window.open(routeData.href, '_blank');
    },

    onImport() {
      this.$refs['import-data'].show()
    },

    onCreate() {
      this.$router.push(this._props.formUrl)
    },

    onSelected(url) {
      this.$emit("selectValue", url);
      this.$emit('close')
    },

    onEdit(url) {
      let id = url.split("/").pop()
      this.$router.push(this._props.formUrl + '/' + id)
    },

    onModal(item) {
      this.$emit('selectedItem', item)
      this.$root.$emit('bv::show::modal', 'modal-edit')
    },

    // onDelete(url) {

    //   let id = url.split("/").pop();

    //   this.$bvModal.msgBoxConfirm(this.$t('delete.confirmBox') + " ", {
    //     title: this.$t('delete.pleaseConfirm'),
    //     okVariant: 'danger',
    //     okTitle: this.$t('delete.yes'),
    //     cancelTitle: this.$t('delete.no'),
    //     footerClass: 'p-2',
    //   })
    //     .then(async (value) => {
    //       if (value == true) {
    //         try {
    //           await axios.delete(this.endpoint + '/' + id)
    //           this.fetchItems()
    //         } catch {
    //           this.$bvModal.msgBoxConfirm(this.$t('delete.editFirst'), {
    //             title: this.$t('delete.pleaseConfirm'),
    //             okVariant: 'primary',
    //             okTitle: this.$t('delete.edit'),
    //             cancelTitle: this.$t('general.cancel'),
    //             footerClass: 'p-2',
    //           })
    //             .then((value) => {
    //               if (value == true) {
    //                 this.$router.push(this._props.formUrl + '/' + id)
    //               }
    //             })
    //         }
    //       }

    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     })

    // },

    async deleteItems(){
      await this.fetchItems();
      this.$refs.table.refresh()
    },

    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchItems();
    },

    fetchItems(ctx) {
      var query = this.queryParams.global_search;
      var size = this.queryParams.per_page;
      var page = this.queryParams.page - 1;
      var sort;
      var sortOrder;
      var filter = "/search/" + this._props['filter'][0]['method'] + "?";
    
      if(this.sortDesc == true){
        sortOrder = "desc"
      }else{
        sortOrder = "asc"
      }

      //define parameter to sort by the first parameter (by default)
      let sortField = this.sortBy.split(".")[this.sortBy.split(".").length - 1]

      //sort by the first column in case of lambda function in the backend
      if(sortField == "searchValue"){
        sortField = this._props.gridColumns[0].key;
        //to actualise sorting icon
        this.sortBy = sortField
      }

      sort = sortField + "," + sortOrder

      //prepare the filter params for query
      this._props['filter'][0]['params'].forEach(function(val, key, arr) {
          var temp = val + "=" + query;
          if (Object.is(arr.length - 1, key)) {
            filter = filter + temp;
          } else {
            filter = filter + temp + "&";
          }
        })

        
      //param for magic function backend
      var paramValue = {
        "size": size,
        "page": page,
        "sort" : sort
      };

      //param for lambda function in backend (don't forget to pass the prop in parent component)
      if (this.customOrderBy)
        paramValue = {
          "size": size,
          "page": page,
          "orderBy": sort
      };

      const promise = axios.get(this._props.endpoint + filter, {
        params: paramValue
      })

      return promise.then(async data => {
        let items = data.data._embedded[this._props.objectNodeName]
        this.total_rows = data.data.page.totalElements

        // Must return an array of items or an empty array if an error occurred
        return items || []
      })

    },

  },

  mounted() {
    this.fetchItems()
  },

};
</script>

<style scopped lang="scss">
.btn-icon {
  cursor: pointer;
  margin: 0 5px;
}
</style>
