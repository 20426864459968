<template>
<div v-can="'Read_Family'">

    <MainGrid :gridTitle="$t('ingredient.nomenclature')" objectNodeName="family" endpoint="family" :gridColumns="familyColumns" :filter="familyFilter" formUrl="/family" deletedataToDisplay="name"
        @selectedItem="SelectedFamily"></MainGrid>

    <b-modal id="modal-edit" ref="modal" title="Edit Function" @ok="saveNewFamily">
        <form ref="form">
            <b-form-group label="Name" label-for="name-input">
                <b-form-input v-can:input="'Update_Family'" id="name-input" v-model="selectedItem.name" @keydown.enter="saveNewMaterialFamily"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from 'axios'
import MainGrid from '../components/MainGrid'
import Feedback from '../mixins/Feedback.js'

window.axios = require("axios");

export default {
    name: 'RegulationsMaterialFamilyGrid',

    mixins: [Feedback],

    components: {
        MainGrid,
    },
    data() {
        return {

            familyColumns: [{
                    key: 'addBy',
                    label: this.$t('general.addBy'),
                    sortable: false
                },
                {
                    key: 'addDate',
                    label: this.$t('general.addOn'),
                    sortable: true,
                    slot_name: "date"
                },
                {
                    key: 'modBy',
                    label: this.$t('general.modBy'),
                    sortable: true,
                },
                {
                    name: 'modDate',
                    label: this.$t('general.modOn'),
                    sortable: true,
                    slot_name: "mod-date"
                },
                {
                    key: 'name',
                    label: this.$t('general.name'),
                    sortable: true
                },
                {
                    key: "modalButton",
                    lable: ""
                }
            ],

            familyFilter: [{
                method: "findByNameContainingIgnoreCase",
                params: ["name"]
            }],

            selectedItem: {}
        }
    },
    methods: {
        SelectedFamily(item) {
            this.selectedItem = item
        },

        saveNewFamily() {
            let data = {
                name: this.selectedItem.name
            }
            let id = this.selectedItem._links.self.href.split("/").pop();
            let endpoint = 'family/' + id

            try {
                axios.put(endpoint, data)
                    .then(
                        this.succesAlert()
                    )
            } catch (error) {
                console.log(error);
            }
        },

    }
}
</script>

<style>
.btn-icon {
    cursor: pointer;
    margin: 0px 5px;
}
</style>
