<template>
<div>

    <div class="form-row centered">

        <div class="form-group col-md-5">
            <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

                <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
            </b-input-group>
        </div>
        <div class="form-group col-md-4">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
        </div>
        <div class="form-group col-md-2">
            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
        </div>
        <div class="form-group col-md-1 text-center">
            <button type="button" class="btn btn-outline-primary btn-sm" @click="onCreate()">{{$t('general.create')}}</button>
        </div>

    </div>

    <b-table hover :items="fetchItems" :fields="fields" :busy.sync="isBusy" :filter="filter" @filtered="onFiltered" :no-provider-filtering="true" :no-provider-paging="true" :no-provider-sorting="true" :sort-by="sortBy" :sort-desc="false"
        :current-page="currentPage" :per-page="perPage">

        <template #cell(targetDate)="row">
            {{row.value | moment("L")}}
        </template>

        <template #cell(resultDate)="row">
            {{row.value | moment("L")}}
        </template>

        <template #cell(status)="row">
            <PillStatus :status="row.value"></PillStatus>
        </template>

        <template #cell(result)="row">
            <PillStatus :status="row.value"></PillStatus>
        </template>

        <template #cell(goTo)="row">
            <font-awesome-icon @click="navigateTo(row.item._links.self.href)" class="btn-icon" icon="arrow-circle-right" />
        </template>

    </b-table>

</div>
</template>


<script>
import axios from 'axios'
import PillStatus from '../components/PillStatus.vue'
window.axios = require("axios");

export default {
    name: 'ListingGrid',

    components: {
        PillStatus
    },
    data() {
        return {
            fields: this._props.column,

            sortBy: 'projectName',

            filter: null,

            isBusy: false,

            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
        }
    },
    props: {
        column: {
            type: Array,
            required: true
        },
        endpoint: {
            type: String,
            required: true
        },
        dataObject: {
            type: String,
            required: true
        },
        redirectUrl: {
            type: String,
            required: true
        },
        parentData: {
            type: String,
        }
    },

    methods: {
        fetchItems() {
            const promise = axios.get(this._props.endpoint)

            return promise.then(async data => {
                const items = data.data._embedded[this._props.dataObject]
                this.totalRows = items.length

                if (this._props.endpoint.split("/").pop() == "formulaTests") {
                    for (let i = 0; i < items.length; i++) {
                        await axios.get(items[i]._links.formulaTestType.href)
                            .then(data => {
                                items[i].name = data.data.name;
                            })
                    }
                }
                // Must return an array of items or an empty array if an error occurred
                return items || []
            })


        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        navigateTo(url) {
            let id = url.split("/").pop()
            this.$router.push(this._props.redirectUrl + '/' + id)
        },
        onCreate() {
            this.$router.push({
                path: this._props.redirectUrl,
                query: {
                    parentData: this._props.parentData
                }
            })
        }

    },

    mounted() {

    }
};
</script>

<style lang="css" scoped>

.btn-icon {
  cursor: pointer;
  margin: 0px 5px;
}

</style>
