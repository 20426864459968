<template>
<div>
  <multiselect ref='multiselect' :allowEmpty="false" v-can:multiselect="_props.permission" v-model="value" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" :placeholder="$t('general.selectRegulation')" label="name" track-by="name" :preselect-first="false" :taggable="false"
    @input="onSelect">
  </multiselect>
</div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

window.axios = require("axios");

export default {
  name: 'RegulationList',
  components: {
    Multiselect
  },

  props: {
    selectedRegulations: {
      type: Array,
      required: true
    },
    permission:{
        type: String,
    }
  },
  data() {
    return {
      value: [],
      options: []
    }
  },
  methods: {
    onSelect(list) {

      var finalObject = [];
      for (var i = 0; i < list.length; i++) {
        finalObject.push(list[i]._links.self.href);
      }
      this.$emit('update:value', finalObject)
      this.$emit('newRegulations', finalObject)
    },

    getRegulation() {
      for (let i = 0; i < this._props.selectedRegulations.length; i++) {
        let id = this._props.selectedRegulations[i].split("/").pop();
        axios
          .get('regulation/' + id)
          .then((response) => {
            this.value.push(response.data)
          })
          .catch((error) => {
            console.log(error.response)
          })
      }
    }
  },

  mounted() {

    axios
      .get('regulation')
      .then((response) => {
        this.options = response.data._embedded.regulation;
      })
      .catch((error) => {
        console.log(error.response)
      })

    this.getRegulation();

  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
