<template>
<div class="container-fluid">
  <p>{{$t('formulation.modalDuplicateFormulaText')}} : <b>{{ this._props.formula.name }} V.{{ this._props.formula.version }}</b></p>

  <div class="form-row">
    <div class="form-group col-md-3">
      <label>New prefix</label>
      <b-form-input list="formulaPrefix-list" placeholder="Prefix" type="text" class="form-control" autocomplete="off" :state="validateState($v.newPrefix)" :lazy="true" :trim="true" v-model.trim="$v.newPrefix.$model" />
      <datalist id="formulaPrefix-list">
        <option v-for="formulaPrefix in formulaPrefixs" :key="formulaPrefix.name" field-value="material.name"> {{ formulaPrefix.name }}</option>
      </datalist>
      <div class="invalid-feedback" v-if="!$v.newPrefix.required">{{$t('errorMsg.required')}}</div>
    </div>

    <div class="form-group col-md-9">
      <label><b>{{$t('product.productName')}}</b></label>
      <span class="ml-2">
        <font-awesome-icon icon="search" class="btn-icon" @click="showModal('product')" />
      </span>
      <b-form-input type="text" autocomplete="off" list="newProducts-list" @input="searchProducts(newProduct.productName)" :state="validateState($v.newProduct.productName)" class="form-control" placeholder="new product" :trim="true"
        v-model="newProduct.productName" />

      <div class="invalid-feedback" v-if="!$v.newProduct.productName.required">{{$t('errorMsg.required')}}</div>
      <div class="invalid-feedback" v-if="!$v.newProduct.productName.newProductMustExist">Product must exist</div>

      <datalist id="newProducts-list">
        <option v-for="product in newProducts" :key="product._links.self.href" field-value="product._links.self.href"> {{ product.productName }}</option>
      </datalist>
    </div>


    <div class="form-group col-md-12">
      <button @click="duplicateFormula()" type="button" class="btn btn-primary float-right ml-3" data-dismiss="modal">{{$t('general.create')}}</button>
      <button type="button" class="btn btn-secondary float-right" @click="$emit('closeModal')">{{$t('general.cancel')}}</button>
    </div>
  </div>

  <ModalWindow v-show="openedModal === 'product'" :title="$t('general.search')" @close="openedModal = null">
    <MainGrid gridTitle="Product" objectNodeName="entityModels" endpoint="/product" :gridColumns="modalColumns" :filter="modalFilter" formUrl="/product" :queryByFilter="true" :customOrderBy="true" @close="openedModal = null"
      @selectValue="setProduct"></MainGrid>
  </ModalWindow>

</div>
</template>

<script>
import axios from 'axios'
window.axios = require("axios");

import MainGrid from '../../components/MainGrid.vue';
import ModalWindow from '../../components/ModalWindow.vue';

import CommonVariablesAndFunctions from '../../mixins/CommonVariablesAndFunctions.js'

import {
  required,
} from 'vuelidate/lib/validators';

function newProductMustExist(value) {
  if (this.newProducts.filter(e => e.productName === value).length != 0) {
    return true
  }
  return false
}

export default {
  name: 'CloneFormula',

  mixins: [CommonVariablesAndFunctions],

  components: {
    MainGrid,
    ModalWindow
  },

  data() {
    return {
      newProduct: {},
      newProducts: [],

      newPrefix: "",
      formulaPrefixs: [],

      openedModal: null,

      modalColumns: [{
          label: this.$t('product.productName'),
          key: "productName",
          sortable: true,
          initial_sort: true
        },
        {
          label: this.$t('product.targetDate'),
          key: "targetDateString",
          sortable: true,
          initial_sort: false,
          slot_name: "date"
        },
        {
          label: this.$t('general.clientName'),
          key: "clientName",
          sortable: true,
          initial_sort: false
        },
        {
          label: this.$t('general.status'),
          key: "status",
          sortable: true,
          initial_sort: false,
          slot_name: "status"
        },
        {
          label: this.$t('formulation.formulationName'),
          key: "formulaName",
          sortable: true,
          initial_sort: false
        },
        {
          key: "returnButton",
        }
      ],
      modalFilter: [{
        method: "findByProductNameOrClientNameOrTargetDateOrStatusOrFormula",
        params: ["searchValue"]
      }],
    }
  },
  props: {
    formula: {
      required: true
    },
    gridFormulationData: {
      required: true
    },
    processTable: {
      required: true
    }

  },
  computed: {

  },

  methods: {

    showModal(type) {
      this.openedModal = type
    },

    async searchProducts(name) {
      if (name.length > 1) {
        var filter = "/search/findByProductNameContainingIgnoreCaseOrderByProductName";
        var paramValue = {
          "productName": name,
        };
        let responseProducts = await axios.get("product" + filter, {
          params: paramValue
        })
        this.newProducts = responseProducts.data._embedded.product;
      }
    },

    async setProduct(value) {
      let response = await axios.get(value);
      this.newProduct = response.data
      //this is needed for the validation
      this.newProducts = []
      this.newProducts.push(response.data)
    },

    async duplicateFormula() {

      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this._props.formula.process = JSON.stringify(this._props.processTable);

      let observations = this._props.formula.observation == null ? "" : this._props.formula.observation;

      let data = {
        prefix: this.newPrefix,
        version: 1,
        name: this._props.formula.name,
        observation: "*** " + this.$t('formulation.modalDuplicateFrom') + " " + this._props.formula.prefixId + " *** " + observations,
        process: this._props.formula.process,
        status: this._props.formula.status,
        product: this.newProduct._links.self.href
      };

      try {
        //post formula: i.e. informations and process
        let formula = await axios.post("formula", data)
        let id = formula.data._links.self.href.split("/").pop();

        //post formulaMaterial
        for (let i = 0; i < this._props.gridFormulationData.length; i++) {
          // //update the url of item
          let formulationFinal = {
            percentage: this._props.gridFormulationData[i].percentage,
            phase: this._props.gridFormulationData[i].phase,
            qsp: this._props.gridFormulationData[i].qsp,
            formula: formula.data._links.self.href,
            material: this._props.gridFormulationData[i].tradeName.url
          };
          await axios.post('formulaMaterial', formulationFinal);

          this.newProduct = {};
          this.newPrefix = "";
          this.$v.$reset();

        }

        this.$router.push(id);
        this.$emit('duplicated')
        this.$emit('closeModal')

      } catch (error) {
        console.log(error);
        // this.errorAlert(error);
      }
    }


  },

  mounted() {
    axios.get('formulaPrefix')
      .then((response) => {
        this.formulaPrefixs = response.data._embedded.formulaPrefix
      })
  },

  validations: {
    newPrefix: {
      required,
    },
    newProduct: {
      productName: {
        required,
        newProductMustExist
      }

    },
  }
};
</script>

<style lang="css" scoped>

table {
  width: 100%;
}

.table-container {
  overflow-x: auto;
}

</style>
