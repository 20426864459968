<template>
<div v-can="'Read_FormulaTest'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-left">{{$tc('general.test', 2)}}</h1>
        <MainGrid gridTitle="Tests" objectNodeName="entityModels" endpoint="formulaTest" :gridColumns="columns" :filter="filter" formUrl="/test" :queryByFilter="true" :customOrderBy="true" deletedataToDisplay="formulaName" :deleteAssociatedData="$store.state.test.associatedData"></MainGrid>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MainGrid from '../components/MainGrid'

export default {
  name: 'Tests',
  components: {
    MainGrid
  },
  data() {
    return {
      title: 'Tests',
      columns: [
        {
          label: this.$t('formulation.formulationName'),
          key: "formulaName",
          sortable: true,
          initial_sort: true
        },
        {
          label: this.$t('tests.formulaPrefixIdAndVersion'),
          key: "formulaPrefixIdAndVersion",
          sortable: true
        },
        {
          label: this.$t('tests.testType'),
          key: "formulaTestTypeName",
          sortable: true
        },
        {
          label: this.$t('tests.targetDate'),
          key: "targetDate",
          sortable: true,
        },
        {
          label: this.$t('tests.resultDate'),
          key: "resultDate",
          sortable: true,
        },
        {
          label: this.$t('general.result'),
          key: "statusName",
          sortable: true
        },
        {
          key: "actionButtons",
          label: "",
        }
      ],
      filter: [{
        method: "findByFormulaNameOrFormulaPrefixIdOrTestTypeOrTargetDateOrResultDateOrStatus",
        params: ["searchValue"]
      }]
    }
  }
}
</script>

<style>
.btn-icon{
  cursor: pointer;
  margin: 0px 5px;
}

</style>
