<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-left">{{$tc('process.template', 2)}}</h1>

                <b-nav tabs>
                    <b-nav-item v-can="'Read_FormulaTestType'" to="/setup-template/tests" exact exact-active-class="active">Tests</b-nav-item>
                    <b-nav-item v-can="'Read_FormulaProcessType'" to="/setup-template/processes" exact exact-active-class="active">Processes</b-nav-item>
                </b-nav>

                <router-view></router-view>

            </div>
        </div>
    </div>

</div>
</template>

<script>

import Feedback from '../mixins/Feedback.js'

window.axios = require("axios");

export default {
    name: 'Setup-Template',

    mixins: [Feedback],

    components: {

    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style>
.btn-icon {
    cursor: pointer;
    margin: 0px 5px;
}
</style>
