<template>
  <div>

    <b-img :src="require('../../assets/ISO-Logo.jpeg')" width=35 id="popover-iso"></b-img>

    <b-popover target="popover-iso" ref="popoverIso" triggers="hover" placement="top">
      <template #title>{{ $t('material.Iso16128') }}</template>
      <div>
        {{ $t('material.natural') }} : {{ Iso.natural }} %
      </div>
      <div>
        {{ $t('material.naturalOrigin') }} : {{ Iso.naturalOrigin }} %
      </div>
      <div>
        {{ $t('material.organic') }} : {{ Iso.organic }} %
      </div>
      <div>
        {{ $t('material.organicOrigin') }} : {{ Iso.organicOrigin }} %
      </div>
      <div class="footnote mt-2">
        {{ $t('formulation.caculateWithWater') }}
      </div>
    </b-popover>

    <b-img :src="require('../../assets/natrue-logo.jpeg')" width=27 id="popover-natrue" class="ml-3"></b-img>

    <b-popover target="popover-natrue" ref="popoverNatrue" triggers="hover" placement="top">
      <template #title>{{ $t('material.natrue.natrue') }}</template>
      <div class="font-weight-bold">
        {{ $t('material.natrue.natural') }} : {{ Natrue.natural }} %
      </div>
      <div class="font-weight-bold">
        {{ $t('material.natrue.naturalOrganic') }} : {{ Natrue.naturalOrganic }} %
      </div>
      <div class="font-weight-bold">
        {{ $t('material.natrue.derivedNatural') }} : {{ Natrue.derivedNatural }} %
      </div>
      <div>
        {{ $t('material.natrue.derivednaturalOutOfOrganic') }} : {{ Natrue.derivedNaturalOutOfOrganic }} %
      </div>
      <div>
        {{ $t('material.natrue.natureIdentical') }} : {{ Natrue.natureIdentical }} %
      </div>
      <div>
        {{ $t('material.natrue.water') }} : {{ Natrue.water }} %
      </div>
      <div class="font-weight-bold mt-2">
        {{$t('material.natrue.percentage')}} : {{ NatrueOrganicPercentage }} %
      </div>
    </b-popover>

  </div>
</template>

<script>

export default {
  name: 'NaturalOrganic',
  mixins: [],
  props: {
    gridFormulationData: {
      required: true,
      type: Array
    }

  },
  computed: {

    Iso: function (){
      let iso = {
        natural : 0,
        naturalOrigin : 0,
        organic : 0,
        organicOrigin : 0
      }

      for (var i = 0; i < this._props.gridFormulationData.length; i++) {
        if (this._props.gridFormulationData[i].materialOrganic != null) {
          iso.natural += this._props.gridFormulationData[i].materialOrganic.isoNatural * this._props.gridFormulationData[i].percentage
          iso.naturalOrigin += this._props.gridFormulationData[i].materialOrganic.isoNaturalOrigin * this._props.gridFormulationData[i].percentage
          iso.organic += this._props.gridFormulationData[i].materialOrganic.isoOrganic * this._props.gridFormulationData[i].percentage
          iso.organicOrigin += this._props.gridFormulationData[i].materialOrganic.isorganicOrigin * this._props.gridFormulationData[i].percentage
        }
      }

      iso.natural = Math.round((iso.natural + Number.EPSILON) * 100) / 100
      iso.naturalOrigin = Math.round((iso.naturalOrigin + Number.EPSILON) * 100) / 100
      iso.organic = Math.round((iso.organic + Number.EPSILON) * 100) / 100
      iso.organicOrigin = Math.round((iso.organicOrigin + Number.EPSILON) * 100) / 100

      return iso

    },

    Natrue: function(){
      let natrue = {
        natural : 0,
        naturalOrganic : 0,
        derivedNatural : 0,
        derivedNaturalOutOfOrganic : 0,
        natureIdentical : 0,
        water: 0
      }

      for (var i = 0; i < this._props.gridFormulationData.length; i++) {
        if (this._props.gridFormulationData[i].materialOrganic != null) {
          natrue.natural += this._props.gridFormulationData[i].materialOrganic.natrueNatural * this._props.gridFormulationData[i].percentage / 100
          natrue.naturalOrganic += this._props.gridFormulationData[i].materialOrganic.natrueNaturalOrganic * this._props.gridFormulationData[i].percentage / 100
          natrue.derivedNatural += this._props.gridFormulationData[i].materialOrganic.natrueDerivedNatural * this._props.gridFormulationData[i].percentage / 100
          natrue.derivedNaturalOutOfOrganic += this._props.gridFormulationData[i].materialOrganic.natrueDerivedNaturalOutOfOrganic * this._props.gridFormulationData[i].percentage / 100
          natrue.natureIdentical += this._props.gridFormulationData[i].materialOrganic.natrueNatureIdentical * this._props.gridFormulationData[i].percentage / 100
          natrue.water += this._props.gridFormulationData[i].materialOrganic.natrueWater * this._props.gridFormulationData[i].percentage / 100
        }
      }

      natrue.natural = Math.round((natrue.natural + Number.EPSILON) * 100) / 100
      natrue.naturalOrganic = Math.round((natrue.naturalOrganic + Number.EPSILON) * 100) / 100
      natrue.derivedNatural = Math.round((natrue.derivedNatural + Number.EPSILON) * 100) / 100
      natrue.derivedNaturalOutOfOrganic = Math.round((natrue.derivedNaturalOutOfOrganic + Number.EPSILON) * 100) / 100
      natrue.natureIdentical = Math.round((natrue.natureIdentical + Number.EPSILON) * 100) / 100
      natrue.water = Math.round((natrue.water + Number.EPSILON) * 100) / 100

      return natrue
    },

    NatrueOrganicPercentage: function () {
      let numerator = 0;
      let denominator = 0;

      for (const { materialOrganic, percentage } of this._props.gridFormulationData) {
        if (materialOrganic != null) {
          const { natrueNaturalOrganic, natrueDerivedNaturalOutOfOrganic, natrueNatural, natrueDerivedNatural } = materialOrganic;
          if (natrueDerivedNaturalOutOfOrganic != 0) {
            numerator += (natrueNaturalOrganic + natrueDerivedNaturalOutOfOrganic) * percentage
            denominator += (natrueNatural + natrueDerivedNatural) * percentage
          } else {
            numerator += natrueNaturalOrganic * percentage
            denominator += natrueNatural * percentage
          }
        }
      }

      const organicPercentage = numerator / denominator

      return Math.round((organicPercentage + Number.EPSILON) * 1000) / 10
    },

  },
  data() {
    return {
      naturalOrigin: null,
      natural: null,
      organicOrigine: null,
      organic: null
    }

  },

};
</script>

<style scoped>
.footnote {
  color: grey;
  font-style: italic;
  font-size: small;
}
</style>
