<template>
<div v-can="_props.permission">
  <div class="d-flex mb-4 flex-row-reverse">
    <!-- <button type="button" class="btn btn-danger mr-auto p-2" @click="clicked('delete')">Delete</button> -->
    <button type="button" class="btn btn-primary p-2 ml-3" @click="clicked('save')">{{$t('general.save')}}</button>
    <button type="button" class="btn btn-warning p-2 ml-3" @click="clicked('cancel')">{{$t('general.cancel')}}</button>
  </div>
  <div class="error small d-flex flex-row-reverse" v-if="isError">{{$t('general.correctForm')}}</div>
</div>
</template>

<script>
export default {
  name: 'SaveCancelDeleteButtons',

  data() {
    return {

    };
  },
  props: {
    isError: {},
    permission:{
        type: String
    }
  },
  methods: {
    //send the  value to the parent
    clicked(clickedValue) {
      this.$emit("clicked", clickedValue)
    },


  },
};
</script>

<style scoped>
.error {
  color: crimson;
  font-weight: bold;
}
</style>
