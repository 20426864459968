import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {

    data() {
        return {

        }
    },

    methods: {
        TestPdf() {

            let testName = this.testTypeList.find(x => x._links.self.href == this.testType).name;
            let targetDate = this.targetDateToIso == null ? '': this.targetDateToIso.split('T')[0];
            let resultDate = this.test.resultDate == null ? '': this.test.resultDate.split('T')[0];
            let result = this.test.result;
            let formulationId = this.formulationId + 'v' + this.selectedFormulation.version
            let selectedFormulationName = this.selectedFormulation.name 
            let productName = this.product.productName
            let projectName = this.project.projectName

            let testItems = this.testItems;
            
            //translation variables
            let testTypeTrad = this.$t('tests.testType');
            let targetDateTrad = this.$t('tests.targetDate');
            let resultDateTrad = this.$t('tests.resultDate');
            let statusTrad = this.$t('general.status');

            let formulationIdTrad = this.$t('formulation.id');
            let formulationNameTrad = this.$t('formulation.formulationName');
            let productNameTrad = this.$t('product.productName');
            let projectNameTrad= this.$t('project.projectName');

            function createHeader() {
                var header = []
                for (var i = 0; i < testItems[0].length; i++) {
                    header.push(testItems[0][i])
                }

                return [header]
            }

            function createBody() {
                var body = testItems.slice(1)

                return body
            }

            const doc = new jsPDF({
                format: 'letter',
                unit: 'mm',
                orientation: 'L'
            })

            doc.setFontSize(16)
            doc.setTextColor(82, 128, 120)
            doc.text('Galenit', 250, 15)

            doc.setTextColor(0)
            doc.setFontSize(10)
            doc.setFont('helvetica', 'bold');
            doc.text(testTypeTrad, 15, 25)
            doc.text(targetDateTrad, 60, 25)
            doc.text(resultDateTrad, 100, 25)
            doc.text(statusTrad, 200, 25)          
            doc.setFont('helvetica', 'normal');
            doc.text(testName, 15, 32)
            doc.text(targetDate, 60, 32)
            doc.text(resultDate, 100, 32)
            doc.text(result, 200, 32)

            doc.setFontSize(10)
            doc.setFont('helvetica', 'bold');
            doc.text(formulationIdTrad, 15, 45)
            doc.text(formulationNameTrad, 60, 45)
            doc.text(projectNameTrad, 125, 45)
            doc.text(productNameTrad, 200, 45)
            doc.setFont('helvetica', 'normal');
            doc.text(formulationId, 15, 52)
            doc.text(selectedFormulationName, 60, 52)
            doc.text(productName, 125, 52)
            doc.text(projectName, 200, 52)

            doc.autoTable({
                startY: 70,
                headStyles: {
                    fillColor: [82, 128, 120],
                },
                head: createHeader(),
                body: createBody(),
            })

            doc.save(formulationId + ' - ' + testName + '.pdf')

            this.printAlert()

        },
    }
};
