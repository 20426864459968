<template>
<div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-left">{{$t('tests.testType')}}</h2>

        <LayoutCard :title="$t('tests.testType')" active="true">
          <div class="loader" v-if="loading">

          </div>

          <fieldset :disabled="loading">
            <form @change="save()">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputName">{{ $t('general.name') }}</label>
                  <b-form-input v-can:input="'Update_FormulaTestType'" type="text" class="form-control" :state="validateState($v.objectData.name)" id="inputName" :placeholder="$t('tests.testType')" :lazy="true" :trim="true" v-model="$v.objectData.name.$model" />
                  <div class="invalid-feedback" id="company-name-required" v-if="!$v.objectData.name.required">{{$t('errorMsg.required')}}</div>
                </div>
              </div>
            </form>
          </fieldset>

        </LayoutCard>

        <LayoutCard :title="$t('tests.template')" active="true">

          <div>

            <EditableGridJson :array="testItems" permission="Update_FormulaTestType" :value.sync="testItems" @edited="save()" :key="objectData.dataTemplate"></EditableGridJson>

          </div>

        </LayoutCard>

        <div v-if="isMod">
          <SaveCancelDeleteButtons permission="Create_FormulaTestType" @clicked="getClicked" @isMod="isMod" :isError="$v.$anyError"></SaveCancelDeleteButtons>
        </div>

        <div class="fixed-top mt-3 d-flex justify-content-center">
          <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
            {{ feedbackMessage }}
          </b-alert>
        </div>

      </div>
    </div>
  </div>

</div>
</template>

<script>
import axios from 'axios'
import LayoutCard from '../components/LayoutCard.vue'
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'
import EditableGridJson from '../components/EditableGridJson.vue'

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'
import {
  required
} from 'vuelidate/lib/validators'

window.axios = require("axios");

export default {
  name: 'TestType',

  mixins: [Feedback, CommonVariablesAndFunctions],

  components: {
    LayoutCard,
    EditableGridJson,
    SaveCancelDeleteButtons,
  },

  data() {
    return {
      title: "TestType",
      endpoint: "formulaTestType",
      value: "",

      objectData: {
        addBy: "",
        addDate: "",
        modBy: "",
        modDate: null,
        name: "",
        dataTemplate: ""
      },

      testItems: [
        ["Test", "1 week", "2 week"],
        ["", "", ""],
      ],

    };
  },
  methods: {

    processForm() {

      let testTemplateStringify = JSON.stringify(this.testItems);

      let testType = {
        name: this.objectData.name,
        dataTemplate: testTemplateStringify

      };
      return testType

    },

    async getData() {
      this.loading = true;

      let response = await axios.get(this.endpoint + '/' + this.$route.params.id)
      this.objectData = response.data

      this.testItems = JSON.parse(response.data.dataTemplate)

      this.loading = false;
      this.isMod = false;
    },

    postData(endpoint, data) {
      axios
        .post(endpoint, data)
        .then((response) => {
          this.isMod = false
          this.succesAlert()
          let id = response.data._links.self.href.split("/").pop();
          this.$router.push('setup-test-type/' + id);
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    putData(endpoint, data) {
      axios
        .put(endpoint, data)
        .then(() => {
          this.isMod = false
          this.succesAlert()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

  },

  created() {
    if (this.$route.params.id) {
      this.getData();
    } else {
      this.loading = false;
    }
  },

  validations: {

    objectData: {
      name: {
        required,
      }
    }
  }
};
</script>

<style scoped>
.form-group {
  text-align: left;
  font-weight: bold;
}
</style>
