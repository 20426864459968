<template>
<div v-can="'Read_FormulaTest'">

    <div class="container-fluid">

        <h2 class="text-left"><router-link to="/tests">{{$tc('general.test', 1)}}</router-link></h2>

        <LayoutCard :title="$tc('general.informations', 1)" active="true">

            <template v-slot:header-options>
                <font-awesome-icon v-show="$route.params.id" icon="file-pdf" @click="TestPdf" class="clickable fa-lg mr-2 ml-1" v-b-tooltip.hover title="ctrl+s / ctrl+p" />
                <DeleteButton v-can="'Delete_FormulaTest'" endpoint="formulaTest" dataToDisplay="Test" searchValue="null" :toDeleteWithRegister="$store.state.test.endpointToDelete"></DeleteButton>
            </template>

            <div class="loader" v-if="loading">
            </div>

            <fieldset :disabled="loading">
                <form @change="save">

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label>{{$t('tests.testType')}}</label>
                            <b-form-select v-model="$v.testType.$model" :disabled="this.$route.params.id ? true : false" @change="loadTemplate" :state="validateState($v.testType)" value-field="_links.self.href" text-field="name"
                                :options="testTypeList">
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{$t('general.selectStatus')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>

                        <div class="form-group col-md-3">
                            <label>{{$t('tests.targetDate')}}</label>
                            <b-form-datepicker v-can:datepicker="'Update_FormulaTest'" @input="save" id="inputTargetDate" :placeholder="$t('tests.targetDate')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="test.targetDate" class="mb-2">
                            </b-form-datepicker>
                        </div>

                        <div class="form-group col-md-3">
                            <label>{{$t('tests.resultDate')}}</label>
                            <b-form-datepicker v-can:datepicker="'Update_FormulaTest'" @input="save" id="inputResultDate" :placeholder="$t('tests.resultDate')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="test.resultDate" class="mb-2">
                            </b-form-datepicker>
                        </div>

                        <div class="form-group col-md-2">
                            <label>{{$t('general.status')}}</label>
                            <v-select id="inputResultStatus" :placeholder="$t('general.selectStatus')" v-model="$v.test.result.$model" :reduce="result => result.id" :clearable="false" :options="testResultList" label="name" @input="save" :class="validateStatus($v.test.result)"
                                class="vselect">
                            </v-select>
                            <div class="error" id="status-required" v-if="!$v.test.result.required && $v.test.result.$dirty">{{$t('errorMsg.required')}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-2">
                            <label v-if='!loading && this.$route.params.id'><router-link :to="'/formulation/' + selectedFormulation._links.self.href.split('/').pop()">{{$t('formulation.id')}}</router-link></label>
                            <label v-else>{{$t('formulation.id')}}</label>
                            <span class="ml-2">
                                <font-awesome-icon v-can="'Update_FormulaTest'" class="clickable" icon="search" @click="toggleModal()" /></span>
                            <b-form-input v-can:input="'Update_FormulaTest'" type="text" list="formulation-list" @input="getFormulations(formulationId); setFormulation(null)" autocomplete="off" :state="validateState($v.formulationId)" class="form-control" placeholder="ID00001"
                                :trim="true" v-model="formulationId" />
                            <div class="invalid-feedback" v-if="!$v.formulationId.required">{{$t('errorMsg.required')}}</div>
                            <div class="invalid-feedback" v-if="!$v.formulationId.formulationIdMustExist">Formulation must exist</div>

                            <datalist id="formulation-list">
                                <option v-for="formulation in formulations" :key="formulation._links.self.href" field-value="formula._links.self.href"> {{ formulation.prefixId }}</option>
                            </datalist>
                        </div>

                        <div v-if="isFormulationSet == true && $v.formulationId.formulationIdMustExist" class="form-group col-md-1">
                            <label>{{$t('formulation.version')}}</label>
                            <b-form-input type="text" readonly class="form-control" v-model="selectedFormulation.version" />
                        </div>

                        <div v-if="isFormulationSet == true && $v.formulationId.formulationIdMustExist" class="form-group col-md-3">
                            <label>{{$t('formulation.formulationName')}}</label>
                            <b-form-input type="text" readonly class="form-control" v-model="selectedFormulation.name" />
                        </div>

                        <div v-if="isFormulationSet == true && $v.formulationId.formulationIdMustExist" class="form-group col-md-3">
                            <label v-if='!loading && this.$route.params.id'><router-link :to="'/product/' + product._links.self.href.split('/').pop()">{{$t('product.productName')}}</router-link></label>
                            <label v-else>{{$t('product.productName')}}</label>
                            <b-form-input type="text" readonly class="form-control" v-model="product.productName" />
                        </div>

                        <div v-if="isFormulationSet == true && $v.formulationId.formulationIdMustExist" class="form-group col-md-3">
                            <label v-if='!loading && this.$route.params.id'><router-link :to="'/project/' + project._links.self.href.split('/').pop()">{{$t('project.projectName')}}</router-link></label>
                            <label v-else>{{$t('project.projectName')}}</label>
                            <b-form-input type="text" readonly class="form-control" v-model="project.projectName" />
                        </div>

                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputObservations">{{$t('tests.observations')}}</label>
                            <b-form-textarea v-can:input="'Update_FormulaTest'" rows="5" max-rows="10" debounce="500" class="form-control" id="inputObservations" placeholder="Observations related to the product" v-model="test.obs"></b-form-textarea>
                        </div>

                        <div v-can="'Read_FileManager'" class="form-group col-md-6" v-if="this.$route.params.id && !loading">
                            <label for="inputFiles">Files</label>
                            <FileManager repositoryName="formulatest" :pathIdList="[this.selectedFormulation._links.formula.href.split('/').pop(), this.test._links.formulaTest.href.split('/').pop()]"
                                acceptExtensions=".doc, .docx, .txt, .pdf, .xls, .xlsx" permission="Update_FormulaTest"/>
                        </div>
                    </div>

                </form>
            </fieldset>

        </LayoutCard>

        <LayoutCard :title="$tc('general.test', 1)" active="false">

            <EditableGridJson :array="testItems" permission="Update_FormulaTest" :value.sync="testItems" @edited="save()" :key="editableGridJsontKey"></EditableGridJson>

        </LayoutCard>

        <div v-if="isMod">

            <SaveCancelDeleteButtons permission="Create_FormulaTest" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>

        </div>

        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                {{ feedbackMessage }}
            </b-alert>
        </div>

        <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('general.history')">
            <History :dataType="endpoint" :addBy="test.addBy" :addDate="test.addDate" :lastModBy="test.modBy" :lastMod="test.modDate" />
        </LayoutCard>

    </div>

    <b-modal ref="search-formulation" size="xl" scrollable :title="$t('general.search')">
        <template #modal-title="">
            <h3>{{$t('general.search')}}</h3>
        </template>

        <MainGrid gridTitle="Formulations" objectNodeName="entityModels" endpoint="formula" :gridColumns="columns" :filter="filter" formUrl="/formulation" :queryByFilter="true" :customOrderBy="true" @close="toggleModal()"
            @selectValue="setFormulation"></MainGrid>

        <template #modal-footer="{ hide }">
            <b-button variant="secondary" @click="hide('forget')">
                {{$t('general.close')}}
            </b-button>
        </template>
    </b-modal>

</div>
</template>

<script>
import axios from 'axios'
import History from '../components/History.vue'
import LayoutCard from '../components/LayoutCard.vue'
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'
import DeleteButton from '../components/DeleteButton.vue';
import MainGrid from '../components/MainGrid.vue';
import EditableGridJson from '../components/EditableGridJson.vue'
import FileManager from '../components/FileManager'
import moment from 'moment'
import {
    required
} from 'vuelidate/lib/validators'

function formulationIdMustExist(value) {
    if (this.formulations.filter(e => e.prefixId === value).length != 0) {
        return true
    }
    return false
}

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'
import TestReportPdf from '../mixins/TestReportPdf.js'

window.axios = require("axios");

export default {
    name: 'Test',
    endpoint: "formulaTest",

    mixins: [Feedback, CommonVariablesAndFunctions, TestReportPdf],

    components: {
        History,
        LayoutCard,
        SaveCancelDeleteButtons,
        DeleteButton,
        MainGrid,
        FileManager,
        EditableGridJson,
    },

    data() {
        return {
            title: "Test",
            endpoint: "formulaTest",

            testTypeList: [],
            testType: "",

            editableGridJsontKey: 0,

            testResultList: [],

            openedModal: null,

            formulationId: "",
            selectedFormulation: {
                prefixId: "",
                name: "",
                _links: {
                    self: {
                        href: ""
                    },
                }
            },

            formulations: [],
            isFormulationSet: false,

            product: {},

            project: {},

            test: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: null,
                obs: "",
                targetDate: null,
                resultDate: null,
                result: "",
                data: ""
            },

            testItems: [
                ['']
            ],

            columns: [{
                    label: this.$t('formulation.formulationName'),
                    key: "name",
                    sortable: true,
                    initial_sort: true
                },
                {
                    label: this.$t('formulation.id'),
                    key: "prefixId",
                    sortable: true
                },
                {
                    label: this.$t('formulation.version'),
                    key: "version",
                    sortable: true
                },
                {
                    label: this.$t('general.status'),
                    key: "statusName",
                    sortable: true
                },
                {
                    label: this.$t('general.clientName'),
                    key: "companyName",
                    sortable: true
                },
                {
                    label: this.$t('project.projectName'),
                    key: "projectName",
                    sortable: true
                },
                {
                    label: this.$t('product.productName'),
                    key: "productName",
                    sortable: true
                },
                {
                    key: "returnButton",
                }
            ],
            filter: [{
                method: "findByClientProjectProduct",
                params: ["searchValue"]
            }]

        };
    },

    computed: {
        targetDateToIso: function() {
            let date = moment(this.test.targetDate).toISOString()
            return date
        },

        resultDateToIso: function() {
            let date = moment(this.test.resultDate).toISOString()
            return date
        },

    },

    methods: {

        async loadTemplate() {
            let response = await axios.get(this.testType)
            this.testItems = JSON.parse(response.data.dataTemplate)
            this.editableGridJsontKey += 1

        },

        async getData() {
            this.loading = true;

            let response = await axios.get(this.endpoint + '/' + this.$route.params.id)
            this.test = response.data

            //get test type Data
            let testTypeResponse = await axios.get(this.test._links.formulaTestType.href)
            this.testType = testTypeResponse.data._links.self.href

            this.testItems = JSON.parse(response.data.data)
            this.editableGridJsontKey += 1

            // get formulation information
            let responseFormulation = await axios.get(this.test._links.formula.href);
            this.selectedFormulation = responseFormulation.data
            this.formulationId = responseFormulation.data.prefixId

            //this is needed for the validation formulationIdMustExist
            this.formulations.push(responseFormulation.data)
            this.$v.formulationId.$touch();

            // get product information
            let responseProduct = await axios.get(this.selectedFormulation._links.product.href);
            this.product = responseProduct.data

            //get project information
            let responseProject = await axios.get(this.product._links.project.href);
            this.project = responseProject.data

            this.isFormulationSet = true
            this.loading = false;
            this.isMod = false;
        },


        processForm() {

            let testTemplateStringify = JSON.stringify(this.testItems);

            let test = {
                obs: this.test.obs,
                targetDate: this.targetDateToIso,
                resultDate: this.resultDateToIso,
                result: this.test.result,
                data: testTemplateStringify,

                formulaTestType: this.testType,
                formula: this.selectedFormulation._links.self.href
            };

            return test

        },

        async postData(endpoint, data) {

            try {
                //test informations data
                let test = await axios.post(endpoint, data);

                let id = test.data._links.self.href.split("/").pop();

                this.isMod = false;
                this.succesAlert()

                //partial get to get _links used by fileManager
                this.loading = true;
                let response = await axios.get(this.endpoint + '/' + id)
                this.test = response.data
                this.loading = false;

                this.$router.push('test/' + id);

            } catch (error) {
                console.log(error);
                // this.errorAlert(error);
            }

        },

        async putData(endpoint, data) {
            try {
                //Update informations
                await axios.put(endpoint, data);

                this.isMod = false;
                this.succesAlert()

            } catch (error) {
                this.errorAlert(error);
            }

        },

        toggleModal() {
            this.$refs['search-formulation'].toggle()
        },

        async getFormulations(formulationId) {

            if (formulationId.length > 1) {
                var filter = "/search/findByPrefixIdContainingIgnoreCaseOrderByPrefixId";

                var paramValue = {
                    "prefixId": formulationId,
                };

                let responseFormulations = await axios.get("formula" + filter, {
                    params: paramValue
                })
                this.formulations = responseFormulations.data._embedded.formula;
            }

        },

        async setFormulation(value) {

            //check if the user delete his input
            if (this.formulationId.length == 0 && value == null) {
                return
            }

            //if the user type the formulation ID
            if (value == null) {
                this.selectedFormulation = this.formulations.filter(formulation => formulation.prefixId == this.formulationId)[0]
                if (this.selectedFormulation === undefined) {
                    return
                }
            }
            // if the user use the search function
            else {
                let responseFormulation = await axios.get(value);
                this.selectedFormulation = responseFormulation.data
                this.formulationId = responseFormulation.data.prefixId

                this.formulations = []
                //this is needed for the validation formulationIdMustExist
                this.formulations.push(responseFormulation.data)

            }

            // get product information
            let responseProduct = await axios.get(this.selectedFormulation._links.product.href);
            this.product = responseProduct.data

            //get project information
            let responseProject = await axios.get(this.product._links.project.href);
            this.project = responseProject.data

            this.isFormulationSet = true

            this.save()

        },

        async loadOptionFields() {

            axios
                .get('formulaTestType')
                .then((response) => {
                    this.testTypeList = response.data._embedded.formulaTestType
                })

            axios
                .get('formulaTestResult')
                .then((response) => {
                    this.testResultList = response.data.content
                })

        },

        exportPdf(e) {

            if ((e.key == 's' && e.metaKey == true) || (e.key == 's' && e.ctrlKey == true) || (e.key == 'p' && e.ctrlKey == true) || (e.key == 'p' && e.metaKey == true)) {
                e.preventDefault();
                this.TestPdf();
            }
        },

    },

    created() {

        this.loadOptionFields()

        if (this.$route.params.id) {
            this.getData();
        } else {
            if (this.$route.query.parentData) {
                this.setFormulation('formula/' + this.$route.query.parentData)
            }
            this.loading = false;
        }
    },

    mounted() {
        document.addEventListener("keydown", this.exportPdf);
    },

    beforeDestroy() {
        document.removeEventListener("keydown", this.exportPdf);
    },

    validations: {
        test: {
            result: {
                required,
            },
        },
        testType: {
            required,
        },
        formulationId: {
            required,
            formulationIdMustExist
        }
    }
};
</script>

<style scoped>
.form-group {
    text-align: left;
    font-weight: bold;
}
</style>
