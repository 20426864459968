<template>
<div v-can="'Read_InciAuxiliary'">

    <MainGrid :gridTitle="$t('ingredient.nomenclature')" objectNodeName="inciAuxiliary" endpoint="inciAuxiliary" :gridColumns="nomenclatureColumns" :filter="nomenclatureFilter" formUrl="/nomenclature" deletedataToDisplay="inciName"
        @selectedItem="SelectedNomenclature"></MainGrid>


    <b-modal id="modal-edit" ref="modal" title="Edit nomenclature" @ok="saveNewInciName">
        <form ref="form">
            <b-form-group label="Name" label-for="name-input">
                <b-form-input v-can:input="'Update_InciAuxiliary'" id="name-input" v-model="selectedItem.inciName"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                {{ feedbackMessage }}
            </b-alert>
        </div>
</div>
</template>

<script>
import axios from 'axios'
import MainGrid from '../components/MainGrid'
import Feedback from '../mixins/Feedback.js'

window.axios = require("axios");

export default {
    name: 'RegulationsNomenclaturesGrid',

    mixins: [Feedback],

    components: {
        MainGrid,
    },
    data() {
        return {

            nomenclatureColumns: [{
                    key: 'addBy',
                    label: 'Add by',
                    sortable: true
                },
                {
                    key: 'addDate',
                    label: 'Add on',
                    sortable: true,
                    slot_name: "date"
                },
                {
                    key: 'modBy',
                    label: 'Modified by',
                    sortable: true,
                },
                {
                    key: 'modDate',
                    label: 'Modified on',
                    sortable: true,
                    slot_name: "mod-date"
                },
                {
                    key: 'inciName',
                    label: 'Nomenclature',
                    sortable: true
                },
                {
                    key: "modalButton",
                    label: "",
                }
            ],

            nomenclatureFilter: [{
                method: "findByInciNameContainingIgnoreCase",
                params: ["inciName"]
            }],

            selectedItem: {}
        }
    },
    methods: {
        SelectedNomenclature(item) {
            this.selectedItem = item
        },
        saveNewInciName() {
            let data = {
                inciName: this.selectedItem.inciName
            }
            let id = this.selectedItem._links.self.href.split("/").pop();
            let endpoint = 'inciAuxiliary/' + id

            try {
                axios.put(endpoint, data)
                    .then(
                        this.succesAlert()
                        
                    )
            } catch (error) {
                console.log(error);
            }
        },

    }
}
</script>

<style>
.btn-icon {
    cursor: pointer;
    margin: 0px 5px;
}
</style>
