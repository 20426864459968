<template>
<div v-can="'Read_Distributor'">

    <div class="container-fluid">

        <div class="col-md-12">

            <h2 class="text-left"><router-link to="/distributors">{{$tc('general.distributor', 1)}}</router-link></h2>

            <LayoutCard :title="$t('general.informations')" active="true">

                <template v-slot:header-options>
                    <DeleteButton v-can="'Delete_Distributor'" endpoint="distributor" :dataToDisplay="objectData.distributorName" :searchValue="objectData.distributorName" :relationship="$store.state.distributor.associatedData"></DeleteButton>
                </template>

                <div class="loader" v-if="loading">
                </div>

                <fieldset :disabled="loading">
                    <form @change="save()">

                        <div class="form-row">
                            <div class="form-group col-md-10">
                                <label for="inputCompanyName">{{$t('distributor.companyName')}}</label>
                                <b-form-input v-can:input="'Update_Distributor'" type="text" class="form-control" id="inputCompanyName" :placeholder="$t('distributor.companyName')" :state="validateState($v.objectData.distributorName)"
                                    @input="getDistributorName(objectData.distributorName)" :trim="true" v-model="$v.objectData.distributorName.$model" />
                                <div class="invalid-feedback" id="company-name-required" v-if="!$v.objectData.distributorName.required">{{$t('errorMsg.required')}}</div>
                                <div class="invalid-feedback" v-if="!$v.objectData.distributorName.isDistributorNameUnique">This distributor already exist</div>
                            </div>

                            <div class="form-group col-md-2">
                                <label>{{$t('general.status')}}</label>
                                <v-select id="elect-distributor-status" placeholder="Select a status" v-model="$v.objectData.status.$model" :reduce="status => status.id" :selectable="status => status.selectable" :clearable="false" :options="distributorStatus" label="name" @input="save" :class="validateStatus($v.objectData.status)"
                                    class="vselect">
                                </v-select>
                                <div class="error" id="status-required" v-if="!$v.objectData.status.required && $v.objectData.status.$dirty">{{$t('errorMsg.required')}}</div>
                            </div>

                        </div>

                        <div class="form-group">
                            <label for="inputEmail">{{$t('contact.email')}}</label>
                            <input v-can:input="'Update_Distributor'" type="text" class="form-control" id="inputEmail" :placeholder="$t('contact.email')" v-model="objectData.email">
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputPhone">{{$t('contact.phoneNumber')}}</label>
                                <input v-can:input="'Update_Distributor'" type="text" class="form-control" id="inputPhone" :placeholder="$t('contact.phoneNumber')" v-model="objectData.phoneNumber">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputFax">{{$t('contact.faxNumber')}}</label>
                                <input v-can:input="'Update_Distributor'" type="text" class="form-control" id="inputFax" :placeholder="$t('contact.faxNumber')" v-model="objectData.faxNumber">
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="inputAdress">{{$t('contact.adress')}}</label>
                            <input v-can:input="'Update_Distributor'" type="text" class="form-control" id="inputAdress" placeholder="1234 Main street" v-model="objectData.address">
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="inputCity">{{$t('contact.city')}}</label>
                                <input v-can:input="'Update_Distributor'" type="text" class="form-control" id="inputCity" :placeholder="$t('contact.city')" v-model="objectData.city">
                            </div>
                            <div class="form-group col-md-3">
                                <label for="inputState">{{$t('contact.state')}}</label>
                                <input v-can:input="'Update_Distributor'" type="text" class="form-control" id="inputState" :placeholder="$t('contact.state')" v-model="objectData.state">
                            </div>
                            <div class="form-group col-md-2">
                                <label for="inputZip">{{$t('contact.zipCode')}}</label>
                                <input v-can:input="'Update_Distributor'" type="text" class="form-control" id="inputZip" :placeholder="$t('contact.zipCode')" v-model="objectData.zipCode">
                            </div>

                            <div class="form-group col-md-3">

                                <label for="inputCountry">{{$t('contact.country')}}</label>

                                <b-form-input v-can:input="'Update_Distributor'" id="input-distributor-country" list="country-list" :placeholder="$t('contact.country')" autocomplete="off" :state="validateCountry()" :lazy="true" :trim="true"
                                    v-model="$v.selectedDistributorCountryName.$model"></b-form-input>
                                <div class="invalid-feedback" v-if="!$v.selectedDistributorCountryName.required && $v.selectedDistributorCountryName.$dirty">{{$t('errorMsg.required')}}</div>
                                <div class="invalid-feedback" id="country-must-exist" v-if="!$v.selectedDistributorCountryId.countryMustExist && $v.selectedDistributorCountryName.$dirty">Country must exist</div>

                                <datalist id="country-list">
                                    <option v-for="country in countries" :key="country.id" field-value="country.id"> {{ country.name }}</option>
                                </datalist>

                            </div>
                        </div>

                    </form>
                </fieldset>

            </LayoutCard>

            <LayoutCard :title="$tc('general.contact', 2)" active="true">

                <ListingContact endpoint="distributor" :contacts.sync="contacts" :isContactSet="isContactSet" :nameToDisplay="objectData.distributorName" @contactSet="isContactSet = true" @saved="contactSaved" :totalRows="contacts.length">
                </ListingContact>
                <div class="error small" id="contact-required" v-if="!$v.isContactSet.selected && $v.$anyError">{{$t('errorMsg.required')}}</div>

            </LayoutCard>

            <!-- <LayoutCard :title="$tc('general.material', 2)">

                <div class="loader" v-if="loading">
                </div>

                <div v-else>


                    <div>

                        Here a list of raw materials

                    </div>

                </div>

            </LayoutCard> -->

            <div v-if="isMod">

                <SaveCancelDeleteButtons permission="Create_Distributor" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>

            </div>

            <div class="fixed-top mt-3 d-flex justify-content-center">
                <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                    {{ feedbackMessage }}
                </b-alert>
            </div>

            <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('general.history')">

                <History :dataType="endpoint" :addBy="objectData.addBy" :addDate="objectData.addDate" :lastModBy="objectData.modBy" :lastMod="objectData.modDate" />

            </LayoutCard>

        </div>

    </div>
</div>
</template>

<script>
import axios from 'axios';
import History from '../components/History.vue';
import LayoutCard from '../components/LayoutCard.vue';
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue';
import DeleteButton from '../components/DeleteButton.vue';
import ListingContact from '../components/ListingContact.vue';
import {
    required,
    minLength,
    email
} from 'vuelidate/lib/validators'

import {
    mapActions
} from "vuex";

function isDistributorNameUnique(value) {
    if (this.distributors.filter(e => e.distributorName.toLowerCase() === value.toLowerCase()).length == 0) {
        return true
    }
    return false
}

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

window.axios = require("axios");
const countryMustExist = (value) => value != ""

export default {
    name: 'Distributor',

    mixins: [Feedback, CommonVariablesAndFunctions],

    components: {
        History,
        SaveCancelDeleteButtons,
        DeleteButton,
        LayoutCard,
        ListingContact,
    },

    props: {
        distributorId: {
            required: false
        }
    },

    data() {
        return {
            title: "Distributor",
            endpoint: "distributor",

            distributors: [],

            contacts: [],
            isContactSet: false,

            openedModal: null,

            countries: [],
            selectedDistributorCountryName: "",
            selectedCountry: {
                name: "",
                id: ""
            },


            objectData: {
                addBy: "TODO",
                addDate: "2020-09-09T14:10:22.485Z",
                modBy: "TODO",
                modDate: "2020-09-09T14:10:22.485Z",
                distributorName: "",
                phoneNumber: "",
                faxNumber: "",
                email: "",
                address: "",
                city: "",
                state: "",
                zipCode: "",
                country: "",
                status: "",
                contact: [{
                    addBy: "TODO",
                    addDate: "2020-09-09T14:10:22.474Z",
                    modBy: "TODO",
                    modDate: "2020-09-09T14:10:22.474Z",
                    firstName: "",
                    lastName: "",
                    role: "",
                    phoneNumber: "",
                    faxNumber: "",
                    email: "",
                    address: "",
                    city: "",
                    zipCode: "",
                    state: "",
                    country: "",
                    status: ""
                }],
            },

        };
    },

    computed: {

        selectedDistributorCountryId: function() {

            if (this.selectedDistributorCountryName != "") {
                if (this.countries.filter(country => country.name == this.selectedDistributorCountryName).length != 0) {
                    let id = this.countries.filter(country => country.name == this.selectedDistributorCountryName)[0].id
                    return id
                } else {
                    return ""
                }
            }
            return ""
        },

        distributorStatus: function() {

            if (this.$store.getters.getStatusFile.distributor.length == 0) {
                this.statusFile('distributor')
            }

            return this.$store.getters.getStatusFile.distributor
        },

    },

    methods: {
        ...mapActions(["statusFile"]),

        validateCountry() {
            if (this.$v.selectedDistributorCountryName.$dirty && this.$v.selectedDistributorCountryName.$error) {
                return false
            }
            if (this.$v.selectedDistributorCountryName.$dirty != "" && this.$v.selectedDistributorCountryId.$invalid) {
                return false
            }
            if (this.$v.selectedDistributorCountryName.$dirty) {
                return true
            }
            return null
        },

        async getData() {
            this.loading = true;

            let response = await axios.get(this.endpoint + '/' + this.$route.params.id)
            this.objectData = response.data

            //get the country name
            let country = await axios.get('country/' + response.data.country)
            this.selectedDistributorCountryName = country.data.name

            //get the contacts
            let responseContact = await axios.get(this.endpoint + '/' + this.$route.params.id + '/contacts')

            if (responseContact.data._embedded.contact.length != 0) {

                this.contacts = responseContact.data._embedded.contact

                this.isContactSet = true;
            }

            this.loading = false;
            this.isMod = false;

        },

        processForm() {

            let distributor = {
                distributorName: this.objectData.distributorName.charAt(0).toUpperCase() + this.objectData.distributorName.slice(1),
                status: this.objectData.status,
                email: this.objectData.email,
                phoneNumber: this.objectData.phoneNumber,
                faxNumber: this.objectData.faxNumber,
                address: this.objectData.address,
                city: this.objectData.city,
                state: this.objectData.state,
                zipCode: this.objectData.zipCode,
                country: this.selectedDistributorCountryId,
            };

            return distributor
        },

        async getDistributorName(input) {
            if (input.length > 1) {
                var filter = "/search/findByDistributorNameContainingIgnoreCaseOrderByDistributorName";
                var paramValue = {
                    "distributorName": input,
                };
                let response = await axios.get("distributor" + filter, {
                    params: paramValue
                })
                this.distributors = response.data._embedded.distributor;
            }
        },

        async postData(endpoint, data) {

            try {
                //Post informations data
                let distriburor = await axios.post("distributor", data);

                let id = distriburor.data._links.self.href.split("/").pop();

                //put contacts
                let contactsLink
                for (let i = 0; i < this.contacts.length; i++) {
                    contactsLink += this.contacts[i]._links.self.href + '\n'
                }

                await axios.put('distributor/' + id + '/contacts', contactsLink, {
                    headers: {
                        "Content-type": "text/uri-list"
                    }
                })

                this.isMod = false;
                this.succesAlert()
                this.$router.push(this.endpoint + '/' + id);
                this.getData();

            } catch (error) {
                this.errorAlert(error);
            }

        },

        async putData(endpoint, data) {

            try {
                //Update informations
                await axios.put(endpoint, data);

                // update contact
                // see ListingContact component

                this.isMod = false;
                this.succesAlert()
                this.getData();

            } catch (error) {
                this.errorAlert(error);
            }

        },

        showModal(type) {
            this.openedModal = type
        },

        contactSaved() {
            this.succesAlert()
        },

        async loadOptionFields() {

            //get country
            axios
                .get('country')
                .then((response) => {
                    this.countries = response.data.content
                })

        },

    },
    //launch once the view is created
    created() {
        this.loadOptionFields();

        if (this._props.distributorId != null){
            this.$route.params.id = this._props.distributorId
        }
        if (this.$route.params.id != undefined) {
            this.getData();
        } else {
            this.loading = false;
        }

    },
    validations: {

        objectData: {
            distributorName: {
                required,
                minLength: minLength(1),
                isDistributorNameUnique
            },
            email: {
                email
            },
            status: {
                required,
            }
        },
        selectedDistributorCountryName: {
            required,
        },
        selectedDistributorCountryId: {
            countryMustExist,
        },
        isContactSet: {
            selected: value => value === true
        }
    }
};
</script>

<style scoped>
.form-group {
    text-align: left;
    font-weight: bold;
}

.error {
    color: crimson;
    font-weight: bold;
}
</style>
