<template>
<div class="container-fluid" v-can="'Read_Formula'">
  <div class="row">
    <div class="col-md-12">

      <h2 class="text-left"><router-link to="/formulations">{{$tc('general.formulation', 1)}}</router-link></h2>

      <LayoutCard :title="$t('general.informations')" active="true" ref="myDiv">

        <template v-slot:header-options>
          <DeleteButton v-can="'Delete_Formula'" endpoint="formula" :dataToDisplay="formula.name" searchValue="null" :relationship="$store.state.formulation.associatedData" :toDeleteWithRegister="$store.state.formulation.endpointToDelete">
          </DeleteButton>
        </template>

        <div class="loader" v-if="loading">
        </div>

        <fieldset :disabled="loading">
          <form @change="save()">

            <div class="form-row">
              <div v-if="this.$route.params.id" class="form-group col-md-2">
                <label>ID</label>
                <span class="ml-2">
                    <font-awesome-icon class="clickable" icon="arrow-circle-right" @click="$bvModal.show('search-formulation')" />
                </span>
                <input @dblclick="$bvModal.show('search-formulation')" type="text" class="form-control" readonly v-model.trim="formula.prefixId">
              </div>

              <div v-else class="form-group col-md-2">
                <label>ID</label>

                <b-form-input v-can:input="'Update_Formula'" list="formulaPrefix-list" placeholder="Prefix" type="text" class="form-control" autocomplete="off" :state="validateState($v.formula.prefix)" :lazy="true" :trim="true"
                  v-model.trim="$v.formula.prefix.$model" />
                <div class="invalid-feedback" v-if="!$v.formula.prefix.required">{{$t('errorMsg.required')}}</div>

                <datalist id="formulaPrefix-list">
                  <option v-for="formulaPrefix in formulaPrefixs" :key="formulaPrefix.name" field-value="material.name"> {{ formulaPrefix.name }}</option>
                </datalist>

              </div>

              <div class="form-group col-md-2">
                <label>{{$t('formulation.version')}}</label>
                <span class="ml-2" v-can="'Update_Formula'">
                  <font-awesome-icon id="plus-circle-button" icon="plus-circle" class="clickable mr-2" v-if="this.$route.params.id" @click="showModal('new-version')" />
                </span>
                <b-icon icon="clipboard-data" v-if="this.$route.params.id" class="btn-icon" @click="$bvModal.show('modal-compare-version')"></b-icon>
                <DeleteButton v-can="'Delete_Formula'" v-if="this.$route.params.id && isAllowedDeleteVersion(logUser.roleName.toLowerCase(), versionOptions)" endpoint="formula" :dataToDisplay="formula.name" searchValue="null"
                  :relationship="$store.state.formulation.associatedData" :toDeleteWithRegister="$store.state.formulation.endpointToDelete"></DeleteButton> 
                <v-select id="inputVersion" v-model="formula.version" label="version" :clearable="false" :options="versionOptions" :selectable="version => version.id != this.$route.params.id" @input="changeVersion(formula.version.id)"
                  class="vselect">
                </v-select>
              </div>

              <div class="form-group col-md-5">
                <label>{{$t('formulation.formulationName')}}</label>
                <b-form-input v-can:input="'Update_Formula'" type="text" class="form-control" placeholder="Name" :state="validateState($v.formula.name)" :lazy="true" :trim="true" v-model="$v.formula.name.$model" />
                <div class="invalid-feedback" v-if="!$v.formula.name.required">{{$t('errorMsg.required')}}</div>
              </div>

              <div class="form-group col-md-3">
                <label>{{$t('general.status')}}</label>
                <v-select id="inputFormulaStatus" :placeholder="$t('general.selectStatus')" v-model="$v.formula.status.$model" :reduce="status => status.id" :selectable="status => status.selectable" :clearable="false" :options="formulaStatus"
                  label="name" @input="save" :class="validateStatus($v.formula.status)" class="vselect">
                </v-select>
                <div class="error" id="status-required" v-if="!$v.formula.status.required && $v.formula.status.$dirty">{{$t('errorMsg.required')}}</div>
              </div>
            </div>

            <div class="form-row">

              <div class="form-group col-md-4">
                <label v-if='!loading && this.$route.params.id'>
                  <router-link :to="'/product/' + selectedProduct._links.self.href.split('/').pop()">{{$t('product.productName')}}</router-link>
                </label>
                <label v-else>{{$t('product.productName')}}</label>
                <span v-if="!this.$route.params.id" class="ml-2" v-can="'Update_Formula'">
                  <font-awesome-icon icon="search" class="btn-icon" @click="showModal('product')" />
                </span>
                <span v-else class="ml-2" v-can="'Update_Formula'">
                  <font-awesome-icon icon="clone" class="btn-icon" @click="showModal('to-new-product')" />
                </span>
                <b-form-input v-if="!this.$route.params.id" v-can:input="'Update_Formula'" type="text" autocomplete="off" list="products-list" @input="getProducts(productName); setProduct(null)" :state="validateState($v.productName)"
                  class="form-control" placeholder="Product 1..." :trim="true" v-model="productName" />
                <input v-else type="text" readonly class="form-control" v-model="productName">

                <div class="invalid-feedback" v-if="!$v.productName.required">{{$t('errorMsg.required')}}</div>
                <div class="invalid-feedback" v-if="!$v.productName.productNameMustExist">Product must exist</div>

                <datalist id="products-list">
                  <option v-for="product in products" :key="product._links.self.href" field-value="product._links.self.href"> {{ product.productName }}</option>
                </datalist>

              </div>

              <div v-if="isProductSet == true && $v.productName.productNameMustExist" class="form-group col-md-4">
                <label v-if='!loading && this.$route.params.id'>
                  <router-link :to="'/project/' + project._links.self.href.split('/').pop()">{{$t('project.projectName')}}</router-link>
                </label>
                <label v-else>{{$t('project.projectName')}}</label>
                <b-form-input v-can:input="'Update_Formula'" type="text" readonly class="form-control" placeholder="Face, body..." :lazy="true" :trim="true" v-model="project.projectName" />
              </div>

              <div v-if="isProductSet == true && $v.productName.productNameMustExist" class="form-group col-md-4">
                <label v-if='!loading && this.$route.params.id'>
                  <router-link :to="'/client/' + client._links.self.href.split('/').pop()">{{$t('general.clientName')}}</router-link>
                </label>
                <label v-else>{{$t('general.clientName')}}</label>
                <b-form-input v-can:input="'Update_Formula'" type="text" readonly class="form-control" placeholder="Face, body..." :lazy="true" :trim="true" v-model="client.companyName" />
              </div>

            </div>

          </form>
        </fieldset>

      </LayoutCard>

      <LayoutCard v-if="this.$route.params.id" :title="$tc('general.formulation', 1)" active="true">

        <form>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label form-control-sm">{{$t('formulation.testQt')}} (g):</label>
            <div class="col-sm-2">
              <b-form-input v-can:input="'Update_Formula'" type="number" :formatter="formatterPositiveValue" class="form-control form-control-sm" placeholder="g" v-model="toWeight" />
            </div>

            <label class="col-sm-2 col-form-label form-control-sm"></label>
            <div class="col-sm-6">
              <SearchIngredientMaterial></SearchIngredientMaterial>
            </div>

          </div>
          <div class="form-group row" v-if="isProductSet == true">
            <label class="col-sm-2 col-form-label form-control-sm">{{$t('general.price')}} ($/kg)
              <font-awesome-icon class="btn-icon" :class=" isPriceDifferencePositif ? 'green' : 'red' " id="popover-price" icon="info-circle" /> :
            </label>
            <div class="col-sm-2">
              <input v-can:input="'Update_Formula'" type="number" readonly class="form-control form-control-sm" v-model="totalPrice">
            </div>

            <b-popover target="popover-price" ref="popoverPrice" triggers="hover" placement="top">
              <template #title>{{$t('general.informations')}}</template>
              <div>
                {{$t('product.priceLimit')}}: {{ Number(selectedProduct.priceLimit).toLocaleString() }} $/kg
              </div>
              <div :class=" isPriceDifferencePositif ? 'green' : 'red' ">
                {{$t('general.difference')}}: {{ Number(totalPrice - selectedProduct.priceLimit).toLocaleString() }} $/kg
              </div>
              <div :class=" isPriceDifferencePositif ? 'green' : 'red' ">
                {{$t('general.percentage')}}: {{ Math.round((totalPrice * 100) / selectedProduct.priceLimit).toLocaleString() }} %
              </div>
            </b-popover>

            <label class="col-sm-2 col-form-label form-control-sm"></label>
            <div v-if="!loading" class="col-sm-6">
              <NaturalOrganic :gridFormulationData="this.gridFormulationData"></NaturalOrganic>
            </div>

          </div>

        </form>

        <div class="d-flex flex-row-reverse">

          <Converter></Converter>

          <DropdownExport v-if="!loading" :gridRegulationId="regulationSelected" @exportPdf="FormulatPdf" @exportCvs="exportCvs($event)" :projectRegulationConformity="project._links.regulationConformity.href"></DropdownExport>

          <b-dropdown variant="outline-primary" size="sm" :text="$t('general.options')" right class="m-2">
            <b-dropdown-form>
              <b-dropdown-group :header="$t('general.hide')+'/'+$t('general.show')">
                <b-dropdown-divider></b-dropdown-divider>
                <b-form-checkbox v-model="showHideInci" class="mb-3" size="sm" style="width:150px">INCI</b-form-checkbox>
                <b-form-checkbox v-model="showHideToWeight" class="mb-3" size="sm">{{$t('formulation.quantityToWeight')}}</b-form-checkbox>
                <b-form-checkbox v-model="showHidePrice" class="mb-3" size="sm">{{$t('general.price')}}</b-form-checkbox>
              </b-dropdown-group>
              <b-dropdown-group :header="$t('general.display')">
                <b-dropdown-divider></b-dropdown-divider>
                <b-form-checkbox v-model="striped" class="mb-3" size="sm">{{$t('general.stripped')}}</b-form-checkbox>
                <b-form-checkbox v-model="bordered" class="mb-3" size="sm">{{$t('general.bordered')}}</b-form-checkbox>
                <b-form-checkbox v-model="isSmall" class="mb-3" size="sm">{{$t('formulation.smallText')}}</b-form-checkbox>
              </b-dropdown-group>

            </b-dropdown-form>
          </b-dropdown>

          <b-dropdown id="dropdown-inci" variant="outline-primary" size="sm" :text="regulationLabel" class="m-2">
            <b-dropdown-item v-for="(item, i) in regulationOfProjectAux" :key="i" @click="selectRegulation(item._links.regulation.href, item.name)">{{item.name}}</b-dropdown-item>
          </b-dropdown>

          <DropdownValidation :gridFormulationData="this.gridFormulationData" :projectWhitelist="this.projectWhitelist" :projectBlacklist="this.projectBlacklist"></DropdownValidation>

        </div>

        <div>
          <FormulationGrid :formulaId="formulaId ? formulaId : '0'" @update-grid="updateGrid" :projectBlacklist="this.projectBlacklist" :toWeight.sync="toWeight" :showHideInci.sync="showHideInci"
            :showHideToWeight.sync="showHideToWeight" :showHidePrice.sync="showHidePrice" :striped.sync="striped" :bordered.sync="bordered" 
            :regulationSelected="this.regulationSelected" :isSmall.sync="isSmall"></FormulationGrid>

        </div>

        <div class="form-row">

          <div class="form-group col-md-12">
            <label>{{$t('process.observations')}}</label>
            <b-form-textarea v-can:input="'Update_Formula'" v-model="formula.observation" @change="save()" rows="3" max-rows="10"></b-form-textarea>
          </div>

        </div>


      </LayoutCard>

      <LayoutCard v-if="this.$route.params.id" :title="$tc('process.process', 1)" active="true">

        <div v-can="'Update_Formula'" class="form-group row">
          <label class="col-sm-2 col-form-label form-control-sm">{{$t('process.selectProcess')}}:</label>
          <div class="col-sm-2">
            <b-form-select size="sm" v-model="processType" @change="loadTemplate" :state="validateState(processType)" value-field="_links.self.href" text-field="name" :options="processTypeList">
              <template v-slot:first>
                <b-form-select-option :value="''" disabled>{{$tc('process.template', 1)}}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>

        <div class="form-group col-md-12">
          <EditableGridJson :array="processTable" permission="Update_Formula" :value.sync="processTable" @edited="save()" :key="editableGridJsontKey"></EditableGridJson>
        </div>

      </LayoutCard>



      <div v-if="isMod">

        <SaveCancelDeleteButtons permission="Create_Formula" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>

      </div>

      <div class="fixed-top mt-3 d-flex justify-content-center">
        <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
          {{ feedbackMessage }}
        </b-alert>
      </div>

      <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('layoutCard.associatedTests')">
        <ListingGrid :column="testsColumn" :endpoint=' "/formula/" + this.$route.params.id + "/formulaTests" ' dataObject="formulaTest" redirectUrl="/test" :parentData="this.$route.params.id"></ListingGrid>
      </LayoutCard>

      <LayoutCard v-if="this.$route.params.id" :title="$t('general.history')">
        <History :dataType="endpoint" :addBy="formula.addBy" :addDate="formula.addDate" :lastModBy="formula.modBy" :lastMod="formula.modDate" />
      </LayoutCard>

      <ModalWindow v-show="openedModal === 'product'" :title="$t('general.search')" @close="openedModal = null">
        <MainGrid gridTitle="Product" objectNodeName="entityModels" endpoint="/product" :gridColumns="modalColumns" :filter="modalFilter" formUrl="/product" :queryByFilter="true" :customOrderBy="true" @close="openedModal = null"
          @selectValue="setProduct"></MainGrid>
      </ModalWindow>

    </div>

    <ModalWindow v-show="openedModal === 'new-version'" :title="$t('formulation.modalNewVersionTitle')" @close="openedModal = null" :width="400">

      <div>
        <p>{{$t('formulation.modalNewVersionText')}}</p>
        <p><b>{{$t('formulation.formulationName')}}: </b>{{ this.formula.name }}</p>
        <p><b>{{$t('formulation.version')}}: </b>{{ this.formula.version }}</p>
      </div>

      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="openedModal = null">{{$t('general.cancel')}}</button>
        <button @click="createNewVersion()" type="button" class="btn btn-primary" data-dismiss="modal">{{$t('general.create')}}</button>
      </template>

    </ModalWindow>

    <ModalWindow v-show="openedModal === 'delete-version'" :title="$t('formulation.modalDeleteVersionTitle')" @close="openedModal = null" :width="400">

      <div>
        <p>{{$t('delete.confirmBox')}}</p>
        <p><b>{{$t('formulation.formulationName')}}: </b>{{ this.formula.name }}</p>
        <p><b>{{$t('formulation.version')}}: </b>{{ this.formula.version }}</p>
      </div>

      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="openedModal = null">{{$t('delete.no')}}</button>
        <button @click="deleteVersion()" type="button" class="btn btn-primary" data-dismiss="modal">{{$t('delete.yes')}}</button>
      </template>

    </ModalWindow>

    <ModalWindow v-show="openedModal === 'to-new-product'" :title="$t('formulation.modalDuplicateFormula')" @close="openedModal = null" :width="600">

      <div>
        <CloneFormula :formula="this.formula" :formulaPrefixs="this.formulaPrefixs" @closeModal="openedModal = null" @duplicated="getData()" :gridFormulationData="gridFormulationData" :processTable="processTable"></CloneFormula>
      </div>

    </ModalWindow>

    <b-modal id="search-formulation" size="xl" scrollable :title="$t('general.search')">
        <template #modal-title="">
            <h3>{{$t('general.search')}}</h3>
        </template>

        <MainGrid gridTitle="Formulations" objectNodeName="entityModels" endpoint="formula" :gridColumns="columnsFormulas" :filter="filterFormulas" formUrl="/formulation" :queryByFilter="true" :customOrderBy="true"
            @selectValue="goTo"></MainGrid>

        <template #modal-footer="{ hide }">
            <b-button variant="secondary" @click="hide('forget')">
                {{$t('general.close')}}
            </b-button>
        </template>
    </b-modal>

    <b-modal id="modal-compare-version" size="xl" :title='"Compare version of " + this.formula.name.toLowerCase()'>
      <CompareFormulaVersion :formulaPrefix="formula.prefixId"></CompareFormulaVersion>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="$bvModal.hide('modal-compare-version')">
            {{$t('general.close')}}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>

</div>
</template>

<script>
import DropdownExport from '../components/formulation/DropdownExport.vue'
import DropdownValidation from '../components/formulation/DropdownValidation.vue'
import CompareFormulaVersion from '../components/formulation/CompareFormulaVersion.vue'
import FormulationGrid from '../components/formulation/FormulationGrid.vue'
import SearchIngredientMaterial from '../components/formulation/SearchIngredientMaterial.vue'
import CloneFormula from '../components/formulation/CloneFormula.vue'
import NaturalOrganic from '../components/formulation/NaturalOrganic.vue';


import axios from 'axios'
import History from '../components/History.vue'
import LayoutCard from '../components/LayoutCard.vue'
import ListingGrid from '../components/ListingGrid.vue';
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'
import DeleteButton from '../components/DeleteButton.vue'
import MainGrid from '../components/MainGrid.vue';
import ModalWindow from '../components/ModalWindow.vue';
import EditableGridJson from '../components/EditableGridJson.vue';
import Converter from '../components/Converter.vue';

import {
  required,
} from 'vuelidate/lib/validators';

import {
  mapActions,
  mapGetters
} from "vuex";

function productNameMustExist(value) {
  return this.products.filter(e => e.productName === value).length != 0
}

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'
import FormulaReportPdf from '../mixins/FormulaReportPdf.js'

window.axios = require("axios");

export default {
  name: 'Formulation',

  mixins: [Feedback, CommonVariablesAndFunctions, FormulaReportPdf],

  components: {
    DropdownExport,
    NaturalOrganic,
    DropdownValidation,
    FormulationGrid,
    SearchIngredientMaterial,
    History,
    Converter,
    CloneFormula,
    ListingGrid,
    LayoutCard,
    ModalWindow,
    EditableGridJson,
    MainGrid,
    SaveCancelDeleteButtons,
    DeleteButton,
    CompareFormulaVersion,
  },
  data() {
    return {

      endpoint: "formula",
      title: "Formulation",

      sortBy: 'phase',
      sortDesc: false,

      openedModal: null,

      formulaPrefixs: [],

      formulaId: 0,

      versionOptions: [],
      regulationSelected: [],

      productName: "",
      selectedProduct: {},
      products: [],
      isProductSet: false,

      regulationOfProject: [],
      regulationOfProjectAux: [],

      regulationLabel: "INCI: ",

      project: {},
      projectBlacklist: [],
      projectWhitelist: [],

      client: {},

      materials: [],

      striped: false,
      bordered: false,
      isSmall: false,
      toWeight: "",
      showHideToWeight: true,
      showHidePrice: true,
      showHideInci: true,

      gridFormulationData: [],

      formula: {
        addBy: "",
        addDate: "",
        modBy: "",
        modDate: null,
        version: null,
        name: "",
        process: "",
        status: "",
        prefix: "",
        prefixId: "",
        prefixIndex: "",
        ingredientItemOfFormulation: [{
          addBy: "",
          addDate: "",
          modBy: "",
          modDate: null,
          percentage: 0.0
        }],
        formulaMaterial: [{
            addBy: "",
            addDate: "",
            modBy: "",
            modDate: null,
            percentage: 70.0,
            phase: "",
            qsp: true
          },
          {
            addBy: "",
            addDate: "",
            modBy: "",
            modDate: null,
            percentage: 20.0,
            phase: "",
            qsp: false
          },
          {
            addBy: "",
            addDate: "",
            modBy: "",
            modDate: null,
            percentage: 10.0,
            phase: "",
            qsp: false
          }
        ],
      },

      processTypeList: [],
      processType: "",

      processTable: [
        ['A'],
      ],

      editableGridJsontKey: 0,

      modalColumns: [{
          label: this.$t('product.productName'),
          key: "productName",
          sortable: true,
          initial_sort: true
        },
        {
          label: this.$t('product.targetDate'),
          key: "targetDateString",
          sortable: true,
          initial_sort: false,
          slot_name: "date"
        },
        {
          label: this.$t('general.clientName'),
          key: "clientName",
          sortable: true,
          initial_sort: false
        },
        {
          label: this.$t('general.status'),
          key: "status",
          sortable: true,
          initial_sort: false,
          slot_name: "status"
        },
        {
          label: this.$t('formulation.formulationName'),
          key: "formulaName",
          sortable: true,
          initial_sort: false
        },
        {
          key: "returnButton",
        }
      ],
      modalFilter: [{
        method: "findByProductNameOrClientNameOrTargetDateOrStatusOrFormula",
        params: ["searchValue"]
      }],

      testsColumn: [{
          key: 'name',
          label: this.$t('general.name'),
          sortable: true
        },
        {
          key: 'targetDate',
          label: this.$t('project.targetDate'),
          sortable: true
        },
        {
          key: 'result',
          label: this.$t('general.status'),
          sortable: true
        },
        {
          key: 'resultDate',
          label: this.$t('tests.resultDate'),
          sortable: true
        },
        {
          key: 'goTo',
          label: this.$t('general.goTo'),
          sortable: false
        },
    ],

      columnsFormulas: [{
              label: this.$t('formulation.formulationName'),
              key: "name",
              sortable: true,
              initial_sort: true
          },
          {
              label: this.$t('formulation.id'),
              key: "prefixId",
              sortable: true
          },
          {
              label: this.$t('formulation.version'),
              key: "version",
              sortable: true
          },
          {
              label: this.$t('general.status'),
              key: "statusName",
              sortable: true
          },
          {
              label: this.$t('general.clientName'),
              key: "companyName",
              sortable: true
          },
          {
              label: this.$t('project.projectName'),
              key: "projectName",
              sortable: true
          },
          {
              label: this.$t('product.productName'),
              key: "productName",
              sortable: true
          },
          {
            key: "returnButton",
          }
      ],
      filterFormulas: [{
          method: "findByClientProjectProduct",
          params: ["searchValue"]
      }]

    }
  },

  computed: {
    ...mapGetters({
      logUser: "getUser",
    }),
    totalPrice: function() {
      let total = 0
      let grid = this.gridFormulationData
      for (let item of grid) {
        total += item.percentage / 100 * item.price
      }
      return total.toFixed(2)
    },

    isPriceDifferencePositif: function() {
      return this.totalPrice - this.selectedProduct.priceLimit < 0
    },

    formulaStatus: function() {

      if (this.$store.getters.getStatusFormulaProjectProduct.formula.length == 0) {
        this.statusFormulaProjectProduct('formula')
      }

      return this.$store.getters.getStatusFormulaProjectProduct.formula
    },

  },

  methods: {

    ...mapActions(["statusFormulaProjectProduct"]),

    goTo(value){
        if(value.split('/').pop() == this.$route.params.id){
            this.$bvModal.hide('search-formulation');
        }else{
            this.$router.push('/formulation/' + value.split('/').pop());
            this.$bvModal.hide('search-formulation');
            this.getData();
        }
    },

    selectRegulation(id, value) {
      this.regulationSelected = id
      this.regulationLabel = "INCI: " + value

      this.$root.$refs.FormulationGrid.getData();

    },

    isAllowedDeleteVersion(userRole, versions) {
      if (userRole === 'administrator') {
        return true;
      }

      if (versions !== undefined && versions.length > 0) {
        return versions[0].id === this.$route.params.id;
      }

      return false;
    },

    updateGrid(grid) {
      this.gridFormulationData = grid
    },

    formatterPositiveValue(value) {
      return Math.abs(value)
    },

    async loadTemplate() {
      let response = await axios.get(this.processType);
      this.processTable = JSON.parse(response.data.dataTemplate);
      this.editableGridJsontKey += 1;

      this.save();
    },

    async getData() {

      this.loading = true;
      this.products = [];
      this.formulaId = this.$route.params.id;

      //get formula data
      let response = await axios.get(this.endpoint + '/' + this.$route.params.id);
      this.formula = response.data;

      this.processTable = JSON.parse(this.formula.process)
      this.editableGridJsontKey += 1;

      //get the product data related to this formulation
      let productData = await axios.get(response.data._links.product.href);
      this.selectedProduct = productData.data;
      this.productName = this.selectedProduct.productName

      //this is needed for the validation productNameMustExist
      this.products.push(productData.data)
      this.$v.productName.$touch();

      //get the project data related to this formulation
      let projectData = await axios.get(productData.data._links.project.href);
      this.project = projectData.data

      let projectBlacklistResponse = await axios.get(this.project._links.ingredientBlacklist.href);
      this.projectBlacklist = projectBlacklistResponse.data._embedded.ingredient

      let projectWhitelistResponse = await axios.get(this.project._links.ingredientWhitelist.href);
      this.projectWhitelist = projectWhitelistResponse.data._embedded.ingredient

      this.regulationOfProject = await axios.get(this.project._links.regulationConformity.href);
      this.regulationOfProjectAux = this.regulationOfProject.data._embedded.regulation
      this.regulationSelected = this.regulationOfProject.data._embedded.regulation[0]._links.regulation.href
      let regulationSelectedValue = this.regulationOfProject.data._embedded.regulation[0].name
      this.regulationLabel = "INCI: " + regulationSelectedValue

      //get the client data related to this formulation
      let clientData = await axios.get(projectData.data._links.client.href);
      this.client = clientData.data

      // this.projectChoosen()
      this.isProductSet = true

      this.$root.$refs.FormulationGrid.getData();

      this.loadVersionOptions();

      this.loading = false;
    },

    processForm() {

      this.formula.process = JSON.stringify(this.processTable);

      let informations = {
        prefix: this.formula.prefix,
        version: this.formula.version,
        name: this.formula.name,
        observation: this.formula.observation,
        process: this.formula.process,
        status: this.formula.status,
        product: this.selectedProduct._links.self.href
      };

      return informations
    },

    async postData(endpoint, data) {
      try {
        //post formula: i.e. informations and process
        let formula = await axios.post(endpoint, data)
        let id = formula.data._links.self.href.split("/").pop();

        //post formulaMaterial
        for (let item of this.gridFormulationData) {
          // update the url of item
          let formulationFinal = {
            percentage: item.percentage,
            phase: item.phase,
            qsp: item.qsp,
            formula: formula.data._links.self.href,
            material: item.tradeName.url
          };
          await axios.post('formulaMaterial', formulationFinal)
        }

        this.succesAlert();
        this.isMod = false;
        this.$router.push('formulation/' + id);
        // this.getData();
        // get partial formula data to get the ID
        let response = await axios.get(this.endpoint + '/' + this.$route.params.id);
        this.formula = response.data;

      } catch (error) {
        console.log(error);
      }
    },

    async putData(endpoint, data) {

      if (this.isProjectHasProduct == false) {
        return
      }
      try {
        //put basic data
        await axios.put(endpoint, data);

        this.succesAlert()
      } catch (error) {
        this.errorAlert(error);
      }
    },

    async getProducts(productName) {
      if (productName.length > 1) {
        let filter = "/search/findByProductNameContainingIgnoreCaseOrderByProductName";
        let paramValue = {
          "productName": name,
        };
        let responseProducts = await axios.get("product" + filter, {
          params: paramValue
        })
        this.products = responseProducts.data._embedded.product;
      }
    },


    async setProduct(value) {
      //check if the user delete his input
      if (this.productName.length == 0 && value == null) {
        return
      }

      //if the user type the formulation ID
      if (value == null) {
        this.selectedProduct = this.products.filter(product => product.productName == this.productName)[0]
        if (this.selectedProduct === undefined) {
          return
        }
      }
      // if the user use the search function
      else {
        let responseProduct = await axios.get(value);
        this.selectedProduct = responseProduct.data
        this.products = []
        //this is needed for the validation
        this.products.push(responseProduct.data)

        this.productName = responseProduct.data.productName
      }

      //get the project information
      let responseProject = await axios.get(this.selectedProduct._links.project.href);
      this.project = responseProject.data

      //get client information
      let responseClient = await axios.get(this.project._links.client.href);
      this.client = responseClient.data

      this.isProductSet = true

      this.save()
    },

    showModal(type) {
      this.openedModal = type
    },

    async createNewVersion() {
      this.openedModal = null;

      try {
        await axios.post('formula/newversion', {
          idFormula: this.$route.params.id
        }).then((response) => {
          let endpoint = '/formulation/' + response.data._links.self.href.split('/').pop();
          this.$router.push(endpoint);

          this.products = [];
          this.versionOptions = [];

          this.getData();
        });
      } catch (error) {
        this.errorAlert(error);
      }
    },

    async deleteVersion() {
      this.openedModal = null;

      try {
        await axios.delete('formula/' + this.$route.params.id)
          .then(() => {
            let version_number = 0
            let endpoint = '/formulation/';

            if (this.versionOptions.length > 1) {
              version_number = this.versionOptions[0].id === this.$route.params.id ? this.versionOptions[1].id : this.versionOptions[0].id;
              endpoint += version_number;
            } else {
              endpoint = '/formulations/';
            }

            this.$router.push(endpoint);

            this.products = [];
            this.versionOptions = [];

            this.getData();
          });
      } catch (error) {
        this.errorAlert(error);
        this.$bvModal.msgBoxOk('Please check if there is any test(s) associated to this formula', {
          title: 'An error has occurred',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'info',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
      }
    },

    async loadOptionFields() {

      if (!this.$route.params.id) {
        await axios.get('formulaPrefix')
          .then((response) => {
            this.formulaPrefixs = response.data._embedded.formulaPrefix
          })
      }

      axios.get('formulaProcessType')
        .then((response) => {
          this.processTypeList = response.data._embedded.formulaProcessType
        })
    },

    loadVersionOptions() {
      axios.get('formula/search/findByPrefixIdOrderByVersionDesc?prefixId=' + this.formula.prefixId)
        .then((response) => {
          this.versionOptions = [];

          response.data._embedded.formula.forEach(element => {
            this.versionOptions.push({
              id: element._links.self.href.split('/').pop(),
              version: element.version
            });
          });
        })
    },

    changeVersion(id) {
      let endpoint = '/formulation/' + id;
      this.$router.push(endpoint);

      this.products = [];
      this.versionOptions = [];

      this.getData();
    }

  },

  created() {
    this.loadOptionFields();

    if (this.$route.params.id) {
      this.getData();
    } else {
      if (this.$route.query.parentData) {
        axios
          .get('product/' + this.$route.query.parentData)
          .then((response) => {
            this.selectedProduct = response.data
            this.products.push(response.data)
            this.productName = response.data.productName

            //get project information
            axios
              .get(this.selectedProduct._links.project.href)
              .then((response) => {
                this.project = response.data

                //get client information
                axios
                  .get(this.project._links.client.href)
                  .then((response) => {
                    this.client = response.data
                  })
              })
            this.isProductSet = true
          })
      }

      this.versionOptions.push({
        id: 1,
        version: 1
      });
      this.formula.version = 1;

      this.loading = false;
    }
  },

  validations: {
    formula: {
      name: {
        required
      },
      prefix: {
        required
      },
      status: {
        required
      }
    },
    productName: {
      required,
      productNameMustExist
    },
  }
}
</script>

<style>
.form-group {
  text-align: left;
  font-weight: bold;
}

</style>
