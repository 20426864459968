<template>
<transition name="modal">

  <div class="modal-mask">
        <div class="modal-wrapper modal-dialog-scrollable">
            <div class="modal-container modal-content" :style="{ width: width + 'px' }">

                <div class="modal-header">
                    <h3 class="modal-title">{{title}}</h3>
                    <span @click="$emit('close')"><font-awesome-icon class="fa-lg btn-icon" icon="times" /></span>
                </div>

                <div class="modal-body">
                    <slot></slot>
                </div>

                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>

            </div>
        </div>
    </div>

</transition>
</template>


<script>
export default {
    name: 'ModalWindow',
    data() {
        return {
            // selectValue: "",
        }
    },
    props: {
        title: {
            type: String
        },
        width: {
            type: Number,
            default: 800
        }
    },
    methods: {
        // selectedValue(id) {
        //   console.log(id)
        //   this.$emit("selectValue", id);
        //   this.$emit('close')
        // }
    }
};
</script>

<style lang="css" scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 90%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
