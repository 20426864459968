<template>
  <div v-can="'Read_Client'">

    <div class="container-fluid">
      <div class="row">

        <div class="col-md-12">
          <h1 class="text-left">{{$tc('general.client', 2)}}</h1>
          <MainGrid gridTitle="Clients" objectNodeName="entityModels" endpoint="client" :gridColumns="columns" :filter="filter" formUrl="/client" :queryByFilter="true" :customOrderBy="true" deletedataToDisplay="companyName" :deleteAssociatedData="$store.state.client.associatedData"></MainGrid>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import MainGrid from '../components/MainGrid'

export default {
  name: 'Clients',

  components: {
    MainGrid
  },
  data() {
    return {
      title: "Clients",
      columns: [
        {
          label: this.$t('client.companyName'),
          key: "companyName",
          sortable: true,
          initial_sort: true
        },
        {
          label: this.$t('contact.firstname'),
          key: "contact[0].firstName",
          sortable: true,
        },
        {
          label: this.$t('contact.lastName'),
          key: "contact[0].lastName",
          sortable: true,
        },
        {
          label: this.$t('client.countryName'),
          key: "countryName",
          sortable: true,
        },
        {
          key: "actionButtons",
          label: "",
        }
      ],
      filter: [{
        method: "findByClientCountryContact",
        params: ["searchValue"]
      }]
  }
},
  mounted() {

  }
};
</script>

<style>

</style>
