<template>
    <div>
        <span class="bold">Substitute: {{formulaMaterialName}} </span>({{ingredientsNameOfOldMaterial}})
        <b-table class="mt-2" :items="items" :fields="fields" striped responsive="sm" :per-page="perPage" sort-icon-left :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" @sort-changed="loadGridData()" show-empty>
            <template #cell(status)="row">
                <PillStatus :status="row.item.status"></PillStatus>
            </template>
            <template #cell(buttons)="row">
                <b-button @click="replaceMaterial(row.item)">{{$t('general.select')}}</b-button>
            </template>
            <template #empty="">
                {{$t('general.noResults')}}
            </template>
        </b-table>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="loadGridData()"></b-pagination>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import PillStatus from '../components/PillStatus.vue'
  export default {
    name: 'MaterialReplacement',
    components:{
        PillStatus
    },
    data() {
        return {
            currentPage: 1,
            rows: 1,
            items: null,
            sortBy: 'similarityPercentage',
            sortDesc: true,
            perPage: 5,
            materialId: 0,
            formula: null,
            formulaMaterialData: null,
            ingredientsNameOfOldMaterial: null,
            fields: [
                {
                    key: 'tradeName',
                    label: this.$t('material.tradeName'),
                    sortable: true
                },
                {
                    label: this.$t('conformity.ingredient'),
                    key: 'ingredientsName',
                    sortable: true
                },
                {
                    key: 'status',
                    label: this.$t('general.status'),
                    sortable: true
                },
                {
                    key: 'similarityPercentage',
                    label: this.$t('material.similarityPercentage'),
                    sortable: true,
                    formatter: "decimalPercentual"
                },
                {
                    key: 'buttons',
                    sortable: false,
                    label: ''
                }
            ],
        }
    },
    props: {
        formulaMaterialId: {
            type: String,
            required: true
        },
        formulaMaterialName:{
            type: String,
            required: true
        }
    },
    methods:{
        async loadData(){
            
            var data = await axios.get(this._props.formulaMaterialId)
            this.formulaMaterialData = data.data
            this.formula = await axios.get(this.formulaMaterialData._links.formula.href)

            var material = await axios.get(this.formulaMaterialData._links.material.href)
            var materialHref = material.data._links.self.href
            this.materialId = materialHref.split('/').pop()
            var ingredientsName = material.data.ingredientsName.sort()
            this.ingredientsNameOfOldMaterial = ingredientsName.join(', ')
            
            this.loadGridData()
        },
        async loadGridData(){
            const sortDirection = this.sortDesc == true ? "desc" : "asc";
            const springPage = this.currentPage - 1
            var endpoint = 'material/'+this.materialId+'/similar?page='+springPage+'&size='+this.perPage+'&orderBy='+this.sortBy+','+sortDirection
            var data = await axios.get(endpoint)
            this.items = data.data._embedded.entityModels
            this.rows = data.data.page.totalElements
        },
        decimalPercentual(value){
            return parseFloat(value).toFixed(2)+"%"
        },
        async replaceMaterial(newMaterial){
            let formulaMaterialFinal = {
                qsp: this.formulaMaterialData.qsp,
                phase: this.formulaMaterialData.phase,
                percentage: this.formulaMaterialData.percentage,
                material: newMaterial._links.self.href,
                formula: this.formula.data._links.formula.href
            };
            try {
                await axios.delete(this._props.formulaMaterialId)
                await axios.post('formulaMaterial',formulaMaterialFinal)

                this.$emit('refreshParent');
            } catch (error) {
                console.log(error)
            }
        }
    },
    created(){
        this.loadData()
    }
  }
  </script>
  
  <style scoped>
    .bold {
        font-weight: bold;
    }
  </style>
  