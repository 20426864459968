<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-left">{{$t('general.setup')}}: {{$tc('general.regulation', 2)}}</h1>

                <b-nav tabs>
                    <b-nav-item v-can="'Read_Regulation'" to="/regulations/regulations" exact exact-active-class="active">{{$t('regulation.regulationName')}}</b-nav-item>
                    <b-nav-item v-can="'Read_InciAuxiliary'" to="/regulations/nomenclatures" exact exact-active-class="active">{{$t('regulation.nomenclature')}}</b-nav-item>
                    <b-nav-item v-can="'Read_MaterialFamily'" to="/regulations/families" exact exact-active-class="active">{{$tc('regulation.family', 2)}}</b-nav-item>
                </b-nav>

                <router-view></router-view>

            </div>
        </div>
    </div>
</div>
</template>

<script>

import Feedback from '../mixins/Feedback.js'


export default {
    name: 'Regulations',

    mixins: [Feedback],

    components: {

    },
    data() {
        return {
            title: 'Regulations',


        }
    },
    methods: {

    }
}
</script>

<style>
.btn-icon {
    cursor: pointer;
    margin: 0px 5px;
}

</style>
