<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h2 class="text-left">{{$t('ingredient.nomenclature')}}</h2>

                <LayoutCard :title="$t('ingredient.nomenclature')" active="true">
                    <div class="loader" v-if="loading">

                    </div>

                    <fieldset :disabled="loading">
                        <form @change="save()">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputInciName">{{ $t('general.name') }}</label>
                                    <b-form-input v-can:input="'Update_InciAuxiliary'" type="text" class="form-control" id="inputInciName" :state="validateState($v.objectData.inciName)" :placeholder="$t('general.name')" v-model="objectData.inciName" @input="getInciName(objectData.inciName)" :trim="true" />
                                    <div class="invalid-feedback" v-if="!$v.objectData.inciName.isInciNameUnique">This nomenclature already exist</div>
                                </div>
                            </div>
                        </form>
                    </fieldset>

                </LayoutCard>

                <div v-if="isMod">
                    <SaveCancelDeleteButtons permission="Create_InciAuxiliary" @clicked="getClicked" @isMod="isMod" :isError="$v.$anyError"></SaveCancelDeleteButtons>
                </div>

                <div class="fixed-top mt-3 d-flex justify-content-center">
                    <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                        {{ feedbackMessage }}
                    </b-alert>
                </div>

            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import LayoutCard from '../components/LayoutCard.vue'
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

function isInciNameUnique(value) {
    if (this.inciNames.filter(e => e.inciName.toLowerCase() === value.toLowerCase()).length == 0) {
        return true
    }
    return false
}

window.axios = require("axios");

export default {
    name: 'Nomenclature',

    mixins: [Feedback, CommonVariablesAndFunctions],

    components: {
        LayoutCard,
        SaveCancelDeleteButtons,
    },

    data() {
        return {
            title: "Nomenclature",
            endpoint: "inciAuxiliary",
            value: "",

            objectData: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: null,
                inciName: "",
            },

            inciNames: []
        };
    },
    methods: {

        getData() {
            this.loading = true;

        },

        processForm() {

            let regulation = {
                inciName: this.objectData.inciName,
            };
            return regulation

        },

        postData(endpoint, data) {
            axios
                .post(endpoint, data)
                .then(() => {
                    this.isMod = false
                    this.succesAlert()
                    this.$router.push('/regulations/nomenclatures');
                })
                .catch((error) => {
                    this.errorAlert(error)
                })
        },

        async getInciName(input) {
            if (input.length > 1) {
                var filter = "/search/findByInciNameContainingIgnoreCase";
                var paramValue = {
                    "inciName": input,
                };
                let response = await axios.get("inciAuxiliary" + filter, {
                    params: paramValue
                })
                this.inciNames = response.data._embedded.inciAuxiliary;
            }

        },

    },

    created() {
        if (this.$route.params.id) {
            this.getData();
        } else {
            this.loading = false;
        }
    },

    validations: {
        objectData: {
            inciName: {
                isInciNameUnique
            }
        }

    }
};
</script>

<style scoped>
.form-group {
    text-align: left;
    font-weight: bold;
}
</style>
