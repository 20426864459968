<template>
<div>

    <div class="form-row">

        <div class="form-group col-md-4">
            <label>{{$t('formulation.id')}}</label>
            <span class="ml-2">
                <font-awesome-icon icon="search" class="btn-icon" @click="toggleModal()" /></span>
            <b-form-input type="text" autocomplete="off" class="form-control" v-model="formulaId" :trim="true" :lazy="true" @change="setFormulation('userInput')" />
        </div>

        <div class="form-group col-md-4">
            <label>{{$t('formulation.formulationName')}}</label>
            <b-form-input type="text" readonly v-model="formulaName" />
        </div>

        <div class="form-group col-md-2">
            <label>{{$t('general.from')}}</label>
            <b-form-select v-model="compareFrom" value-field="version" text-field="version" :options="versionOptions" @change="loadVersion"></b-form-select>
        </div>

        <div class="form-group col-md-2">
            <label>{{$t('general.to')}}</label>
            <b-form-select v-model="compareLimit" value-field="version" text-field="version" :options="versionLimit" @change="loadVersion"></b-form-select>
        </div>

    </div>

    <div class="form-row">

        <div class="form-group col-md-2">
            <label>{{$t('formulation.compareWith')}}</label>
            <b-form-select v-model="compareWith" value-field="version" text-field="version" :options="versionCompare" @change="compareVersions"></b-form-select>
        </div>

    </div>

    <div class="table-container">
        <b-table id="table" class="text-left" striped hover responsive :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="versionsCompareWith" :fields="fields" :busy="isBusy">

            <template #table-busy>
                <div class="loader"></div>
            </template>

            <template #thead-top="">
                <b-tr>
                    <b-th :stickyColumn="true">{{$tc('general.material', 2)}}</b-th>
                    <b-th :colspan="fields.length < 2 ? 1 : (fields.length - 1)">{{$tc('general.formulation',2)}}</b-th>
                </b-tr>
            </template>

            <template #head(tradeName)="">
                <div style="min-width:200px">
                    {{ $t('material.tradeName') }}
                </div>
            </template>

            <template v-slot:[`cell(${field})`]="data" v-for="field in fields">
                <div v-if="field != 'tradeName' && field != compareWith" :key="'if' + field">
                    <div v-if="data.value.value == null">
                        -
                    </div>
                    <div v-else>
                        {{data.value.value}}
                        <span v-if="data.value.diff != 0" :class="data.value.diff > 0 ? 'text-success' : 'text-danger'"><sup>(<span v-if="data.value.diff > 0">+</span>{{data.value.diff}})</sup></span>
                    </div>
                </div>
                <div v-else :key="'else-' + field">
                    {{data.value}}
                    <span v-if="data.value == ''">-</span>
                </div>
            </template>

        </b-table>
    </div>

    <b-modal ref="search-formulation" size="xl" scrollable :title="$t('general.search')">

        <template #modal-title="">
            <h3>{{$t('general.search')}}</h3>
        </template>

        <MainGrid gridTitle="Formulations" objectNodeName="entityModels" endpoint="formula" :gridColumns="columns" :filter="filter" formUrl="/formulation" :queryByFilter="true" :customOrderBy="true" @close="toggleModal()"
            @selectValue="setFormulation"></MainGrid>

        <template #modal-footer="{ hide }">
            <b-button variant="secondary" @click="hide('forget')">
                {{$t('general.close')}}
            </b-button>
        </template>

    </b-modal>

</div>
</template>

<script>
import axios from 'axios'
import MainGrid from '../../components/MainGrid.vue';
window.axios = require("axios");

export default {
    name: 'CompareFormulaVersion',

    components: {
        MainGrid
    },
    data() {
        return {

            formulaId: this._props.formulaPrefix,
            formulaName: "",

            compareFrom: "v1",
            compareLimit: "v6",
            compareWith: "",
            versionOptions: [],

            versionsMerged: [],
            versionsCompareWith: [],

            fields: [],

            isBusy: false,
            sortBy: 'tradeName',
            sortDesc: false,

            columns: [{
                    label: this.$t('formulation.formulationName'),
                    key: "name",
                    sortable: true,
                    initial_sort: true
                },
                {
                    label: this.$t('formulation.id'),
                    key: "prefixId",
                    sortable: true
                },
                {
                    label: this.$t('formulation.version'),
                    key: "version",
                    sortable: true
                },
                {
                    label: this.$t('general.status'),
                    key: "statusName",
                    sortable: true
                },
                {
                    label: this.$t('general.clientName'),
                    key: "companyName",
                    sortable: true
                },
                {
                    label: this.$t('project.projectName'),
                    key: "projectName",
                    sortable: true
                },
                {
                    label: this.$t('product.productName'),
                    key: "productName",
                    sortable: true
                },
                {
                    key: "returnButton",
                }
            ],
            filter: [{
                method: "findByClientProjectProduct",
                params: ["searchValue"]
            }]
        }
    },
    props: {
        formulaPrefix: {
            type: String,
        }

    },
    computed: {
        versionLimit: function() {
            let index = this.versionOptions.findIndex(x => x.version === this.compareFrom);

            return this.versionOptions.slice(index)
        },

        versionCompare: function() {
            let indexStart = this.versionOptions.findIndex(x => x.version === this.compareFrom);
            let indexEnd = this.versionOptions.findIndex(x => x.version === this.compareLimit);

            return this.versionOptions.slice(indexStart, indexEnd + 1)
        },
    },

    methods: {
        async loadVersion() {

            this.isBusy = true

            this.fields = [{
                key: 'tradeName',
                sortable: true,
                stickyColumn: true,
            }]

            try {
                let formulaAllVersion = await axios.get('formula/search/findByPrefixIdOrderByVersionAsc?prefixId=' + this.formulaId)

                this.formulaName = formulaAllVersion.data._embedded.formula[0].name

                if (formulaAllVersion.data._embedded.formula.length == 0) {
                    this.$bvModal.msgBoxOk(this.$t('formulation.noFormulationWithID'), {
                        title: this.$t('general.noMatch'),
                    })
                }

                let versions = []

                for (let i = (this.compareFrom.split('v').pop() - 1); i < this.compareLimit.split('v').pop(); i++) {
                    let formulaMaterial = await axios.get(formulaAllVersion.data._embedded.formula[i]._links.formulaMaterials.href)
                    this.fields.push('v' + formulaAllVersion.data._embedded.formula[i].version)

                    for (let j = 0; j < formulaMaterial.data._embedded.formulaMaterial.length; j++) {
                        let materialResponse = await axios.get(formulaMaterial.data._embedded.formulaMaterial[j]._links.material.href)

                        versions.push({
                            ['v' + formulaAllVersion.data._embedded.formula[i].version]: formulaMaterial.data._embedded.formulaMaterial[j].percentage,
                            tradeName: materialResponse.data.tradeName
                        })
                    }
                }

                let allMaterial = []

                for (let k = 0; k < versions.length; k++) {
                    allMaterial.push(versions[k].tradeName)

                }

                let materialUniq = [...new Set(allMaterial)]

                let output = []

                for (let l = 0; l < materialUniq.length; l++) {
                    let result = versions.filter(tradeName => tradeName.tradeName == materialUniq[l])
                    let merged = Object.assign(...result)
                    output.push(merged)
                }

                this.versionsMerged = output
                this.compareWith = this.compareFrom

                this.compareVersions()

                this.isBusy = false

            } catch (error) {
                return []
            }

        },

        compareVersions() {

            this.versionsCompareWith = JSON.parse(JSON.stringify(this.versionsMerged))

            for (let m = 0; m < this.versionsCompareWith.length; m++) {
                for (let n = 0; n < this.fields.length; n++) {
                    if (this.fields[n] == 'tradeName' || this.fields[n] == this.compareWith || this.versionsCompareWith[m][this.fields[n]] == undefined) {
                        continue
                    } else {
                        let difference = Math.round((this.versionsCompareWith[m][this.fields[n]] - this.versionsCompareWith[m][this.compareWith] + Number.EPSILON) * 100) / 100
                        if (!isNaN(difference)) {
                            this.versionsCompareWith[m][this.fields[n]] = {
                                value: this.versionsCompareWith[m][this.fields[n]],
                                diff: difference
                            }
                        } else {
                            this.versionsCompareWith[m][this.fields[n]] = {
                                value: Math.round((this.versionsCompareWith[m][this.fields[n]]) * 100) / 100,
                                diff: this.versionsCompareWith[m][this.fields[n]]
                            }
                        }
                    }
                }
            }

        },

        loadVersionOptions() {
            axios.get('formula/search/findByPrefixIdOrderByVersionAsc?prefixId=' + this.formulaId)
                .then((response) => {

                    this.versionOptions = [];

                    response.data._embedded.formula.forEach(element => {
                        this.versionOptions.push({
                            id: element._links.self.href.split('/').pop(),
                            version: 'v' + element.version
                        });
                    });

                })
                .then(() => {
                    this.compareFrom = this.versionOptions[0].version
                    this.compareLimit = this.versionOptions[this.versionOptions.length - 1].version
                    this.loadVersion()
                })


        },

        toggleModal() {
            this.$refs['search-formulation'].toggle()
        },

        async setFormulation(value) {

            //check if the user delete his input
            if (this.formulaId == '') {
                return
            }

            //if the user type the formulation ID
            if (value == 'userInput') {
                this.loadVersionOptions()
            }

            // if the user use the search function
            else {
                let responseFormulation = await axios.get(value);
                this.formulaId = responseFormulation.data.prefixId;
                this.loadVersionOptions()
            }
        },

    },

    mounted() {
        this.loadVersionOptions()
    }
};
</script>

<style lang="css" scoped>

table {
  width: 100%;
}

.table-container {
  overflow-x: auto;
}

</style>
