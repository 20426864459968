<template>
    <div>
    <div>
        <b-dropdown variant="outline-primary" size="sm" :text="$t('general.export')" right class="m-2">
            <b-dropdown-form>
                <b-dropdown-group :header="$tc('general.regulation', 1)">
                    <b-form-select text-field="name" @change="onRegulationSelected" value-field="_links.self.href" size="sm" v-model="regulationSelected" :options="regulations" class="mb-3">
                        <template #first>
                            <b-form-select-option :value="0">Select a regulation</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-dropdown-group>
                <span v-if="!loading">
                    <b-form-checkbox v-model="impressionFormula" class="mb-2" size="sm" style="width:200px">Formula <span class="bold">(pdf)</span></b-form-checkbox>
                    <b-form-checkbox v-model="reportEclate" :disabled="regulationSelected==0" class="mb-2" size="sm" style="width:200px">INCI Eclate <span class="bold">(csv)</span></b-form-checkbox>
                    <b-form-checkbox v-model="reportInciQualiQuanti" :disabled="regulationSelected==0" class="mb-2" size="sm">INCI Quali/Quanti <span class="bold">(csv)</span></b-form-checkbox>
                    <b-form-checkbox v-model="reportFormulaStatus" class="mb-2" size="sm">Formula Status <span class="bold">(csv)</span></b-form-checkbox>
                </span>
                <b-button v-show="(reportEclate || reportInciQualiQuanti || reportFormulaStatus || impressionFormula) && regulationSelected!=0" class="float-right" variant="outline-primary" size="sm" @click="onExport">Export</b-button>
            </b-dropdown-form>
        </b-dropdown>
    </div>
        <ModalWindow v-show="openFormulationAlertModal === true" :title="$t('formulation.alert.modalIngredientsTitle')" @close="openFormulationAlertModal = false" :width="550">
            <div>
                <p>{{$t('formulation.alert.incompleteFormulationMessage')}}</p>
                <p v-if="!this.alertMessage.formula100Percent"><b>{{$t('formulation.alert.noComposition')}}</b></p>
                <p v-if="!this.alertMessage.hasIngredients"><b>{{$t('formulation.alert.noRegisteredIngredients')}}</b></p>
                <p v-if="this.alertMessage.hasIngredients && !this.alertMessage.ingredientsHasInci"><b>{{$t('formulation.alert.ingredientsWithoutInci')}}: </b>
                    <ul class="row ml-2">
                        <li class="list-item col-lg-12 py-1" v-for="item in this.alertMessage.ingredientsWithoutInci" :key="item">
                            {{ item }}
                        </li>
                    </ul>
                </p>
            </div>

            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" @click="openFormulationAlertModal = false">{{$t('general.close')}}</button>
            </template>
        </ModalWindow>
    </div>
</template>
    
<script>
import axios from 'axios'
import ModalWindow from '../ModalWindow.vue';

window.axios = require("axios");

export default {
    name: 'DropdownExport',
    mixins: [],
    props: {
        projectRegulationConformity:{
            required: true
        },
        gridRegulationId:{
            required: true
        }
    },
    watch: { 
        gridRegulationId: function(newVal) {
            this.regulationSelected = newVal
        }
    },
    data() {
        return {
            loading: true,
            reportEclate: false,
            reportInciQualiQuanti: false,
            reportFormulaStatus: false,
            impressionFormula: false,
            regulations: [],
            regulationSelected: this._props.gridRegulationId,
            openFormulationAlertModal: false,
            alertMessage: {
                hasIngredients: true,
                ingredientsHasInci: true,
                formula100Percent: true,
                ingredientsWithoutInci: ''
            }
        }

    },
    mounted: function() {
        axios.get(this._props.projectRegulationConformity)
            .then((response) => {
                this.regulations = response.data._embedded.regulation;
                if(this.regulations.length == 0){
                    this.regulations = [{ value: 'null', name: this.$t('general.noRegulationSet'), disabled: true }]
                }
                this.loading = false;
            })

    },
    methods: {
        onRegulationSelected() {
            if(this.regulationSelected == 0) {
                this.reportEclate = false
                this.reportInciQualiQuanti = false
            }
        },
        async onExport() {
            var regulationId
            var ingredientsInformation = null
            var canGenerateCsv = true

            if(this.regulationSelected!=0){
                var idArray = this.regulationSelected.split('/')
                regulationId = idArray[idArray.length-1]

                if (this.reportEclate || this.reportInciQualiQuanti || this.reportFormulaStatus) {
                    let response = await axios.get('/inci/checkIfMaterialHasIngredientsOrInci?formulaId=' + this.$route.params.id + '&regulationId=' + regulationId)
                    ingredientsInformation = response.data

                    if (!ingredientsInformation['hasIngredients']) {
                        this.alertMessage.hasIngredients = false
                        this.openFormulationAlertModal = true
                        canGenerateCsv = false
                    } else if (!ingredientsInformation['formula100Percent']) {
                        this.alertMessage.formula100Percent = false
                        this.openFormulationAlertModal = true
                        canGenerateCsv = false
                    } else if (!ingredientsInformation['ingredientsHasInci']) {
                        this.alertMessage.ingredientsHasInci = false
                        this.alertMessage.ingredientsWithoutInci = ingredientsInformation['ingredientsWithoutInci']
                        this.openFormulationAlertModal = true
                    }
                }
            }

            if (this.$route.params.id) {
                if (this.reportEclate) {
                    if (canGenerateCsv) {
                        let url = '/inci/csvEclateReport?formulaId=' + this.$route.params.id + '&regulationId=' + regulationId;
                        this.$emit('exportCvs', url)
                    }
                    this.reportEclate = false;
                }
                if (this.reportInciQualiQuanti) {
                    if (canGenerateCsv) {
                        let url = '/inci/csvQualiQuantiReport?formulaId=' + this.$route.params.id + '&regulationId=' + regulationId;
                        this.$emit('exportCvs', url)
                    }
                    this.reportInciQualiQuanti = false;
                }
                if (this.reportFormulaStatus) {
                    if (canGenerateCsv) {
                        let url = '/formula/' + this.$route.params.id + '/checkConformityGetMaterialReturnCsvFile/' + regulationId;
                        this.$emit('exportCvs', url)
                    }
                    this.reportFormulaStatus = false;
                }
                if (this.impressionFormula) {
                    if (canGenerateCsv) {
                        this.$emit('exportPdf')
                    }
                    this.impressionFormula = false;
                }
            }
        },
    },
    components: {
        ModalWindow
    }
};
</script>

<style scoped>
.exportWindow{
    width: 200px;
}

.exportWindow a{
    text-decoration: none;
}

.bold {
    font-weight: bold;
}
</style>
