<template>
<div v-can="'Read_Ingredient'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-left">{{$tc('general.ingredient', 2)}}</h1>
        <MainGrid gridTitle="Ingredients" objectNodeName="entityModels" endpoint="ingredient" :gridColumns="columns" :filter="filter" formUrl="/ingredient" :queryByFilter="true" :customOrderBy="true" deletedataToDisplay="name" :deleteAssociatedData="$store.state.ingredient.associatedData" :deleteEndpointToDelete="$store.state.ingredient.endpointToDelete"></MainGrid>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MainGrid from '../components/MainGrid'

export default {
  name: 'IngredientGrid',
  components: {
    MainGrid
  },
  data() {
    return {
      title: 'Ingredients',
      columns: [
        {
          label: this.$t('ingredient.ingredientName'),
          key: "name",
          sortable: true,
        },
        {
          label: this.$t('ingredient.casNumber'),
          key: "casNumber",
          sortable: true,
        },
        {
          label: this.$t('ingredient.inciName'),
          key: "inciName",
          sortable: true,
        },
        {
          key: "actionButtons",
          label: "",
        }
      ],
      filter: [{
        method: "findByNameOrCasNumberOrInciName",
        params: ["searchValue"]
      }]
    }
  }
}
</script>

<style>
.btn-icon{
  cursor: pointer;
  margin: 0px 5px;
}

</style>
