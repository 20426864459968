<template>
<div>

  <!-- <b-dropdown variant="outline-primary" size="sm" text="Search" right class="m-2">
    <b-dropdown-form> -->

  <div class="form-group row">
    <div class="col-sm-8">
      <b-form-input class="form-control form-control-sm" :placeholder="$t('general.search')" v-model="searchInput" autocomplete="off" @input="search(searchInput)">
      </b-form-input>
    </div>
    <div class="col-sm-4">
      <b-form-select class="form-control form-control-sm" v-model="searchType" :options="searchOptions" @change="search(searchInput)"></b-form-select>
    </div>
  </div>
  <div v-if="searchInput.length > 1" class="suggestionsBox">
    <div class="form-group row">
      <div class="col-sm-12">
        <div v-if="searching == true">searching...</div>
        <div v-else-if="notFound == true">{{$t('general.noResults')}}</div>
        <div v-else>
          <div v-if="searchType == 'ingredients' && notFound == false">
            <ul>
              <li v-for="ingredient in ingredients" :key="ingredient.name">
                <a :href="'/ingredient/' + ingredient._links.self.href.split('/').pop()">{{ingredient.name}}</a> {{$t('formulation.isUsed')}} :
                <ul>
                  <li v-for="material in ingredient.materials" :key="material.tradeName">
                    <a :href="'/material/' + material._links.self.href.split('/').pop()">{{material.tradeName}}</a>
                    <font-awesome-icon v-can="'Update_Formula'" icon="plus-circle" class="clickable ml-2" @click="addMaterial(material)" />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div v-if="searchType == 'materials' && notFound == false">
            <ul>
              <li v-for="material in materials" :key="material.tradeName">
                <a :href="'/material/' + material._links.self.href.split('/').pop()">{{material.tradeName}}</a>
                <font-awesome-icon v-can="'Update_Formula'" icon="plus-circle" class="clickable ml-2" @click="addMaterial(material)" />
              </li>
            </ul>
          </div>

        </div>

      </div>
    </div>
  </div>

  <!--
    </b-dropdown-form>
  </b-dropdown> -->


</div>
</template>

<script>
import axios from 'axios'
window.axios = require("axios");

export default {
  name: 'SearchIngredientMaterial',
  mixins: [],
  props: {

  },
  data() {
    return {
      searchInput: "",
      searching: false,
      notFound: false,

      ingredients: [],
      materials: [],

      searchType: 'ingredients',
      searchOptions: [{
          value: 'ingredients',
          text: this.$tc('general.ingredient', 2)
        },
        {
          value: 'materials',
          text: this.$tc('general.material', 2)
        },
      ]
    }

  },
  computed: {

  },

  mounted: function() {


  },
  methods: {
    search(item) {
      if (item.length > 1) {
        this.searching = true
        if (this.searchType == 'ingredients') {
          this.getIngredientsMaterials(item)
        } else {
          this.getMaterials(item)
        }
      }

    },
    async getIngredientsMaterials(item) {

      let finded = []

      if (item.length > 1) {
        var filter = "/search/findByNameContainingIgnoreCaseOrderByName";

        var paramValue = {
          "name": item,
          "size": 20
        };

        let responseIngredients = await axios.get("ingredient" + filter, {
          params: paramValue
        })

        finded = responseIngredients.data._embedded.ingredient;

        if (finded.length > 0) {
          this.notFound = false

          for (let i = 0; i < finded.length; i++) {
            let item = finded[i]._links.self.href.split("/").pop()

            var filter2 = "/search/findByIngredientId";

            var paramValue2 = {
              "ingredientId": item,
              "searchValue": '',
            };

            let response = await axios.get("material" + filter2, {
              params: paramValue2
            })
            finded[i].materials = response.data._embedded.entityModels
          }
          this.ingredients = finded
        } else {
          this.notFound = true
        }
        this.searching = false
      }

    },

    async getMaterials(input) {
      if (input.length > 1) {
        this.notFound = false
        var filter = "/search/findByTradeNameContainingIgnoreCaseOrderByTradeName";
        var paramValue = {
          "tradeName": input,
        };
        let response = await axios.get("material" + filter, {
          params: paramValue
        })
        this.materials = response.data._embedded.material;
        if (this.materials.length == 0) {
          this.notFound = true
        }
      }
      this.searching = false
    },

    async addMaterial(item) {
      var filter = "/search/findByTradeNameContainingIgnoreCaseOrderByTradeName";
      var paramValue = {
        "tradeName": item.tradeName,
      };
      let response = await axios.get("material" + filter, {
        params: paramValue
      })
      let materialLink = response.data._embedded.material[0]._links.self.href;
      this.$root.$emit('add_material', materialLink)
      this.searchInput = ""
    }

  },
};
</script>

<style scoped>
.suggestionsBox {
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 400px;
  max-height: 200px;
  padding-top: 10px;
  z-index: 99999;
  background: white;
  border: solid #ced4da 1px;
  border-radius: 5px;
}

#specific_locations_add {
  position: relative;
}
</style>
