<template>
<div :class="bgColor" v-b-tooltip.hover="messageTooltip">

    <b-icon icon="circle-fill" style="width: 0.40em" :variant="variant" ></b-icon>

</div>
</template>

<script>
export default {
    name: 'StatusMaterialINCIRegulation',
    data() {
        return {

        }
    },
    props: {
        status: {
            required: true,
            default: "PROHIBITED"
        },

    },
    computed: {
        variant: function() {

            if (this._props.status == "TENTATIVE") {
                return "info"
            } else if (this._props.status == "REVIEWED") {
                return "primary"
            } else if (this._props.status == "VALIDATED") {
                return "success"
            } else if (this._props.status == "ALLOWED") {
                return "success"
            } else if (this._props.status == "RESTRICTED") {
                return "warning"
            } else if (this._props.status == "PROHIBITED") {
                return "danger"
            } else if (this._props.status == "ABANDONED") {
                return "dark"
            } else if (this._props.status == "FAILED") {
                return "dark"
            } else {
                return "warning"
            }

        },
        messageTooltip: function() {
            if (this._props.status == "TENTATIVE") {
                return "Tentative"
            } else if (this._props.status == "REVIEWED") {
                return "Reviewed"
            } else if (this._props.status == "VALIDATED") {
                return "Validated"
            } else if (this._props.status == "ALLOWED") {
                return "Allowed"
            } else if (this._props.status == "RESTRICTED") {
                return "Restricted"
            } else if (this._props.status == "PROHIBITED") {
                return "Prohibited"
            } else if (this._props.status == "ABANDONED") {
                return "Abandoned"
            } else if (this._props.status == "FAILED") {
                return "Failed"
            } else {
                return "Unknown"
            }
        },

        bgColor: function() {

            if (this._props.status == "TENTATIVE") {
                return "status-background-color-info"
            } else if (this._props.status == "REVIEWED") {
                return "status-background-color-primary"
            } else if (this._props.status == "VALIDATED") {
                return "status-background-color-success"
            } else if (this._props.status == "ALLOWED") {
                return "status-background-color-success"
            } else if (this._props.status == "RESTRICTED") {
                return "status-background-color-warning"
            } else if (this._props.status == "PROHIBITED") {
                return "status-background-color-danger"
            } else if (this._props.status == "ABANDONED") {
                return "status-background-color-dark"
            } else if (this._props.status == "FAILED") {
                return "status-background-color-dark"
            } else {
                return "status-background-color-warning"
            }
        },

    },
    methods: {


    },
    created() {}
}
</script>

<style scoped>

.status-background-color-primary {
    background-color: var(--primary) !important;
}
.status-background-color-secondary {
    background-color: var(--secondary) !important;
}
.status-background-color-success {
    background-color: var(--success) !important;
}
.status-background-color-danger {
    background-color: var(--danger) !important;
}
.status-background-color-info {
    background-color: var(--info) !important;
}
.status-background-color-warning {
    background-color: var(--warning) !important;
}
.status-background-color-dark {
    background-color: var(--dark) !important;
}
</style>
