<template>
<div>
  <table width="100%" border="1" cellspacing="5px" cellpadding="5px">
    <thead>
      <tr>
        <th colspan="8">INFORMATIONS</th>
      </tr>
    </thead>
    <tbody class="left">
      <tr>
        <td><strong>ID:</strong> {{formula.prefixId}}</td>
        <td><strong>Version:</strong> {{formula.version}}</td>
        <td><strong>Formulation's name:</strong> {{formula.name}}</td>
        <td><strong>Status:</strong> {{formula.status}}</td>
      </tr>
      <tr>
        <td><strong>Project's name:</strong> {{project.projectName}}</td>
        <td><strong>Product's ID:</strong> </td>
        <td colspan="2"><strong>Product's name:</strong> {{product.productName}}</td>
      </tr>
    </tbody>
  </table>

  <p>&nbsp;</p>

  <table width="100%" border="1" cellspacing="2px" cellpadding="2px">
    <thead>
      <tr>
        <th colspan=8>FORMULATION</th>
      </tr>
      <tr>
        <td><strong>Phase</strong></td>
        <td><strong>Material ID</strong></td>
        <td><strong>Trade Name</strong></td>
        <td><strong>Inci</strong></td>
        <td><strong>Percentage</strong></td>
        <td><strong>Quantity to Weight</strong></td>
        <td><strong>Weighted Quantity</strong></td>
        <td><strong>Lot Number</strong></td>
      </tr>
    </thead>
    <tfoot>
      <tr>
        <td colspan="8" class="left"><strong>Process:</strong> {{formula.process}}</td>
      </tr>
    </tfoot>
    <tbody>
      <tr v-for="(item, i) in gridFormulationData" :key="i">
        <td>{{item.phase}}</td>
        <td>{{item.prefixId}}</td>
        <td>{{item.tradeName.name}}</td>
        <td>{{item.inci[0]}}</td>
        <td>{{item.percentage}}</td>
        <td>{{(toWeight * item.percentage / 100).toFixed(3)}}</td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import axios from 'axios'
window.axios = require("axios");

export default {
  name: 'Formulation',
  components: {},
  data() {
    return {
      endpoint: "formula",
      toWeight: this.$route.query.toWeight,
      project: {
        projectName: ""
      },
      gridFormulationData: [],
      product: {},
      formula: {
        addBy: "",
        addDate: "",
        modBy: "",
        modDate: null,
        version: null,
        name: "",
        process: "",
        status: "",
        prefix: "",
        prefixID: "",
        prefixIndex: "",
        ingredientItemOfFormulation: [{
          addBy: "",
          addDate: "",
          modBy: "",
          modDate: null,
          percentage: 0.0
        }],
      }
    }
  },

  methods: {

    async getData() {

      this.loading = true;

      //get formula data
      let response = await axios.get(this.endpoint + '/' + this.$route.params.id);
      this.formula = response.data;

      //get the product data related to this formulation
      let productData = await axios.get(response.data._links.product.href);
      this.product = productData.data;

      //get the project data related to this formulation
      let projectData = await axios.get(productData.data._links.project.href);
      this.project.projectName = projectData.data.projectName

      // get the material related to this formulation
      let formulaMaterials = await axios.get(response.data._links.formulaMaterials.href);

      // for each material
      for (let i = 0; i < formulaMaterials.data._embedded.formulaMaterial.length; i++) {

        // set the data
        let formulaMaterialLink = formulaMaterials.data._embedded.formulaMaterial[i]._links.formulaMaterial.href
        let percentage = formulaMaterials.data._embedded.formulaMaterial[i].percentage;
        let phase = formulaMaterials.data._embedded.formulaMaterial[i].phase;
        let qsp = formulaMaterials.data._embedded.formulaMaterial[i].qsp

        // get id and name of this material and set data
        let materialData = await axios.get(formulaMaterials.data._embedded.formulaMaterial[i]._links.material.href)
        let materialLink = materialData.data._links.material.href
        let prefixId = materialData.data.prefixId
        let tradeName = materialData.data.tradeName
        let status = materialData.data.status

        //get the inci
        let materialIngredients = await axios.get(materialData.data._links.materialIngredients.href)
        let inciMaterial = []

        //test if material have ingredient
        if (materialIngredients.data._embedded.materialIngredient.length == 0) {
          let inci = "no ingredient"
          inciMaterial.push(inci)
        } else {
          for (let j = 0; j < materialIngredients.data._embedded.materialIngredient.length; j++) {
            let ingredient = await axios.get(materialIngredients.data._embedded.materialIngredient[j]._links.ingredient.href)
            let incis = await axios.get(ingredient.data._links.incis.href)

            //test if ingredient have INCI
            if (incis.data._embedded.inci.length > 0) {
              let inci = incis.data._embedded.inci[0].inciName
              inciMaterial.push(inci)
            } else {
              let inci = "no inci found"
              inciMaterial.push(inci)
            }
          }
        }

        //get the price
        let materialDistributors = await axios.get(materialData.data._links.materialDistributors.href)
        //some material have no price in the data seed.
        let price = 0
        if (materialDistributors.data._embedded.materialDistributor.length > 0) {
          price = materialDistributors.data._embedded.materialDistributor[0].price
        } else {
          price = 0
        }

        this.gridFormulationData.push({
          phase: phase,
          prefixId: prefixId,
          tradeName: {
            name: tradeName,
            url: materialLink
          },
          inci: inciMaterial,
          percentage: percentage,
          qsp: qsp,
          status: status,
          price: price,
          formulaMaterialLink: formulaMaterialLink,
        })
      }

      this.loading = false;
    },

    promisesGetData() {
      const promises = [this.getData()];

      Promise.all(promises)
        .then(() => {
          window.print();
          window.onafterprint = function() {
            let id = window.location.pathname.split("/").pop();
            window.location.href = '../formulation/'+ id ;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },


  },

  created() {
    if (this.$route.params.id) {
      this.promisesGetData();
    } else {
      this.loading = false;
    }
  },
}
</script>
<style type="text/css">
@media print {
  @page {
    size: auto ! important
  }
}

table {
  page-break-inside: auto
}

tr {
  page-break-inside: avoid;
  page-break-after: auto
}

thead {
  display: table-header-group
}

tfoot {
  display: table-footer-group
}

.left {
  text-align: left;
}
</style>
