import { render, staticRenderFns } from "./FormulationGrid.vue?vue&type=template&id=e87f5520&scoped=true&"
import script from "./FormulationGrid.vue?vue&type=script&lang=js&"
export * from "./FormulationGrid.vue?vue&type=script&lang=js&"
import style0 from "./FormulationGrid.vue?vue&type=style&index=0&id=e87f5520&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e87f5520",
  null
  
)

export default component.exports