<template>
    <b-navbar v-if="$store.getters.isAuthenticated && $store.getters.getLicence.valid != false && this.$route.name != 'Login' " toggleable="lg" class="navbar-custom">
        <b-navbar-brand>
            <router-link class="nav-link" to="/home" ref="home"><img src="../assets/galenit-logo.png" class="logo" /></router-link>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item>
                    <router-link class="nav-link" to="/home" ref="dashboard">{{$t('general.dashboard')}}</router-link>
                </b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav>
                <li v-can="'Read_Client' || 'Read_Distributor' || 'Read_Supplier' || 'Read_Contact'">
                    <b-nav-item-dropdown :text="$tc('general.contact', 2)">
                        <b-dropdown-item to="/clients">
                            {{$tc('general.client', 2)}}
                        </b-dropdown-item>
                        <b-dropdown-item to="/distributors">
                            {{$tc('general.distributor', 2)}}
                        </b-dropdown-item>
                        <b-dropdown-item to="/suppliers">
                            {{$tc('general.supplier', 2)}}
                        </b-dropdown-item>
                        <b-dropdown-item to="/contacts">
                            {{$t('general.seeAllContact')}}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </li>
                <li v-can="'Read_Project' || 'Read_Product' || 'Read_Formula' || 'Read_FormulaTest'" class="nav-item dropdown">
                    <b-nav-item-dropdown :text="$tc('general.project', 2)">
                        <b-dropdown-item to="/projects">
                            {{$tc('general.project', 2)}}
                        </b-dropdown-item>
                        <b-dropdown-item to="/products">
                            {{$tc('general.product', 2)}}
                        </b-dropdown-item>
                        <b-dropdown-item to="/formulations">
                            {{$tc('general.formulation', 2)}}
                        </b-dropdown-item>
                        <b-dropdown-item to="/tests">
                            {{$tc('general.test', 2)}}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </li>
                <li v-can="'Read_Material' || 'Read_Ingredient'" class="nav-item dropdown">
                    <b-nav-item-dropdown :text="$tc('general.ingredient', 2)">
                        <b-dropdown-item to="/materials">
                            {{$tc('general.material', 2)}}
                        </b-dropdown-item>
                        <b-dropdown-item to="/ingredients">
                            {{$tc('general.ingredient', 2)}}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </li>
                <li v-can="'Read_Regulation' || 'Read_InciAuxiliary' || 'Read_FormulaProcessType' || 'Read_FormulaTestType'" class="nav-item dropdown">
                    <b-nav-item-dropdown :text="$t('general.setup')">
                        <b-dropdown-item to="/regulations/regulations">
                            {{$tc('general.regulation', 2)}}
                        </b-dropdown-item>
                        <b-dropdown-item to="/setup-template/tests">
                            {{$tc('general.template', 2)}}
                        </b-dropdown-item>
                        <b-dropdown-item to="/setup-prefix/formula">
                            {{$tc('general.prefixes', 2)}}
                        </b-dropdown-item>

                        <div v-if="$store.getters.getUser.roleName == 'Administrator'">
                            <hr />
                            <b-dropdown-item to="/setup-user-role">
                                {{$t('user.userRoleMenu')}}
                            </b-dropdown-item>
                        </div>
                    </b-nav-item-dropdown>
                </li>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-text>
                    <span class="text-danger text-uppercase font-weight-bold pr-3">{{env}}</span>
                </b-nav-text>
                <b-nav-text>
                  <font-awesome-icon class="clickable mr-2" :class="classContrastMode" @click="swithToContrast" icon="adjust" />
                  <b-icon icon="moon" @click="swithToDark" class="clickable" :class="classDarkMode"></b-icon>
                </b-nav-text>
                <b-nav-item-dropdown right>
                    <template slot="button-content">
                        <span v-if="$i18n.locale == 'fr'" class="flag-icon flag-icon-fr"> </span>
                        <span v-else-if="$i18n.locale == 'es'" class="flag-icon flag-icon-es"> </span>
                        <span v-else-if="$i18n.locale == 'pt'" class="flag-icon flag-icon-br"> </span>
                        <span v-else-if="$i18n.locale == 'de'" class="flag-icon flag-icon-de"> </span>
                        <span v-else class="flag-icon flag-icon-us"> </span>
                    </template>
                    <b-dropdown-item>
                        <a class="dropdown-item" href="#" @click="langEn()" ref="langEn"><span class="flag-icon flag-icon-us"> </span> English</a>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <a class="dropdown-item" href="#" @click="langFr()" ref="langFr"><span class="flag-icon flag-icon-fr"> </span> Français</a>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <a class="dropdown-item" href="#" @click="langEs()" ref="langEs"><span class="flag-icon flag-icon-es"> </span> Español</a>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <a class="dropdown-item" href="#" @click="langPt()" ref="langPt"><span class="flag-icon flag-icon-br"> </span> Português</a>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <a class="dropdown-item" href="#" @click="langDe()" ref="langDe"><span class="flag-icon flag-icon-de"> </span> Deutsche</a>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown no-caret right>
                    <template slot="button-content">
                        <router-link class="dropdown-toggle" to="" ref="user">
                            <b-avatar v-if="$store.getters.getUser != null" variant="light" size="2em" :src="require(`@/assets/avatar/${$store.getters.getUser.avatar}`)" ></b-avatar>
                        </router-link>
                    </template>
                    <b-dropdown-item :to="`/user/${$store.getters.getUser._links.user.href.split('/').pop()}`">
                        <i>{{$store.getters.getUser.userName}}</i>
                    </b-dropdown-item>
                    <b-dropdown-item to="/users">
                        {{$tc('user.user', 2)}}
                    </b-dropdown-item>
                    <b-dropdown-item to="/about">
                        {{$t('general.about')}}
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="logout">
                        <a class="text-danger text-decoration-none">Log Out</a>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import axios from 'axios'

export default {
  name: 'NavBar',
  data() {
    return {
      env: this.setEnv(),
      mode: "app",
    };
  },
  components: {

  },
  computed: {
    classDarkMode: function() {
      return {
        'dark-mode': this.mode === 'dark'
      }
    },
    classContrastMode: function() {
      return {
        'dark-mode': this.mode === 'contrast'
      }
    }
  },
  mounted() {
    if (localStorage.mode) {
      this.mode = localStorage.mode;
    }
  },
  methods: {

    swithToContrast() {
      if (this.mode == "app" || this.mode == "dark") {
        localStorage.mode = "contrast"
      } else {
        localStorage.mode = "app"
      }

      this.$router.go()
    },

    swithToDark() {

      if (this.mode == "app" || this.mode == "contrast") {
        localStorage.mode = "dark"
      } else {
        localStorage.mode = "app"
      }

      this.$router.go()

    },

    setEnv() {
      if (process.env.NODE_ENV != 'production') {
        return "development environment";
      }
    },
    langEn() {
      this.$i18n.locale = 'en'
    },

    langFr() {
      this.$i18n.locale = 'fr'
    },

    langEs() {
      this.$i18n.locale = 'es'
    },

    langPt() {
      this.$i18n.locale = 'pt'
    },

    langDe() {
      this.$i18n.locale = 'de'
    },

    logout() {
      var self = this;

      axios.get('/logout').then(function() {
        axios.defaults.withCredentials = false;
        self.$store.dispatch('setAccessedUrl', "/");
        self.$store.dispatch('logOut')
        self.$store.commit('setCredentialIncorrect', false)
        self.$store.commit('setAllStatus', [])
        self.$router.push("/");

      })
    }

  }

};
</script>

<style scoped>
.navbar-custom {
  background-color: var(--navbar-background);
}

::v-deep .dropdown-menu {
  background-color: var(--navbar-background) !important;
  color: red;
}

::v-deep .dropdown-menu .dropdown-item {
  color: var(--navbar-text) !important;
  text-decoration: none;
  font-weight: bold;
}

::v-deep .dropdown-menu .dropdown-item:hover {
  color: var(--navbar-background) !important;
  text-decoration: none;
}

::v-deep .dropdown .nav-link {
  color: var(--navbar-text) !important;
  font-weight: 600;
}

.nav-item.nav-item.nav-item a {
  color: var(--navbar-text);
  font-weight: 600;
}

::v-deep .nav-link.dropdown-toggle:hover {
  color: var(--navbar-background) !important;
  background: var(--navbar-text) !important;
  font-weight: 600;
}

.logo {
  height: 35px;
}

.dark-mode {
  color: yellow
}
</style>
