<template>
<div>
    <multiselect ref='multiselect' v-can:multiselect="_props.permission" v-model="value" :options="options" @search-change="getIngredients" :hide-selected="true" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" :placeholder="chooseIngredientLabel"
        label="name" track-by="name" :preselect-first="false" :taggable="false" @input="onSelect">
        <template slot="noOptions">{{$t('general.searchIngredient')}}</template>
    </multiselect>
</div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

window.axios = require("axios");

export default {
    name: 'Ingredient',
    components: {
        Multiselect
    },

    props: {
        selectedIngredient: {
            type: Array,
            required: true
        },
        permission:{
            type: String,
        }
    },
    data() {
        return {
            ingredients: [],
            value: [],
            options: [],
            chooseIngredientLabel: this.$t('ingredient.chooseIngredient')
        }
    },
    methods: {
        onSelect(list) {

            var finalObject = [];
            for (var i = 0; i < list.length; i++) {
                finalObject.push(list[i]._links.self.href);
            }
            this.$emit('update:value', finalObject)
            this.$emit('newIngredients', finalObject)
        },

        loadIngredients() {
            for (let i = 0; i < this._props.selectedIngredient.length; i++) {
              let id = this._props.selectedIngredient[i].split("/").pop();
              axios
                .get('ingredient/' + id)
                .then((response) => {
                  this.value.push(response.data)
                })
                .catch((error) => {
                  console.log(error.response)
                })
            }
        },

        async getIngredients(name) {
            if (name.length > 1) {
                var filter = "/search/findByNameContainingIgnoreCaseOrderByName";
                var paramValue = {
                    "name": name,
                };
                let ingredients = await axios.get("ingredient" + filter, {
                    params: paramValue
                })
                this.options = ingredients.data._embedded.ingredient;

                return this.options
            }
        },
    },

    mounted() {

        this.loadIngredients();

    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
