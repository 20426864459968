<template>
<div>
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-12 text-justify">
                <h5>{{$t('general.welcome')}} {{logUser.firstName}}</h5>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <div v-can="'Read_Project'" class="col-md-6">
                <b-card header-class="card-header-projects" header-text-variant="white" align="center" class="card-projects bg-background">
                    <template #header>
                        <h5 class="mb-0">{{$t('dashboard.lastModifiedProjects')}}</h5>
                    </template>
                    <b-card-text>

                        <b-table hover small :items="fetchProjects" :fields="projectsFields">

                            <template #cell(modDate)="row">
                                {{row.value | moment("L")}}
                            </template>

                            <template #cell(targetDate)="row">
                                {{row.value | moment("L")}}
                            </template>

                            <template #cell(status)="row">
                                <PillStatus :status="row.value"></PillStatus>
                            </template>

                            <template #cell(goTo)="row">
                                <font-awesome-icon @click="navigateTo(row.item._links.self.href)" class="btn-icon" icon="arrow-circle-right" />
                            </template>

                        </b-table>

                    </b-card-text>
                </b-card>

            </div>


            <div v-can="'Read_Product'" class="col-md-6">
                <b-card header-class="card-header-products" header-text-variant="white" align="center" class="card-products bg-background">
                    <template #header>
                        <h5 class="mb-0">{{$t('dashboard.lastModifiedProducts')}}</h5>
                    </template>
                    <b-card-text>

                        <b-table hover small :items="fetchProducts" :fields="productsFields">

                            <template #cell(modDate)="row">
                                {{row.value | moment("L")}}
                            </template>

                            <template #cell(targetDate)="row">
                                {{row.value | moment("L")}}
                            </template>

                            <template #cell(status)="row">
                                <PillStatus :status="row.value"></PillStatus>
                            </template>

                            <template #cell(goTo)="row">
                                <font-awesome-icon @click="navigateTo(row.item._links.self.href)" class="btn-icon" icon="arrow-circle-right" />
                            </template>

                        </b-table>

                    </b-card-text>
                </b-card>

            </div>
        </div>

        <div class="row mb-4">
            <div v-can="'Read_Formula'" class="col-md-12">
                <b-card header-class="card-header-formulations" header-text-variant="white" align="center" class="card-formulations bg-background">
                    <template #header>
                        <h5 class="mb-0">{{$t('dashboard.lastModifiedFormulations')}}</h5>
                    </template>
                    <b-card-text>

                        <b-table hover small :items="fetchFormulations" :fields="formulationsFields">

                            <template #cell(modDate)="row">
                                {{row.value | moment("L")}}
                            </template>

                            <template #cell(targetDate)="row">
                                {{row.value | moment("L")}}
                            </template>

                            <template #cell(status)="row">
                                <PillStatus :status="row.value"></PillStatus>
                            </template>

                            <template #cell(goTo)="row">
                                <font-awesome-icon @click="navigateTo(row.item._links.self.href)" class="btn-icon" icon="arrow-circle-right" />
                            </template>

                        </b-table>

                    </b-card-text>
                </b-card>
            </div>
        </div>


        <div class="row mt-5 d-flex justify-content-center">
            <div class="d-flex justify-content-center">
                <div class="form-group col-md-4">
                    <h5 class="green">{{$t('dashboard.createIngredientTitle')}}</h5>
                    <ol>
                        <li>{{ $t('dashboard.createIngredientItem1') }}</li>
                        <li>{{ $t('dashboard.createIngredientItem2') }}</li>
                    </ol>
                </div>
                <div class="form-group col-md-4">
                    <h5 class="green">{{ $t('dashboard.createMaterialTitle') }}</h5>
                    <ol>
                        <li>{{ $t('dashboard.createMaterialItem1') }}</li>
                        <li>{{ $t('dashboard.createMaterialItem2') }}</li>
                        <li>{{ $t('dashboard.createMaterialItem3') }}</li>
                    </ol>
                </div>
                <div class="form-group col-md-4">
                    <h5 class="green">{{ $t('dashboard.createFormulationTitle') }}</h5>
                    <ol>
                        <li>{{ $t('dashboard.createFormulationItem1') }}</li>
                        <li>{{ $t('dashboard.createFormulationItem2') }}</li>
                        <li>{{ $t('dashboard.createFormulationItem3') }}</li>
                        <li>{{ $t('dashboard.createFormulationItem4') }}</li>
                        <li>{{ $t('dashboard.createFormulationItem5') }}</li>
                    </ol>
                </div>
            </div>
        </div>

        <b-modal id="new-version" ok-only centered :title="$t('login.newVersion')">
            <p class="my-2">{{$t('login.pleaseVisit')}} <a href="/about">{{ $t('general.about') }}</a> </p>
        </b-modal>

    </div>
</div>
</template>

<script>
import axios from 'axios'
import PillStatus from '../components/PillStatus.vue'
window.axios = require("axios");

import {
    mapGetters
} from 'vuex'

export default {

    name: 'Home',

    components: {
        PillStatus,
    },

    data() {
        return {
            title: "Hello Home",
            licenceTimeLeft: null,

            totalClient: "5",
            totalProject: "4",
            totalProduct: "3",
            totalFormulation: "2",
            totalMaterial: "1000",
            totalDistributor: "58",

            LastModifiedProject: [],

            projectsFields: [{
                    key: 'projectName',
                    label: this.$t('project.projectName'),
                    sortable: false
                },
                {
                    key: 'modDate',
                    label: this.$t('general.modificationData'),
                    sortable: false
                },
                {
                    key: 'targetDate',
                    label: this.$t('project.targetDate'),
                    sortable: false
                },
                {
                    key: 'status',
                    label: this.$t('general.status'),
                    sortable: false,
                },
                {
                    key: 'goTo',
                    label: this.$t('general.goTo'),
                    sortable: false,
                }
            ],

            productsFields: [{
                    key: 'productName',
                    label: this.$t('product.productName'),
                    sortable: false
                },
                {
                    key: 'modDate',
                    label: this.$t('general.modificationData'),
                    sortable: false
                },
                {
                    key: 'targetDate',
                    label: this.$t('project.targetDate'),
                    sortable: false
                },
                {
                    key: 'status',
                    label: this.$t('general.status'),
                    sortable: false,
                },
                {
                    key: 'goTo',
                    label: this.$t('general.goTo'),
                    sortable: false,
                }
            ],

            formulationsFields: [{
                    key: 'name',
                    label: this.$t('formulation.formulationName'),
                    sortable: false
                },
                {
                    key: 'modDate',
                    label: this.$t('general.modificationData'),
                    sortable: false
                },
                {
                    key: 'status',
                    label: this.$t('general.status'),
                    sortable: false,
                },
                {
                    key: 'goTo',
                    label: this.$t('general.goTo'),
                    sortable: false,
                }
            ]


        };
    },
    computed: {
        ...mapGetters({
            logUser: "getUser",
            licence: "getLicence",
            appDate: "appDate"

        }),

    },
 
    methods: {

        fetchProjects() {

            var filter = "/search/findByOrderByModDateDesc";
            var paramValue = {
                "page": 0,
                "size": 5,
            };
            const promise = axios.get("project" + filter, {
                params: paramValue
            })

            return promise.then(data => {
                const items = data.data._embedded.project
                this.totalProject = data.data.page.totalElements
                // Must return an array of items or an empty array if an error occurred
                return items || []
            })
        },

        fetchProducts() {
            var filter = "/search/findByOrderByModDateDesc";
            var paramValue = {
                "page": 0,
                "size": 5,
            };
            const promise = axios.get("product" + filter, {
                params: paramValue
            })

            return promise.then(data => {
                const items = data.data._embedded.product
                this.totalProduct = data.data.page.totalElements
                // Must return an array of items or an empty array if an error occurred
                return items || []
            })
        },

        fetchFormulations() {
            var filter = "/search/findByOrderByModDateDesc";
            var paramValue = {
                "page": 0,
                "size": 5,
            };
            const promise = axios.get("formula" + filter, {
                params: paramValue
            })

            return promise.then(data => {
                const items = data.data._embedded.formula
                this.totalFormulation = data.data.page.totalElements
                // Must return an array of items or an empty array if an error occurred
                return items || []
            })
        },

        navigateTo(url) {
            let id = url.split("/").pop()
            let file = url.split("/")[4]
            if (file == "formula") {
                file = "formulation"
            }
            this.$router.push(file + '/' + id)
        },

        showMsgBoxCertificate() {
            this.$bvModal.msgBoxOk(this.$t('login.licenceExpireSoon') + " " + Math.floor(this.licenceTimeLeft/(60*24)) + " " + this.$t('general.days'), {
            title: this.$t('login.licenceExpiration'),
            //   size: 'sm',
            okVariant: 'info',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
            })
        }

    },
    mounted() {
       if(this.$route.query.fromLogin == 'true'){
        this.licenceTimeLeft = Math.floor((new Date(this.licence.certificateEndDate) - Date.now()) / 60e3)
        if(this.licenceTimeLeft <= 43200){
            this.showMsgBoxCertificate()
        }

        if(new Date(this.appDate) > new Date(this.logUser.lastConnection)){
            this.$bvModal.show('new-version')
        }

        axios.get(this.logUser._links.role.href)
        .then((roleLink)=>{
            let userRole = {
                role: roleLink.data._links.self.href
            }
            axios.put(this.logUser._links.self.href + "/updateLastConnection", userRole)
        })

       }

    }


};
</script>

<style scoped>
.card-projects {
    border-color: #528061
}

.card-header-projects {
    background-color: var(--project-header-background);
    opacity: 0.8
}

.card-projects {
    border-color: var(--project-header-background);
}

.card-products {
    border-color: var(--product-header-background);
}

.card-header-products {
    background-color: var(--product-header-background);
    opacity: 0.8
}

.card-formulations {
    border-color: var(--formula-header-background);
}

.card-header-formulations {
    background-color: var(--formula-header-background);
    opacity: 0.8
}

.green {
    color: green;
}
</style>
