<template>
<div>

    <div class="form-row centered">

        <div class="form-group col-md-5">
            <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search" class="my-auto"></b-form-input>

                <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
            </b-input-group>
        </div>

        <div class="form-group col-md-4">
            <b-pagination v-model="currentPage" :total-rows="filteredRows == null ? totalRows : filteredRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
        </div>
        <div class="form-group col-md-2">
            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
        </div>

        <div class="form-group col-md-1 text-center">
            <button type="button" class="btn btn-outline-primary btn-sm" @click="showModal('contact')">{{$t('general.add')}}</button>
        </div>

    </div>


    <b-table ref="contactTable" responsive="sm" hover :items="contacts" :fields="contactColumn" :busy.sync="isBusy" :filter="filter" @filtered="onFiltered" :no-provider-filtering="true" :no-provider-paging="true" :no-provider-sorting="true"
        :sort-by="sortBy" :sort-desc="false" :current-page="currentPage" :per-page="perPage">

        <template #cell(delete)="row">
            <font-awesome-icon class="btn-icon" @click="contactModal(row.item)" icon="eye" />
            <font-awesome-icon v-can="'Delete_'+_props.endpoint.charAt(0).toUpperCase() + _props.endpoint.slice(1)+'Contacts'" @click="deleteContact(row.item)" class="btn-icon" icon="trash-alt" />
        </template>


    </b-table>

    <div v-if="!isContactSet">
        {{$t('general.noRecord')}}
    </div>


    <ModalWindow v-show="openedModal === 'contact'" :title="$t('general.search')" @close="openedModal = null">
        <MainGrid gridTitle="Contact" objectNodeName="contact" endpoint="contact" :gridColumns="columnsMainGrid" :filter="filterMainGrid" formUrl="/contact" @close="openedModal = null" @selectValue="setContact"></MainGrid>
    </ModalWindow>

    <b-modal ref="contact-more-info-modal" size="xl" scrollable :title="$tc('general.contact', 1)">
        <Contact :contactId="modalContactId"></Contact>
        <template #modal-footer="{ ok }">
            <b-button variant="primary" @click="ok()">OK</b-button>
        </template>
    </b-modal>

</div>
</template>


<script>
import ModalWindow from '../components/ModalWindow.vue';
import MainGrid from '../components/MainGrid.vue';
import Contact from '../views/Contact.vue';
import axios from 'axios'
//
import Feedback from '../mixins/Feedback.js'

window.axios = require("axios");

export default {
    name: 'ListingContact',

    components: {
        ModalWindow,
        Contact,
        MainGrid
    },

    mixins: [Feedback],

    data() {
        return {
            fields: this._props.column,

            sortBy: 'lastName',

            filter: null,

            isBusy: false,

            filteredRows: null,

            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],

            openedModal: null,
            modalContactId: null,

            contactColumn: [{
                    key: 'firstName',
                    label: this.$t('contact.firstname'),
                    sortable: true
                },
                {
                    key: 'lastName',
                    label: this.$t('contact.lastName'),
                    sortable: true
                },
                {
                    key: 'role',
                    label: this.$t('contact.function'),
                    sortable: true
                },
                {
                    key: 'email',
                    label: this.$t('contact.email'),
                    sortable: true
                },
                {
                    key: 'phoneNumber',
                    label: this.$t('contact.phoneNumber'),
                    sortable: true
                },
                {
                    key: 'delete',
                    label: "",
                    sortable: false
                },
            ],

            columnsMainGrid: [{
                    label: this.$t('contact.firstname'),
                    key: "firstName",
                    sortable: true,
                    initial_sort: true
                },
                {
                    label: this.$t('contact.lastName'),
                    key: "lastName",
                    sortable: true,
                },
                {
                    label: this.$t('contact.email'),
                    key: "email",
                    sortable: true,
                },
                {
                    key: "returnButton",
                }
            ],
            filterMainGrid: [{
                method: "findByFirstNameContainingIgnoreCaseOrLastNameContainingIgnoreCaseOrEmailContainingIgnoreCase",
                params: ["firstName", "lastName", "email"]
            }],
        }
    },
    props: {
        contacts: {
            type: Array,
            required: true
        },
        isContactSet: {
            type: Boolean,
            required: true
        },
        nameToDisplay: {
            type: String,
            required: true
        },
        totalRows: {
            type: Number,
            required: true,
        },
        endpoint: {
            type: String,
            required: true
        }

    },
    methods: {

        async setContact(value) {

            if (this.$route.params.id) {
                let responseContacts = await axios.get(this._props.endpoint + '/' + this.$route.params.id + '/contacts')

                //add if to detect is value is already in the contacts
                let contactsLink = value

                for (let i = 0; i < responseContacts.data._embedded.contact.length; i++) {
                    contactsLink = contactsLink + '\n' + responseContacts.data._embedded.contact[i]._links.self.href
                }

                try {
                    axios.put(this._props.endpoint + '/' + this.$route.params.id + '/contacts', contactsLink, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                    this.$emit('saved')

                    let responseContact = await axios.get(value)
                    this.localContacts.push(responseContact.data)


                } catch (error) {
                    console.log(error);
                }
            } else {
                let reponseContact = await axios.get(value)
                this.localContacts.push(reponseContact.data)
                this.$emit('contactSet')
            }

        },

        deleteContact(item) {

            if (this.contacts.length == 1) {
                this.$bvModal.msgBoxOk("Can't delete the only contact of " + this._props.nameToDisplay, {
                    title: 'Add a new contact first',
                })
                return
            }

            let index = this.contacts.indexOf(item);
            if (this.$route.params.id) {
                this.localContacts.splice(index, 1)

                let contactsLink

                for (let i = 0; i < this.contacts.length; i++) {
                    contactsLink += this.contacts[i]._links.self.href + '\n'
                }

                try {
                    axios.put(this._props.endpoint + '/' + this.$route.params.id + '/contacts', contactsLink, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                    this.$emit('saved')

                } catch (error) {
                    console.log(error);
                }
            } else {
                this.localContacts.splice(index, 1)
            }

        },

        showModal(type) {
            this.openedModal = type
        },

        contactModal(url) {
            this.modalContactId = url._links.self.href.split('/').pop()
            this.$refs['contact-more-info-modal'].show()
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.filteredRows = filteredItems.length
            this.currentPage = 1
        },

    },

    mounted() {

    },
    computed: {
        localContacts: {
            get() {
                return this.contacts
            },
            set(newValue) {
                this.$emit('update:contacts', newValue)
            },
        }
    }
};
</script>

<style lang="css" scoped>

.btn-icon {
  cursor: pointer;
  margin: 0px 5px;
}

</style>
