<!-- HOW TO USE :
this component is to delete a register it could be use with a search function or an endpoint

you need to pass the following props :
endpoint: a string equal to the endpoint of the register you want to delete
dataToDisplay: will be display in the modal to inform the user
relationship: it's an array of object which contain all the associated data you want to test. You should declare an array of object in the parent like :
    associatedData: [{
        endpoint: "project",                -> endpoint of the associated data SHLOUD be singular (not plurial!!)
        filter: "/search/findByProjectNameOrTargetDateOrStatusOrClient",  -> is you need to use a search function if not leave empty
        result: [] ->
    }]
endpointToDelete: it's an array of object which contain the endpoint and embedded name of the data you want to delete AUTOMATICALLY by deleting the register
    endpointToDelete: [
        {
                endpoint: "formulaMaterials",
                embeddedName: "formulaMaterial"
        },
    ],
searchValue: the value you want to search. -->


<template>
<span>

    <font-awesome-icon v-if="this.$route.params.id" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'Clients'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'Contacts'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'Distributors'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'Suppliers'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'Ingredients'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'Materials'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'Projects'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'Products'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'Formulations'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'Tests'" class="btn-icon" @click="deleteData()" icon="trash-alt" />

    <!-- children routes -->
    <font-awesome-icon v-if="this.$route.name == 'regulations'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'nomenclatures'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'families'" class="btn-icon" @click="deleteData()" icon="trash-alt" />

    <font-awesome-icon v-if="this.$route.name == 'tests'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'processes'" class="btn-icon" @click="deleteData()" icon="trash-alt" />

    <font-awesome-icon v-if="this.$route.name == 'formula'" class="btn-icon" @click="deleteData()" icon="trash-alt" />
    <font-awesome-icon v-if="this.$route.name == 'material'" class="btn-icon" @click="deleteData()" icon="trash-alt" />

    <b-modal ref="delete-error" :title="$t('delete.foundData')" ok-only>
        <div class="my-4"> {{this._props.dataToDisplay}} {{$t('delete.associatedData')}}</div>
        <div>{{$t('delete.editRegister')}}:</div>

        <div v-for="(data, index) in associatedData" :key="`data-${index}`">
            <div v-if="data.result.length > 0">
                <ul>
                    <li v-if="data.endpoint == 'formulaTest'">Test:</li>
                    <li v-else-if="data.endpoint == 'materialIngredient' || data.endpoint == 'materialDistributor'">Material:</li>
                    <li v-else-if="data.endpoint == 'formulaMaterial'">Formulation:</li>
                    <li v-else>{{data.endpoint.charAt(0).toUpperCase() + data.endpoint.slice(1)}} :</li>
                    <ul v-if="data.result.length > 0">
                        <li v-for="(item, index) in data.result" :key="`item-${index}`">

                            <span v-if="data.endpoint == 'client'">
                                {{item.companyName}}
                            </span>

                            <span v-if="data.endpoint == 'distributor'">
                                {{item.distributorName}}
                            </span>

                            <span v-if="data.endpoint == 'supplier'">
                                {{item.supplierName}}
                            </span>

                            <span v-if="data.endpoint == 'project'">
                                {{item.projectName}}
                            </span>

                            <span v-if="data.endpoint == 'product'">
                                {{item.productName}}
                            </span>

                            <span v-if="data.endpoint == 'formula'">
                                {{item.name}} - {{item.prefixId}}
                            </span>

                            <span v-if="data.endpoint == 'formulaTest'">
                                {{item.formulaPrefixIdAndVersion}}
                            </span>

                            <span v-if="data.endpoint == 'materialIngredient'">
                                {{item.tradeName}}
                            </span>

                            <span v-if="data.endpoint == 'formulaMaterial'">
                                {{item.name}} - {{item.prefixId}}
                            </span>

                            <span v-if="data.endpoint == 'materialDistributor'">
                                {{item.tradeName}}
                            </span>

                            <span v-if="data.endpoint == 'material'">
                                {{item.tradeName}}
                            </span>

                            <span>
                                <font-awesome-icon class="btn-icon" @click="navigateTo(item._links.self.href)" icon="edit" /></span>

                        </li>
                    </ul>
                </ul>
            </div>

        </div>
    </b-modal>

</span>
</template>

<script>
import axios from 'axios'
window.axios = require("axios");

export default {
    name: 'DeleteButton',

    components: {

    },

    props: {
        data: {
            type: String,
        },
        endpoint: {
            type: String,
            required: true
        },
        dataToDisplay: {
            type: String,
            required: true
        },
        relationship: {
            type: Array,
            default: function() {
                return []
            }
        },
        toDeleteWithRegister: {
            type: Array,
            default: function() {
                return []
            }
        },
        searchValue: {
            required: false,
        },
    },

    data() {
        return {
            associatedData: this._props.relationship
        }
    },

    methods: {
        async deleteData() {

            let id

            if (this.$route.params.id) {
                id = this.$route.params.id;
            } else {
                id = this.data.split("/").pop();
            }

            this.$bvModal.msgBoxConfirm(this.$t('delete.confirmBox') + " " + this._props.dataToDisplay, {
                    title: this.$t('delete.pleaseConfirm'),
                    okVariant: 'danger',
                    okTitle: this.$t('delete.yes'),
                    cancelTitle: this.$t('delete.no'),
                    footerClass: 'p-2',
                })
                .then(async (value) => {

                    //if the user hit OK try to delete the register
                    if (value == true) {

                        let isResult = false

                        for (let i = 0; i < this.associatedData.length; i++) {

                            if (this.associatedData[i].filter == "") {

                                this.associatedData[i].result = []

                                let getAssociatedData = await axios.get(this._props.endpoint + '/' + id + '/' + this.associatedData[i].endpoint + 's')

                                //if endpoint is not empty push to result
                                for (let j = 0; j < getAssociatedData.data._embedded[this.associatedData[i].endpoint].length; j++) {
                                    //in the specific case of formulaMaterial we need to acces to the formula data (to display 'interesting' data to the user)
                                    if (this.associatedData[i].endpoint == 'formulaMaterial') {
                                        let formula = await axios.get(getAssociatedData.data._embedded[this.associatedData[i].endpoint][j]._links.formula.href)
                                        this.associatedData[i].result.push(formula.data)
                                        //in the specific case of materialIngredient we need to acces to the material data (to display 'interesting' data to the user)
                                    } else if (this.associatedData[i].endpoint == 'materialIngredient') {
                                        let material = await axios.get(getAssociatedData.data._embedded[this.associatedData[i].endpoint][j]._links.material.href)
                                        this.associatedData[i].result.push(material.data)
                                    } else if (this.associatedData[i].endpoint == 'materialDistributor') {
                                        let material = await axios.get(getAssociatedData.data._embedded[this.associatedData[i].endpoint][j]._links.material.href)
                                        this.associatedData[i].result.push(material.data)
                                    } else {
                                        this.associatedData[i].result.push(getAssociatedData.data._embedded[this.associatedData[i].endpoint][j])
                                    }
                                    isResult = true
                                }

                            } else {

                                var paramValue = {
                                    "searchValue": this._props.searchValue,
                                };

                                //performe the search for each relation data
                                let response = await axios.get(this.associatedData[i].endpoint + this.associatedData[i].filter, {
                                    params: paramValue
                                })

                                this.associatedData[i].result = []

                                //if we get a match
                                if (response.data._embedded.entityModels.length > 0) {
                                    //loop to the match to get the link of related data
                                    for (let j = 0; j < response.data._embedded.entityModels.length; j++) {
                                        //get the link of each results
                                        let parseMatchData = await axios.get(response.data._embedded.entityModels[j]._links.self.href)

                                        //get the data of each result
                                        //case of one to many relationship : like a contact is set to many clients, distributors or suppliers
                                        // let getAssociatedData = await axios.get(parseMatchData.data._links[this._props.endpoint + 's'].href)
                                        let getAssociatedData = await axios.get(parseMatchData.data._links[this._props.endpoint + 's'].href)

                                        //compare the id of the result and the id of the register to delete if match push the result in the array
                                        for (let k = 0; k < getAssociatedData.data._embedded[this._props.endpoint].length; k++) {
                                            if (getAssociatedData.data._embedded[this._props.endpoint][k]._links[this._props.endpoint].href.split("/").pop() == id) {
                                                this.associatedData[i].result.push(response.data._embedded.entityModels[j])
                                                isResult = true
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if (isResult == true) {
                            this.$refs['delete-error'].show()
                        } else {

                            for (let i = 0; i < this._props.toDeleteWithRegister.length; i++) {
                                let response = await axios.get(this._props.endpoint + '/' + id + '/' + this._props.toDeleteWithRegister[i].endpoint)
                                if (response.data._embedded[this._props.toDeleteWithRegister[i].embeddedName].length > 0) {
                                    for (let j = 0; j < response.data._embedded[this._props.toDeleteWithRegister[i].embeddedName].length; j++) {
                                        await axios.delete(response.data._embedded[this._props.toDeleteWithRegister[i].embeddedName][j]._links.self.href)
                                    }
                                }
                            }


                            await axios.delete(this._props.endpoint + '/' + id)

                            if (this.$route.params.id) {
                                this.$router.push("/" + this._props.endpoint + "s");
                            } else {
                                this.$emit("delete")
                            }


                        }
                    } else {
                        return
                    }

                })
                //if the register can't be delete due to relationship
                .catch((error) => {
                    console.log(error);
                    this.$bvModal.msgBoxOk('Something went wrong', {
                        title: 'Error',
                        size: 'sm',
                        headerTextVariant: 'danger',
                        okVariant: 'warning',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                    })
                })

        },

        async navigateTo(url) {
            let path = url.split("/")
            let pathLength = path.length
            this.$router.push('/' + path[pathLength - 2] + '/' + path[pathLength - 1])
        },

    },

}
</script>

<style scoped>


</style>
