<template>
<div>
    <div class="container-fluid">
        <img src="../assets/galenit-logo.png" class="logo">
        <h1><b-icon icon="exclamation-circle-fill" variant="danger"></b-icon> {{title}}</h1>
        <p>{{text}}</p>
        <p><b-button @click="goHome()">Home</b-button></p>
    </div>
</div>
</template>

<script>
export default {
    name: 'Page404',
    components: {
    },

    data() {
        return {
            title: "Not found",
            text: "The content you are trying to access is not available."
        }
    },
    methods: {
        goHome(){
            this.$store.dispatch('setAccessedUrl', "/")
            this.$router.push("/home");
        }
    }
};
</script>

<style scoped>
.logo{
    max-width: 30%;
    margin-bottom: 50px;
}
</style>
