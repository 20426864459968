<template>
<div v-can="'Read_Project'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-left">{{$tc('general.project', 2)}}</h1>
        <MainGrid gridTitle="Projects" objectNodeName="entityModels" endpoint="project" :gridColumns="columns" :filter="filter" formUrl="/project" :queryByFilter="true" :customOrderBy="true" deletedataToDisplay="projectName" :deleteAssociatedData="$store.state.project.associatedData"></MainGrid>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MainGrid from '../components/MainGrid'

export default {
  name: 'Projects',
  components: {
    MainGrid
  },
  data() {
    return {
      title: 'Projects',
      columns: [
        {
          label: this.$t('project.projectName'),
          key: "projectName",
          sortable: true,
          initial_sort: true
        },
        {
          label: this.$t('general.clientName'),
          key: "clientName",
          sortable: true,
        },
        {
          label: this.$t('project.targetDate'),
          key: "targetDate",
          sortable: true,
        },
        {
          label: this.$t('project.statusName'),
          key: "statusName",
          sortable: true,
        },
        {
          key: "actionButtons",
          label: "",
        }
      ],
      filter: [{
        method: "findByProjectNameOrTargetDateOrStatusOrClient",
        params: ["searchValue"]
      }]
    }
  }
}
</script>

<style>
.btn-icon{
  cursor: pointer;
  margin: 0px 5px;
}

</style>
