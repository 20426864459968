<template>
<div>
  <multiselect v-can:multiselect="_props.permission" v-model="value" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" :placeholder="$t('general.selectCountry')" label="name" track-by="id" :preselect-first="false" :taggable="false"
    @input="onSelect">
  </multiselect>
</div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

window.axios = require("axios");

export default {
  name: 'CountryList',
  components: {
    Multiselect
  },

  props: {
    selectedCountries: {
      type: Array,
      required: true
    },
    permission:{
        type: String,
    }
  },
  data() {
    return {
      value: [],
      options: []
    }
  },
  methods: {
    onSelect(list) {
      var finalObject = [];
      for (var i = 0; i < list.length; i++) {
        finalObject.push(list[i]['id']);
      }
      this.$emit('update:value', finalObject)
      this.$emit('newCountries', finalObject)
    }
  },
  mounted() {

    axios
      .get('country')
      .then((response) => {
        this.options = response.data.content;

        //find for selected countries in the options list
        for (var i = 0; i < this.options.length; i++) {
          for (var k = 0; k < this._props.selectedCountries.length; k++) {
            if (this.options[i]['id'] == this._props.selectedCountries[k]) {
              this.value.push(this.options[i]);

            }
          }
        }
      });
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
