<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12" v-if="$store.getters.getPermission.name == 'administrator'">
                <h2 class="text-left">{{$t('user.userRole')}}</h2>

                <LayoutCard :title="$t('user.crud')" active="true">

                    <form>
                        <div class="row mb-3 ml-3 mt-3">
                            <label for="mail" class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.role')}}</label>
                            <div class="col-sm-3">
                                <v-select id="inputRole" :placeholder="$t('user.selectRole')" v-model="selectedRole" :clearable="false" :options="roles" label="name" @input="getPermission" class="vselect">
                                </v-select>
                            </div>
                            <span class="ml-2">
                                <font-awesome-icon v-if="selectedRole != [] && selectedRole.name != 'Administrator'" class="btn-icon" @click="showModal('editRole')" icon="edit" />
                            </span>
                            <span class="ml-2">
                                <font-awesome-icon id="plus-circle-button" icon="plus-circle" class="clickable mr-2" @click="showModal('newRole')" />
                            </span>
                        </div>

                    </form>

                </LayoutCard>

                <LayoutCard :title="$tc('general.contact', 2)" v-if="selectedRole != []" active="true">

                    <form>
                        <div>
                            <div class="row ml-2">
                                <h4>{{$tc('general.contact',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="contactPermissionsSelected" :options="contactPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(8) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(8) }}</template>
                                </Multiselect>

                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.allowedStatus')}}</label>

                                <Multiselect ref='multiselect' v-model="contactPermissionsStatusSelected" :options="contactStatusPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(21) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(21) }}</template>
                                </Multiselect>
                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$tc('general.client',1)}}</h4>
                            </div>

                            <div class="row ml-3">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="clientPermissionsSelected" :options="clientPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(6) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(6) }}</template>
                                </Multiselect>

                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.allowedStatus')}}</label>

                                <Multiselect ref='multiselect' v-model="clientPermissionsStatusSelected" :options="clientStatusPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(20) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(20) }}</template>
                                </Multiselect>
                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$tc('general.distributor',1)}}</h4>
                            </div>

                            <div class="row ml-3">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="distributorPermissionsSelected" :options="distributorPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(12) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(12) }}</template>
                                </Multiselect>

                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.allowedStatus')}}</label>

                                <Multiselect ref='multiselect' v-model="distributorPermissionsStatusSelected" :options="distributorStatusPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(25) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(25) }}</template>
                                </Multiselect>
                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$tc('general.supplier',1)}}</h4>
                            </div>

                            <div class="row ml-3">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="supplierPermissionsSelected" :options="supplierPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(8) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(8) }}</template>
                                </Multiselect>

                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.allowedStatus')}}</label>

                                <Multiselect ref='multiselect' v-model="supplierPermissionsStatusSelected" :options="supplierStatusPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(21) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(21) }}</template>
                                </Multiselect>
                            </div>

                        </div>

                    </form>

                </LayoutCard>

                <LayoutCard :title="$tc('general.project', 2)" v-if="selectedRole != []" active="true">

                    <form>
                        <div>
                            <div class="row ml-2">
                                <h4>{{$tc('general.project',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="projectPermissionsSelected" :options="projectPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(7) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(7) }}</template>
                                </Multiselect>

                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.allowedStatus')}}</label>

                                <Multiselect ref='multiselect' v-model="projectPermissionsStatusSelected" :options="projectStatusPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(23) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(23) }}</template>
                                </Multiselect>
                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$tc('general.product',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="productPermissionsSelected" :options="productPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(7) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(7) }}</template>
                                </Multiselect>

                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.allowedStatus')}}</label>

                                <Multiselect ref='multiselect' v-model="productPermissionsStatusSelected" :options="productStatusPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(23) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(23) }}</template>
                                </Multiselect>
                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$tc('general.formulation',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="formulaPermissionsSelected" :options="formulaPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(7) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(7) }}</template>
                                </Multiselect>

                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.allowedStatus')}}</label>

                                <Multiselect ref='multiselect' v-model="formulaPermissionsStatusSelected" :options="formulaStatusPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(24) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(24) }}</template>
                                </Multiselect>

                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$tc('general.test',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="testPermissionsSelected" :options="testPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(12) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(12) }}</template>
                                </Multiselect>



                                <!-- NO STATUS PERMISSION YET -->
                                <!-- <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.allowedStatus')}}</label>

                                <Multiselect ref='multiselect' v-model="testPermissionsStatusSelected" :options="testStatusPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(0) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(0) }}</template>
                                </Multiselect> -->
                            </div>

                        </div>

                    </form>

                </LayoutCard>

                <LayoutCard :title="$tc('general.ingredient', 2)" v-if="selectedRole != []" active="true">

                    <form>
                        <div>
                            <div class="row ml-2">
                                <h4>{{$tc('general.material',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="materialPermissionsSelected" :options="materialPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(8) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(8) }}</template>
                                </Multiselect>

                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.allowedStatus')}}</label>

                                <Multiselect ref='multiselect' v-model="materialPermissionsStatusSelected" :options="materialStatusPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(28) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(28) }}</template>
                                </Multiselect>
                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$tc('general.ingredient',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="ingredientPermissionsSelected" :options="ingredientPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(10) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(10) }}</template>
                                </Multiselect>

                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('conformity.conditional')}}</label>

                                <Multiselect ref='multiselect' v-model="conditionalPermissionsSelected" :options="conditionalPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(12) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(12) }}</template>
                                </Multiselect>

                            </div>

                        </div>

                    </form>

                </LayoutCard>

                <LayoutCard :title="$tc('general.setup', 2)" v-if="selectedRole != []" active="true">

                    <form>
                        <div>
                            <div class="row ml-2">
                                <h4>{{$tc('general.regulation',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$tc('general.regulation',1)}}</label>

                                <Multiselect ref='multiselect' v-model="regulationPermissionsSelected" :options="regulationPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(10) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(10) }}</template>
                                </Multiselect>

                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('regulation.nomenclature')}}</label>

                                <Multiselect ref='multiselect' v-model="nomenclaturePermissionsSelected" :options="nomenclaturePermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(13) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(13) }}</template>
                                </Multiselect>
                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$tc('general.template',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('tests.testType')}}</label>

                                <Multiselect ref='multiselect' v-model="testTypePermissionsSelected" :options="testTypePermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(16) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(16) }}</template>
                                </Multiselect>

                                <label class="col-sm-3 col-form-label font-weight-bold text-left">{{$t('process.processType')}}</label>

                                <Multiselect ref='multiselect' v-model="processPermissionsSelected" :options="processPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(19) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(19) }}</template>
                                </Multiselect>
                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$tc('user.user',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="userPermissionsSelected" :options="userPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(5) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(5) }}</template>
                                </Multiselect>
                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$tc('user.role',1)}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="rolePermissionsSelected" :options="rolePermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(4) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(4) }}</template>
                                </Multiselect>
                            </div>

                            <div class="row ml-2 mt-4">
                                <h4>{{$t('fileManager.uploadFile')}}</h4>
                            </div>

                            <div class="row ml-2">
                                <label class="col-sm-2 col-form-label font-weight-bold text-left">{{$t('user.permission')}}</label>

                                <Multiselect ref='multiselect' v-model="fileManagerPermissionsSelected" :options="fileManagerPermissionsOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true"
                                    :placeholder="$t('user.choosePermission')" label="name" track-by="name" :preselect-first="false" :taggable="false" @input="savePermission">
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag">
                                            <span>{{ option.name.substring(11) }}</span>
                                            <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">{{ props.option.name.substring(11) }}</template>
                                </Multiselect>
                            </div>

                        </div>

                    </form>

                </LayoutCard>

                <div class="fixed-top mt-3 d-flex justify-content-center">
                    <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                        {{ feedbackMessage }}
                    </b-alert>
                </div>

                <b-modal ref="newRole" id="newRole" :title="$t('user.addRole')" @ok="addRole" @cancel="cancelRole">

                    <div class="row mt-3 mb-5">
                        <label for="group" class="col-sm-3 font-weight-bold col-form-label">{{$t('user.role')}}</label>
                        <div class="col-sm-9">
                            <input type="text" id="group" class="form-control mb-2" v-model="newRole" />
                        </div>
                    </div>

                </b-modal>

                <b-modal ref="editRole" id="editRole" :title="$t('user.editRole')" @ok="editRole" @cancel="cancelRole">

                    <div class="row mt-3 mb-5">
                        <label for="group" class="col-sm-3 font-weight-bold col-form-label">{{$t('user.role')}}</label>
                        <div class="col-sm-9">
                            <input type="text" id="group" class="form-control mb-2" v-model="selectedRole.name" />
                        </div>
                    </div>

                </b-modal>
            </div>
            <div class="col-md-12" v-else>
                <h2 class="text-left">{{$t('general.NotAllowed')}}</h2>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import Feedback from '../mixins/Feedback.js'
import LayoutCard from '../components/LayoutCard.vue'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

import Multiselect from 'vue-multiselect'

window.axios = require("axios");

export default {
    name: 'Setup-User-Role',

    components: {
        LayoutCard,
        Multiselect
    },

    mixins: [Feedback, CommonVariablesAndFunctions],

    data() {
        return {

            roles: [],
            selectedRole: '',
            newRole: "",

            allExistingPermissions: [],

            contactPermissionsOptions: [],
            contactStatusPermissionsOptions: [],
            contactPermissionsSelected: [],
            contactPermissionsStatusSelected: [],

            clientPermissionsOptions: [],
            clientStatusPermissionsOptions: [],
            clientPermissionsSelected: [],
            clientPermissionsStatusSelected: [],

            distributorPermissionsOptions: [],
            distributorStatusPermissionsOptions: [],
            distributorPermissionsSelected: [],
            distributorPermissionsStatusSelected: [],

            supplierPermissionsOptions: [],
            supplierStatusPermissionsOptions: [],
            supplierPermissionsSelected: [],
            supplierPermissionsStatusSelected: [],

            projectPermissionsOptions: [],
            projectStatusPermissionsOptions: [],
            projectPermissionsSelected: [],
            projectPermissionsStatusSelected: [],

            productPermissionsOptions: [],
            productStatusPermissionsOptions: [],
            productPermissionsSelected: [],
            productPermissionsStatusSelected: [],

            formulaPermissionsOptions: [],
            formulaStatusPermissionsOptions: [],
            formulaPermissionsSelected: [],
            formulaPermissionsStatusSelected: [],

            processPermissionsOptions: [],
            processPermissionsSelected: [],

            testPermissionsOptions: [],
            testStatusPermissionsOptions: [],
            testPermissionsSelected: [],
            testPermissionsStatusSelected: [],

            testTypePermissionsSelected: [],
            testTypePermissionsOptions: [],

            materialPermissionsOptions: [],
            materialStatusPermissionsOptions: [],
            materialPermissionsSelected: [],
            materialPermissionsStatusSelected: [],

            ingredientPermissionsOptions: [],
            ingredientPermissionsSelected: [],

            conditionalPermissionsOptions: [],
            conditionalPermissionsSelected: [],

            regulationPermissionsOptions: [],
            regulationPermissionsSelected: [],

            nomenclaturePermissionsOptions: [],
            nomenclaturePermissionsSelected: [],

            userPermissionsOptions: [],
            userPermissionsSelected: [],

            rolePermissionsOptions: [],
            rolePermissionsSelected: [],

            fileManagerPermissionsOptions: [],
            fileManagerPermissionsSelected: [],

        }
    },
    computed: {

        selectedPermissions: function() {
            const permissions = [
                ...this.contactPermissionsSelected,
                ...this.contactPermissionsStatusSelected,
                ...this.clientPermissionsSelected,
                ...this.clientPermissionsStatusSelected,
                ...this.distributorPermissionsSelected,
                ...this.distributorPermissionsStatusSelected,
                ...this.supplierPermissionsSelected,
                ...this.supplierPermissionsStatusSelected,
                ...this.projectPermissionsSelected,
                ...this.projectPermissionsStatusSelected,
                ...this.productPermissionsSelected,
                ...this.productPermissionsStatusSelected,
                ...this.formulaPermissionsSelected,
                ...this.formulaPermissionsStatusSelected,
                ...this.processPermissionsSelected,
                ...this.testPermissionsSelected,
                ...this.testPermissionsStatusSelected,
                ...this.testTypePermissionsSelected,
                ...this.materialPermissionsSelected,
                ...this.materialPermissionsStatusSelected,
                ...this.ingredientPermissionsSelected,
                ...this.conditionalPermissionsSelected,
                ...this.regulationPermissionsSelected,
                ...this.nomenclaturePermissionsSelected,
                ...this.userPermissionsSelected,
                ...this.rolePermissionsSelected,
                ...this.fileManagerPermissionsSelected
            ];
            return permissions
        },

    },

    methods: {

        savePermission() {

            let readUser = this.allExistingPermissions.filter(item => item.key.includes('Read_User'))[0]._links.self.href;
            let readPermission = this.allExistingPermissions.filter(item => item.key.includes('Read_Permission'))[0]._links.self.href;
            let readStatusFile = this.allExistingPermissions.filter(item => item.key.includes('Read_StatusFile'))[0]._links.self.href;
            let readStatusFormulaProjectProduct = this.allExistingPermissions.filter(item => item.key.includes('Read_StatusFormulaProjectProduct'))[0]._links.self.href;
            let readStatusMaterialINCIRegulation = this.allExistingPermissions.filter(item => item.key.includes('Read_StatusMaterialINCIRegulation'))[0]._links.self.href;
            let readMaterialFamily = this.allExistingPermissions.filter(item => item.key.includes('Read_MaterialFamily'))[0]._links.self.href;
            let readCountry = this.allExistingPermissions.filter(item => item.key.includes('Read_Country'))[0]._links.self.href;
            let readLanguage = this.allExistingPermissions.filter(item => item.key.includes('Read_Language'))[0]._links.self.href;
            let readIngredientAction = this.allExistingPermissions.filter(item => item.key.includes('Read_IngredientAction'))[0]._links.self.href;
            let readMaterialPrefix = this.allExistingPermissions.filter(item => item.key.includes('Read_MaterialPrefix'))[0]._links.self.href;
            let readFormulaPrefix = this.allExistingPermissions.filter(item => item.key.includes('Read_FormulaPrefix'))[0]._links.self.href;
            let readFormulaTestResult = this.allExistingPermissions.filter(item => item.key.includes('Read_FormulaTestResult'))[0]._links.self.href;

            let permissionsTempString = readUser + "\n" +
                readPermission + "\n" +
                readStatusFile + "\n" +
                readStatusFormulaProjectProduct + "\n" +
                readStatusMaterialINCIRegulation + "\n" +
                readMaterialFamily + "\n" +
                readCountry + "\n" +
                readLanguage + "\n" +
                readIngredientAction + "\n" +
                readMaterialPrefix + "\n" +
                readFormulaPrefix + "\n" +
                readFormulaTestResult;

            // let permissionsTempString = "";

            for (var i = 0; i < this.selectedPermissions.length; i++) {
                permissionsTempString += this.selectedPermissions[i]._links.self.href + "\n";
            }

            axios.put(this.selectedRole._links.permissions.href, permissionsTempString, {
                headers: {
                    "Content-type": "text/uri-list"
                }
            }).then(() => {
                this.succesAlert()
            }).catch((error) => {
                this.errorAlert(error)
            })
        },

        async getPermission() {

            let allPermissions = []

            let getRolePermissions = await axios.get(this.selectedRole._links.permissions.href)
            let permissions = getRolePermissions.data._embedded.permission

            for (let i = 0; i < permissions.length; i++) {
                allPermissions.push(permissions[i])
            }

            let contactPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_Contact'))
            this.contactPermissionsSelected = contactPermissionsSelectedAll.filter(item => !item.key.includes('StatusFile_'))
            this.contactPermissionsStatusSelected = contactPermissionsSelectedAll.filter(item => item.key.includes('StatusFile_'))

            let clientPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_Client'))
            this.clientPermissionsSelected = clientPermissionsSelectedAll.filter(item => !item.key.includes('StatusFile_'))
            this.clientPermissionsStatusSelected = clientPermissionsSelectedAll.filter(item => item.key.includes('StatusFile_'))

            let distributorPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_Distributor'))
            this.distributorPermissionsSelected = distributorPermissionsSelectedAll.filter(item => !item.key.includes('StatusFile_'))
            this.distributorPermissionsStatusSelected = distributorPermissionsSelectedAll.filter(item => item.key.includes('StatusFile_'))

            let supplierPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_Supplier'))
            this.supplierPermissionsSelected = supplierPermissionsSelectedAll.filter(item => !item.key.includes('StatusFile_'))
            this.supplierPermissionsStatusSelected = supplierPermissionsSelectedAll.filter(item => item.key.includes('StatusFile_'))

            let projectPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_Project'))
            this.projectPermissionsSelected = projectPermissionsSelectedAll.filter(item => !item.key.includes('StatusFormula_'))
            this.projectPermissionsStatusSelected = projectPermissionsSelectedAll.filter(item => item.key.includes('StatusFormula_'))

            let productPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_Product'))
            this.productPermissionsSelected = productPermissionsSelectedAll.filter(item => !item.key.includes('StatusFormula_'))
            this.productPermissionsStatusSelected = productPermissionsSelectedAll.filter(item => item.key.includes('StatusFormula_'))

            let formulaPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_Formula'))
            let formulaPermissionsSelectedTmp = formulaPermissionsSelectedAll.filter(item => !item.key.includes('StatusFormula_'))
            let formulaPermissionsSelectedTmp2 = formulaPermissionsSelectedTmp.filter(item => !item.key.includes('_FormulaTest'))
            this.formulaPermissionsSelected = formulaPermissionsSelectedTmp2.filter(item => !item.name.includes('Enum'))
            this.formulaPermissionsStatusSelected = formulaPermissionsSelectedAll.filter(item => item.key.includes('StatusFormula_'))

            this.processPermissionsSelected = allPermissions.filter(item => item.key.includes('_FormulaProcess'))

            let testPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_FormulaTest'))
            let testPermissionsSelectedTmp = testPermissionsSelectedAll.filter(item => !item.key.includes('StatusFormula_'))
            let testPermissionsSelectedTmp2 = testPermissionsSelectedTmp.filter(item => !item.key.includes('FormulaTestType'))
            this.testPermissionsSelected = testPermissionsSelectedTmp2.filter(item => !item.name.includes('Enum'))
            // NO status permission YET
            // this.testPermissionsStatusSelected = testPermissionsSelectedAll.filter(item => item.key.includes('StatusFormula_'))
            this.testTypePermissionsSelected = testPermissionsSelectedAll.filter(item => item.key.includes('FormulaTestType'))

            let materialPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_Material'))
            // let materialPermissionsSelectedTmp = materialPermissionsSelectedAll.filter(item => !item.key.includes('StatusMaterial_'))
            this.materialPermissionsSelected = materialPermissionsSelectedAll.filter(item => !item.name.includes('Enum'))
            let materialPermissionsStatusSelectedTmp = allPermissions.filter(item => item.key.includes('StatusMaterial_'))
            this.materialPermissionsStatusSelected = materialPermissionsStatusSelectedTmp.filter(item => item.key.includes('_RawMaterial'))

            let ingredientPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_Ingredient'))
            let ingredientPermissionsSelectedTmp = ingredientPermissionsSelectedAll.filter(item => !item.key.includes('_IngredientItemOfFormulation'))
            let ingredientPermissionsSelectedTmp2 = ingredientPermissionsSelectedTmp.filter(item => !item.name.includes('Enum'))
            this.ingredientPermissionsSelected = ingredientPermissionsSelectedTmp2.filter(item => !item.key.includes('StatusFormula_'))

            this.conditionalPermissionsSelected = allPermissions.filter(item => item.key.includes('_Conditional'))

            let regulationPermissionsSelectedAll = allPermissions.filter(item => item.key.includes('_Regulation'))
            this.regulationPermissionsSelected = regulationPermissionsSelectedAll.filter(item => !item.key.includes('StatusMaterial_'))

            this.nomenclaturePermissionsSelected = allPermissions.filter(item => item.key.includes('_InciAuxiliary'))

            this.userPermissionsSelected = allPermissions.filter(item => item.key.includes('_User'))

            this.rolePermissionsSelected = allPermissions.filter(item => item.key.includes('_Role'))

            this.fileManagerPermissionsSelected = allPermissions.filter(item => item.key.includes('_FileManager'))

        },

        showModal(ref) {
            this.$refs[ref].show()
        },

        addRole() {
            let data = {
                name: this.newRole,
                permission: []
            }

            axios.post('role', data)
                .then((response) => {
                    this.loadOptionFields()
                    this.selectedRole = response.data
                    this.savePermission()
                })
        },

        editRole() {
            let data = {
                name: this.selectedRole.name,
            }

            axios.put(this.selectedRole._links.self.href, data)
                .then(() => {
                    this.loadOptionFields()
                })
        },

        cancelRole() {
            this.loadOptionFields()
        },

        async loadOptionFields() {

            let roleResponse = await axios.get('role')
            this.roles = roleResponse.data._embedded.role

            let response = await axios.get('permission?size=300')
            this.allExistingPermissions = response.data._embedded.permission

            let contactPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_Contact'))
            this.contactPermissionsOptions = contactPermissionsOptionsAll.filter(item => !item.key.includes('StatusFile_'))
            this.contactStatusPermissionsOptions = contactPermissionsOptionsAll.filter(item => item.key.includes('StatusFile_'))

            let clientPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_Client'))
            this.clientPermissionsOptions = clientPermissionsOptionsAll.filter(item => !item.key.includes('StatusFile_'))
            this.clientStatusPermissionsOptions = clientPermissionsOptionsAll.filter(item => item.key.includes('StatusFile_'))

            let distributorPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_Distributor'))
            this.distributorPermissionsOptions = distributorPermissionsOptionsAll.filter(item => !item.key.includes('StatusFile_'))
            this.distributorStatusPermissionsOptions = distributorPermissionsOptionsAll.filter(item => item.key.includes('StatusFile_'))

            let supplierPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_Supplier'))
            this.supplierPermissionsOptions = supplierPermissionsOptionsAll.filter(item => !item.key.includes('StatusFile_'))
            this.supplierStatusPermissionsOptions = supplierPermissionsOptionsAll.filter(item => item.key.includes('StatusFile_'))

            let projectPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_Project'))
            this.projectPermissionsOptions = projectPermissionsOptionsAll.filter(item => !item.key.includes('StatusFormula_'))
            this.projectStatusPermissionsOptions = projectPermissionsOptionsAll.filter(item => item.key.includes('StatusFormula_'))

            let productPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_Product'))
            this.productPermissionsOptions = productPermissionsOptionsAll.filter(item => !item.key.includes('StatusFormula_'))
            this.productStatusPermissionsOptions = productPermissionsOptionsAll.filter(item => item.key.includes('StatusFormula_'))

            let formulaPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_Formula'))
            let formulaPermissionsOptionsTmp = formulaPermissionsOptionsAll.filter(item => !item.key.includes('StatusFormula_'))
            let formulaPermissionsOptionsTmp2 = formulaPermissionsOptionsTmp.filter(item => !item.key.includes('_FormulaTest'))
            this.formulaPermissionsOptions = formulaPermissionsOptionsTmp2.filter(item => !item.name.includes('Enum'))
            this.formulaStatusPermissionsOptions = formulaPermissionsOptionsAll.filter(item => item.key.includes('StatusFormula_'))

            this.processPermissionsOptions = this.allExistingPermissions.filter(item => item.key.includes('_FormulaProcess'))

            let testPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_FormulaTest'))
            let testPermissionsOptionsTmp = testPermissionsOptionsAll.filter(item => !item.key.includes('StatusFormula_'))
            let testPermissionsOptionsTmp2 = testPermissionsOptionsTmp.filter(item => !item.key.includes('FormulaTestType'))
            this.testPermissionsOptions = testPermissionsOptionsTmp2.filter(item => !item.name.includes('Enum'))
            // NO status permission YET
            // this.testStatusPermissionsOptions = testPermissionsOptionsAll.filter(item => item.key.includes('StatusFormula_'))

            this.testTypePermissionsOptions = testPermissionsOptionsAll.filter(item => item.key.includes('FormulaTestType'))

            let materialPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_Material'))
            // let materialPermissionsOptionsTmp = materialPermissionsOptionsAll.filter(item => !item.key.includes('StatusMaterial_'))
            this.materialPermissionsOptions = materialPermissionsOptionsAll.filter(item => !item.name.includes('Enum'))
            let materialStatusPermissionsOptionsTmp = this.allExistingPermissions.filter(item => item.key.includes('StatusMaterial_'))
            this.materialStatusPermissionsOptions = materialStatusPermissionsOptionsTmp.filter(item => item.key.includes('_RawMaterial'))

            let ingredientPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_Ingredient'))
            let ingredientPermissionsOptionsTmp = ingredientPermissionsOptionsAll.filter(item => !item.key.includes('_IngredientItemOfFormulation'))
            let ingredientPermissionsOptionsTmp2 = ingredientPermissionsOptionsTmp.filter(item => !item.name.includes('Enum'))
            this.ingredientPermissionsOptions = ingredientPermissionsOptionsTmp2.filter(item => !item.key.includes('StatusFormula_'))

            this.conditionalPermissionsOptions = this.allExistingPermissions.filter(item => item.key.includes('_Conditional'))

            let regulationPermissionsOptionsAll = this.allExistingPermissions.filter(item => item.key.includes('_Regulation'))
            this.regulationPermissionsOptions = regulationPermissionsOptionsAll.filter(item => !item.key.includes('StatusMaterial_'))

            this.nomenclaturePermissionsOptions = this.allExistingPermissions.filter(item => item.key.includes('_InciAuxiliary'))

            this.userPermissionsOptions = this.allExistingPermissions.filter(item => item.key.includes('_User'))

            this.rolePermissionsOptions = this.allExistingPermissions.filter(item => item.key.includes('_Role'))

            this.fileManagerPermissionsOptions = this.allExistingPermissions.filter(item => item.key.includes('_FileManager'))

        }

    },
    created() {

        this.loadOptionFields()

    },
}
</script>

<style>
.btn-icon {
    cursor: pointer;
    margin: 0px 5px;
}

.select:-internal-list-box option:checked {
    color: red;
}
</style>
