<template>
<div v-can="'Read_Regulation'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h2 class="text-left"><router-link to="/regulations/regulations">{{$tc('general.regulation', 1)}}</router-link></h2>

                <LayoutCard :title="$tc('general.regulation', 1)" active="true">

                    <template v-slot:header-options>
                        <DeleteButton v-can="'Delete_Regulation'" endpoint="regulation" :dataToDisplay="objectData.name"></DeleteButton>
                    </template>

                    <div class="loader" v-if="loading">

                    </div>

                    <fieldset :disabled="loading">
                        <form @change="save()">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputName">{{$t('general.name')}}</label>
                                    <b-form-input v-can:input="'Update_Regulation'" type="text" class="form-control" id="inputName" :placeholder="$t('general.name')" :state="validateState($v.objectData.name)" :lazy="true" :trim="true" v-model="$v.objectData.name.$model" />
                                    <div class="invalid-feedback" id="regulation-name-required" v-if="!$v.objectData.name.required">{{$t('errorMsg.required')}}</div>
                                </div>
                                <div v-if='!loading' class="form-group col-md-6">
                                    <label for="inputCountry">{{$t('contact.country')}}</label>
                                    <CountryList permission="Update_Regulation" @newCountries="save()" :selectedCountries="objectData.countries" :value.sync="objectData.countries"></CountryList>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputObs">{{$t('general.note')}}</label>
                                    <textarea v-can:input="'Update_Regulation'" class="form-control" id="inputObs" rows="3" v-model="objectData.obs"></textarea>
                                </div>
                            </div>
                        </form>
                    </fieldset>

                </LayoutCard>

                <div v-if="isMod">
                    <SaveCancelDeleteButtons permission="Create_Regulation" @clicked="getClicked" @isMod="isMod" :isError="$v.$anyError"></SaveCancelDeleteButtons>
                </div>

                <div class="fixed-top mt-3 d-flex justify-content-center">
                    <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                        {{ feedbackMessage }}
                    </b-alert>
                </div>

                <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('general.history')">
                    <History :dataType="endpoint" :addBy="objectData.addBy" :addDate="objectData.addDate" :lastModBy="objectData.modBy" :lastMod="objectData.modDate" />
                </LayoutCard>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import History from '../components/History.vue'
import LayoutCard from '../components/LayoutCard.vue'
import CountryList from '../components/CountryList.vue'
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'
import DeleteButton from '../components/DeleteButton.vue'
import {
    required,
} from 'vuelidate/lib/validators'

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

window.axios = require("axios");

export default {
    name: 'Regulation',

    mixins: [Feedback, CommonVariablesAndFunctions],

    components: {
        History,
        LayoutCard,
        DeleteButton,
        SaveCancelDeleteButtons,
        CountryList
    },

    data() {
        return {
            title: "Regulation",
            endpoint: 'regulation',
            value: "",

            objectData: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: null,
                name: "",
                obs: "",
                countries: []
            }
        };
    },
    methods: {

        getData() {
            this.loading = true,

                axios
                .get(this.endpoint + '/' + this.$route.params.id)
                .then((response) => {
                    this.objectData = response.data;
                    this.loading = false;
                    this.isMod = false;
                })
                .catch((error) => {
                    this.errorAlert(error)
                })
        },

        processForm() {

            let regulation = {
                name: this.objectData.name,
                obs: this.objectData.obs,
                countries: this.objectData.countries
            };
            return regulation

        },

        postData(endpoint, regulation) {
            axios
                .post(endpoint, regulation)
                .then((response) => {
                    this.isMod = false
                    this.succesAlert()
                    let id = response.data._links.self.href.split("/").pop();
                    this.$router.push(this.endpoint + '/' + id);
                })
                .catch((error) => {
                    this.errorAlert(error)
                })
        },

        putData(endpoint, regulation) {
            axios
                .put(endpoint, regulation)
                .then(() => {
                    this.succesAlert()
                    this.isMod = false;
                })
                .catch((error) => {
                    this.errorAlert(error)
                })
        },
    },

    created() {
        if (this.$route.params.id) {
            this.getData();
        } else {
            this.loading = false;
        }
    },

    validations: {
        objectData: {
            name: {
                required
            }
        }

    }
};
</script>

<style scoped>
.form-group {
    text-align: left;
    font-weight: bold;
}
</style>
