<template>
<div :class="{small : isSmall}">

    <b-table :busy.sync="loading" responsive="md" small primary-key="id" :striped="_props.striped" :bordered="_props.bordered" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="gridFormulationData" :fields="fields">

        <template #table-busy>
            <div class="loader">
            </div>
        </template>

        <template v-slot:cell(phase)="row">
            <b-form-input v-can:input="'Update_Formula'" :size="isSm" lazy v-model.trim="row.item.phase" @change="saveMaterial(row.item)" />
        </template>

        <template v-slot:cell(prefixId)="row">
            <span class="inputPrefixId">{{row.item.prefixId}}</span>
        </template>

        <template #head(tradeName)="">
            {{$t('material.tradeName')}}
            <font-awesome-icon icon="search" class="btn-icon" @click="showModal('material')" />
        </template>

        <template v-slot:cell(tradeName)="row">
            <b-input-group>

                <b-form-input v-can:input="'Update_Formula'" :ref='"input_" + row.item.id' list="materials-list" :size="isSm" class="inputTradeName" placeholder="Material" v-model="row.item.tradeName.name" autocomplete="off" @input="getMaterials(row.item)"
                    @blur="saveMaterial(row.item)">
                </b-form-input>

                <b-input-group-append class="border-append">
                    <StatusMaterialINCIRegulation :status="row.item.status"></StatusMaterialINCIRegulation>
                </b-input-group-append>

                <b-input-group-append class="border-append border-append-round-right" v-if="row.item.tradeName.url && row.item.tradeName.url != ''">
                    <font-awesome-icon class="btn-icon my-auto mr-1 ml-1" @click="materialModal(row.item)" icon="eye" />
                </b-input-group-append>

                <datalist id="materials-list">
                    <option v-for="material in materials" :key="material._links.self.href" field-value="material._links.self.href"> {{ material.tradeName }}</option>
                </datalist>

            </b-input-group>
            <div class="text-danger font-italic font-weight-bold small" v-if="row.item.blacklistedIngredients.length == 1">
                <span v-for="(ingredient, id) in row.item.blacklistedIngredients" :key="id"> {{ingredient}}</span> {{$tc('formulation.contrainsBlacklist', 1)}}
            </div>
            <div class="text-danger font-italic font-weight-bold small" v-if="row.item.blacklistedIngredients.length > 1">
                <span v-for="(ingredient, id) in row.item.blacklistedIngredients.slice(0, row.item.blacklistedIngredients.length-2)" :key="'ingrediend' + id"> {{ingredient}}, </span>
                <span v-for="(ingredient, id) in row.item.blacklistedIngredients.slice(row.item.blacklistedIngredients.length-2, row.item.blacklistedIngredients.length-1)" :key="'before-last-ingrediend'+id"> {{ingredient}} and</span>
                <span v-for="(ingredient, id) in row.item.blacklistedIngredients.slice(row.item.blacklistedIngredients.length-1, row.item.blacklistedIngredients.length)" :key="'final-ingrediend'+id"> {{ingredient}}</span>
                {{$tc('formulation.contrainsBlacklist', 2)}}
            </div>
        </template>

        <template v-slot:head(inci)="field">
            <div v-show="_props.showHideInci">{{ field.label }}</div>
        </template>
        <template v-slot:cell(inci)="row">
            <li v-show="_props.showHideInci" v-for="(item, i) in row.item.inci" :key="i" class="no-bullet">
                <!-- <b-badge class="m-1" variant="info">{{ item }}</b-badge> -->
                {{ item }}<span v-if="i != row.item.inci.length - 1">; </span>
            </li>
        </template>
        <template v-slot:cell(percentage)="row">
            <span v-if="row.item.qsp" >{{ row.item.percentage.toLocaleString() }}</span>
            <b-form-input v-can:input="'Update_Formula'" v-else :size="isSm" type="number" min="0" max="100" step="0.001" class="input-formulation-table-center" @change="calculQsp(row.item)" v-model="row.item.percentage" />
        </template>
        <template v-slot:cell(qsp)="row">
            <input type="radio" @change="selectedQsp(row.item)" v-model="row.item.qsp" :value="true">
        </template>
        <template v-slot:head(toweight)="field">
            <div v-show="_props.showHideToWeight">{{ field.label }}</div>
        </template>
        <template v-slot:cell(toweight)="row">
            <div v-show="_props.showHideToWeight">
                {{ Number((_props.toWeight * row.item.percentage / 100).toFixed(3)).toLocaleString()}}
            </div>
        </template>

        <template v-slot:head(price)="field">
            <div v-show="_props.showHidePrice">{{ field.label }}</div>
        </template>
        <template v-slot:cell(price)="row">
            <div v-show="_props.showHidePrice">
                {{ Number((row.item.percentage / 100 * row.item.price).toFixed(2)).toLocaleString()}}
            </div>
        </template>
        <template v-slot:cell(more)="row">
            <div align="right">
                <a class="btn" @click="row.toggleDetails" v-if="row.item.conformity=='NO_RESTRICTION_NO_WARNING' || row.item.conformity=='ALLOWED_NO_WARNING'">
                    <font-awesome-icon class="btn-icon green" icon="check"></font-awesome-icon>
                </a>
                <a class="btn" @click="row.toggleDetails" v-if="row.item.conformity=='NO_RESTRICTION_WARNING' || row.item.conformity=='ALLOWED_WARNING'">
                    <font-awesome-icon class="btn-icon green" icon="exclamation-triangle"></font-awesome-icon>
                </a>
                <a class="btn" @click="row.toggleDetails" v-if="row.item.conformity=='RESTRICTED_INGREDIENT'">
                    <font-awesome-icon class="btn-icon yellow" icon="exclamation-triangle"></font-awesome-icon>
                </a>
                <a class="btn" @click="row.toggleDetails" v-if="row.item.conformity=='PROHIBITED_INGREDIENT'">
                    <font-awesome-icon class="btn-icon red" icon="exclamation-triangle"></font-awesome-icon>
                </a>
                <a class="btn" @click="row.toggleDetails" v-if="row.item.conformity=='NO_PROJECT_REGULATION_SET' || row.item.conformity=='NO_INCI_REGULATION_CONDITION' || row.item.conformity=='NO_INCI_REGULATION'">
                    <font-awesome-icon class="btn-icon gray" icon="exclamation-triangle"></font-awesome-icon>
                </a>
                
                <b-link @click="materialReplacementModal(row.item)"><b-icon v-can="'Update_Formula'" icon="arrow-left-right" variant="dark"></b-icon></b-link>
                <font-awesome-icon v-can="'Update_Formula'" v-if="row.item.qsp==false" class="btn-icon" @click="deleteRow(row.item)" icon="trash-alt" />
            </div>
        </template>

        <template #row-details="row">
            <div>
                <b-table v-if="!loading" responsive="md" small :striped="_props.striped" :bordered="_props.bordered" :items="row.item.conformityIssue" :fields="conformityFields">
                    <template #cell(conformityStatusDescription)="data">
                        <span class="text-primary" v-if="data.item.conformityStatusDescription == 'Allowed ingredient and no warning'">{{$t('conformity.ALLOWED_NO_WARNING')}}</span>
                        <span class="text-primary" v-else-if="data.item.conformityStatusDescription == 'Allowed ingredient and and warning associated'">{{$t('conformity.ALLOWED_WARNING')}}</span>
                        <span class="text-primary" v-else-if="data.item.conformityStatusDescription == 'Restricted ingredient in range and no warning'">{{$t('conformity.NO_RESTRICTION_NO_WARNING')}}</span>
                        <span class="text-primary" v-else-if="data.item.conformityStatusDescription == 'Restricted ingredient in range and warning associated'">{{$t('conformity.NO_RESTRICTION_WARNING')}}</span>
                        <span v-else-if="data.item.conformityStatusDescription == 'No condition at all. The user needs to check manually'">{{$t('conformity.NO_INCI_REGULATION_CONDITION')}}</span>
                        <span v-else-if="data.item.conformityStatusDescription == 'No regulation set at all. The user needs to check manually'">{{$t('conformity.NO_INCI_REGULATION')}}</span>
                        <span class="text-secondary" v-else-if="data.item.conformityStatusDescription == 'Restricted ingredient out of range'">{{$t('conformity.RESTRICTED_INGREDIENT')}}</span>
                        <span class="text-danger" v-else-if="data.item.conformityStatusDescription == 'Prohibited ingredient'">{{$t('conformity.PROHIBITED_INGREDIENT')}}</span>
                        <span v-else-if="data.item.conformityStatusDescription == 'No regulation set in project'">{{$t('conformity.NO_PROJECT_REGULATION_SET')}}</span>
                    </template>
                    <template #cell(warning)="data">
                        <span v-if="data.item.warning == ''">-</span>
                        <span v-else>{{data.item.warning}}</span>
                    </template>
                </b-table>
            </div>
        </template>

    </b-table>

    <div class="d-flex flex-row-reverse mb-4">
        <font-awesome-icon v-can="'Update_Formula'" icon="plus-circle" class="clickable" @click="addNewRow" />
    </div>

    <ModalWindow v-if="openedModal === 'material'" :title="$t('general.search')" @close="openedModal = null">
        <MainGrid :gridTitle="$tc('general.material', 2)" objectNodeName="entityModels" endpoint="material" :gridColumns="modalMaterialColumns" :filter="modalMaterialFilter" :customOrderBy="true" formUrl="/material" :queryByFilter="true"
            @close="openedModal = null" @selectValue="setNewMaterial"></MainGrid>
    </ModalWindow>

    <b-modal ref="material-modal" size="xl" scrollable :title="$tc('general.material', 1)">
        <Material :materialId="modalMaterialId"></Material>
        <template #modal-footer="{ ok }">
            <b-button variant="primary" @click="ok()">OK</b-button>
        </template>
    </b-modal>


    <div class="fixed-top mt-3 d-flex justify-content-center">
        <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
            {{ feedbackMessage }}
        </b-alert>
    </div>
    
    <b-modal ref="material-replacement-modal" id="material-replacement-modal" size="xl" scrollable :title="$tc('material.materialReplacementTitle', 1)" ok-only>
        <MaterialReplacement @refreshParent="closeMaterialReplacementModal" :formulaMaterialId="modalMaterialReplacementId" :formulaMaterialName="modalMaterialReplacementName"></MaterialReplacement>
    </b-modal>
</div>
</template>

<script>
import axios from 'axios'
import StatusMaterialINCIRegulation from '../../components/StatusMaterialINCIRegulation.vue';
import ModalWindow from '../../components/ModalWindow.vue';
import MainGrid from '../../components/MainGrid.vue';
import Material from '../../views/Material.vue';

import Feedback from '../../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../../mixins/CommonVariablesAndFunctions.js'
import MaterialReplacement from '../MaterialReplacement.vue';


export default {
    name: 'FormulationGrid',
    mixins: [Feedback, CommonVariablesAndFunctions],
    components: {
    StatusMaterialINCIRegulation,
    ModalWindow,
    MainGrid,
    Material,
    MaterialReplacement
},
    props: {
        formulaId: {
            type: String,
            required: true
        },
        projectBlacklist: {
            type: Array,
            required: true
        },
        toWeight: {
            required: true,
        },
        showHideInci: {
            type: Boolean,
            required: true,
            default: false
        },
        showHideToWeight: {
            type: Boolean,
            required: true,
            default: false
        },
        showHidePrice: {
            type: Boolean,
            required: true,
            default: false
        },
        striped: {
            type: Boolean,
            required: true,
            default: false
        },
        bordered: {
            type: Boolean,
            required: true,
            default: false
        },
        isSmall: {
            type: Boolean,
            required: true,
            default: false
        },
        regulationSelected: {
            required: true
        }
    },
    data() {
        return {
            loading: true,

            sortBy: 'phase',
            sortDesc: false,

            gridFormulationData: [],

            openedModal: null,

            formula: [],
            materials: [],
            
            modalMaterialReplacementId: null,
            modalMaterialReplacementName: null,

            fields: [{
                    key: 'phase',
                    label: this.$t('formulation.phase'),
                    sortable: true,
                },
                {
                    key: 'prefixId',
                    label: 'ID',
                    sortable: true,
                    thClass: "prefixIdClass",
                    tdClass: "prefixIDColClass"
                },
                {
                    key: 'tradeName',
                    label: this.$t('material.tradeName'),
                    sortable: false,
                    tdClass: "tradeNameColClass"
                },
                {
                    key: 'inci',
                    label: 'INCI',
                    sortable: true,
                },
                {
                    key: 'percentage',
                    label: this.$t('general.percentage'),
                    sortable: true,
                    thClass: "percentageClass"
                },
                {
                    key: 'qsp',
                    label: 'qsp',
                    sortable: true,
                },
                {
                    key: 'toweight',
                    label: this.$t('formulation.quantityToWeight'),
                    sortable: true,
                },
                {
                    key: 'price',
                    label: this.$t('general.priceKg'),
                    sortable: true,
                },
                {
                    key: 'more',
                    label: '',
                },
            ],

            conformityFields: [{
                key: 'ingredientName',
                label: this.$t('conformity.ingredient')
            }, {
                key: 'globalPercentage',
                label: this.$t('conformity.globalPercentage')
            }, {
                key: 'conditional',
                label: this.$t('conformity.conditional')
            }, {
                key: 'conditionalPercentage',
                label: this.$t('conformity.conditionalPercentage')
            }, {
                key: 'warning',
                label: this.$t('conformity.warning')
            }, {
                key: 'conformityStatusDescription',
                label: this.$t('conformity.status')
            }, {
                key: 'regulationName',
                label: this.$t('regulation.regulationName')
            }],

            modalMaterialId: null,

            modalMaterialColumns: [{
                    label: this.$t('material.tradeName'),
                    key: "tradeName",
                    sortable: false,
                    initial_sort: false
                },
                {
                    key: "returnButton",
                }
            ],
            modalMaterialFilter: [{
                method: "findByMaterialIngredientInciDistributor",
                params: ["searchValue"]
            }],

        }

    },
    computed: {

    isSm: function(){
            if(this._props.isSmall){
                return "sm"
            }else{
                return ""
            }
        },
    },

    mounted: function() {
        if (this.$route.params.id && this.regulationSelected != "") {
            this.getData()
        } else {
            this.loading = false
        }
        this.$root.$on('add_material', (materialLink) => {
            this.setNewMaterial(materialLink)
        })
    },

    watch: {
        formulaId: function() {
            if (this.$route.params.id) {
                this.getData()
            }

        },
    },

    methods: {

        gridUpdated() {
            this.$emit('update-grid', this.gridFormulationData)
        },

        showModal(type) {
            this.openedModal = type
        },

        materialModal(url) {
            this.modalMaterialId = url.tradeName.url.split('/').pop()
            this.$refs['material-modal'].show()
        },

        materialReplacementModal(url) {
            this.modalMaterialReplacementId = url.formulaMaterialLink
            this.modalMaterialReplacementName = url.tradeName.name
            this.$refs['material-replacement-modal'].show()
        },

        async setNewMaterial(materialSelected) {

            let material = await axios.get(materialSelected)

            //get the price
            let materialDistributors = await axios.get(materialSelected + "/materialDistributors")

            //update the inci
            let materialIngredients = await axios.get(material.data._links.materialIngredients.href)
            let inciMaterial = []

            //test if material have ingredient
            if (materialIngredients.data._embedded.materialIngredient.length == 0) {
                let inci = "no ingredient"
                inciMaterial.push(inci)
            } else {
                for (let j = 0; j < materialIngredients.data._embedded.materialIngredient.length; j++) {
                    let ingredient = await axios.get(materialIngredients.data._embedded.materialIngredient[j]._links.ingredient.href)
                    let incis = await axios.get(ingredient.data._links.incis.href)
                    //test if ingredient have INCI
                    if (incis.data._embedded.inci.length > 0) {
                        let inci = incis.data._embedded.inci[0].inciName
                        inciMaterial.push(inci)
                    } else {
                        let inci = "no inci found"
                        inciMaterial.push(inci)
                    }
                }
            }

            //get materialOrganic
            let materialOrganic = await axios.get(materialSelected + "/materialOrganic")


            let item = {
                id: Math.random() * Math.random(),
                phase: "Undefined",
                prefixId: material.data.prefixId,
                tradeName: {
                    name: material.data.tradeName,
                    url: material.data._links.material.href
                },
                inci: inciMaterial,
                ingredient: [],
                blacklistedIngredients: [],
                percentage: "0",
                qsp: this.gridFormulationData.length == 0 ? true : false,
                status: material.data.status,
                price: materialDistributors.data._embedded.materialDistributor[0].price,
                formulaMaterialLink: "",
                conformity: 1,
                conformityIssue: [],
                materialOrganic: materialOrganic.data
            }

            this.gridFormulationData.push(item)

            this.saveMaterial(item)

            this.sortBy = "phase";
            this.sortDesc = false;

        },

        async getData() {

            this.gridFormulationData = [];

            this.loading = true;

            let response = await axios.get('formula/' + this._props.formulaId);
            this.formula = response.data;
            var regulationArray = this._props.regulationSelected.split('/')
            var regulationId = regulationArray[regulationArray.length - 1]

            // get the material related to this formulation
            try {
                let formulaMaterials = await axios.get('formula/' + this._props.formulaId + '/materialGrid/' + regulationId);

                // for each material
                for (let i = 0; i < formulaMaterials.data.length; i++) {

                    let formulaMaterialLink = "/formulaMaterial/" + formulaMaterials.data[i].formulaMaterialId;
                    let percentage = formulaMaterials.data[i].percentage;
                    let phase = formulaMaterials.data[i].phase;
                    let qsp = formulaMaterials.data[i].qsp
                    let price = formulaMaterials.data[i].price
                    let inciMaterial = formulaMaterials.data[i].inci
                    let ingredient = formulaMaterials.data[i].ingredient
                    let materialLink = "/material/" + formulaMaterials.data[i].materialId
                    let prefixId = formulaMaterials.data[i].prefixId
                    let tradeName = formulaMaterials.data[i].materialName
                    let status = formulaMaterials.data[i].status
                    let materialOrganic = formulaMaterials.data[i].materialOrganic

                    let item = {
                        id: Math.random() * Math.random(),
                        phase: phase,
                        prefixId: prefixId,
                        tradeName: {
                            name: tradeName,
                            url: materialLink
                        },
                        inci: inciMaterial,
                        ingredient: ingredient,
                        blacklistedIngredients: [],
                        percentage: percentage,
                        qsp: qsp,
                        status: status,
                        price: price,
                        formulaMaterialLink: formulaMaterialLink,
                        conformity: 1,
                        conformityIssue: [],
                        materialOrganic: materialOrganic
                    }

                    this.gridFormulationData.push(item)

                    this.isIngredientBlacklisted(item)
                }

                this.checkConformity();
                this.gridUpdated();

                this.loading = false;
            } catch {
                this.loading = false;
            }
        
        },

        async addNewRow() {

            //add a new line
            if (this.gridFormulationData.length == 0) {
                this.gridFormulationData.push({
                    id: Math.random() * Math.random(),
                    phase: "Undefined",
                    prefixId: "",
                    tradeName: {
                        name: "",
                        url: ""
                    },
                    inci: "",
                    ingredient: [],
                    blacklistedIngredients: [],
                    percentage: 100,
                    qsp: true,
                    status: "RESTRICTED",
                    price: "",
                    formulaMaterialLink: "",
                    conformity: 1,
                    conformityIssue: [],
                    materialOrganic: {}
                });
            } else {
                this.gridFormulationData.push({
                    id: Math.random() * Math.random(),
                    phase: "Undefined",
                    prefixId: "",
                    tradeName: {
                        name: "",
                        url: ""
                    },
                    inci: "",
                    ingredient: [],
                    blacklistedIngredients: [],
                    percentage: 0,
                    qsp: false,
                    status: "RESTRICTED",
                    price: "",
                    formulaMaterialLink: "",
                    conformity: 1,
                    conformityIssue: [],
                    materialOrganic: {},
                })
            }

        },

        deleteRow(item) {
            let index = this.gridFormulationData.indexOf(item);

            //delete row at table and post delete method to api
            if (this.$route.params.id && item.formulaMaterialLink != "") {
                let urlDelete = this.gridFormulationData[index].formulaMaterialLink;
                axios.delete(urlDelete)
                    .then(() => {
                        this.gridFormulationData.splice(index, 1);
                        this.succesAlert();
                        this.calculQsp(null);
                    })
                    .catch((error) => {
                        console.log(error)
                        this.errorAlert(error)
                    })

                //if it is new line (never save) just remove the table row
            } else {
                this.gridFormulationData.splice(index, 1);
                this.calculQsp(null);
            }
        },

        async getMaterials(item) {

            if (item.tradeName.name.length > 1) {
                var filter = "/search/findByTradeNameContainingIgnoreCaseOrderByTradeName";

                var paramValue = {
                    "tradeName": item.tradeName.name,
                    "size": 10
                };

                let responseMaterials = await axios.get("material" + filter, {
                    params: paramValue
                })
                this.materials = responseMaterials.data._embedded.material;

            }

            if (this.materials.length > 0) {
                return true
            } else {
                return false
            }

        },

        async saveMaterial(item) {

            //do not save if the user didn't inform a material
            if (item.tradeName.name != "") {
                //update the url of item
                await this.getMaterials(item)

                try {
                    item.tradeName.url = this.materials.filter(material => material.tradeName == item.tradeName.name)[0]._links.self.href;
                } catch {
                    let error = {
                        response: {
                            data: {
                                content: 'This material doesn\'t exist'
                            }
                        }
                    }
                    this.errorAlert(error)
                    return
                }

                //if it is not a new register we need to save it
                if (this.$route.params.id) {
                    //organize data
                    let rowComposition = {
                        percentage: item.percentage,
                        phase: item.phase,
                        qsp: item.qsp,
                        formula: "formula/" + this.$route.params.id,
                        material: item.tradeName.url
                    };

                    //perform a post because it's a new line
                    if (item.formulaMaterialLink == "") {
                        try {
                            let response = await axios.post('formulaMaterial', rowComposition);
                            item.formulaMaterialLink = response.data._links.self.href
                            this.tradeNameSelected(item)
                            this.succesAlert();
                        } catch (error) {
                            this.errorAlert(error)
                        }
                    } else {
                        //get the link of the material on server
                        let formulaMaterial = await axios.get(item.formulaMaterialLink);
                        let serverMaterial = await axios.get(formulaMaterial.data._links.material.href)
                        let serverMaterialLink = serverMaterial.data._links.self.href

                        //if the material is the same it's a put (the user update the %, phase or qsp)
                        //if not delete the old relationship and post a new one
                        if (item.tradeName.url == serverMaterialLink) {
                            axios
                                .put(item.formulaMaterialLink, rowComposition)
                                .then(() => {
                                    this.succesAlert();
                                })
                                .catch((error) => {
                                    this.errorAlert(error)
                                })
                        } else {
                            //delete the relationship
                            await axios.delete(item.formulaMaterialLink, {
                                headers: {
                                    "X-HTTP-Method-Override": "DELETE"
                                }
                            });
                            //post a new one and update this.composition
                            axios
                                .post('formulaMaterial', rowComposition)
                                .then((response) => {
                                    item.formulaMaterialLink = response.data._links.self.href
                                    this.tradeNameSelected(item)
                                    this.succesAlert();
                                })
                                .catch((error) => {
                                    this.errorAlert(error)
                                })
                        }
                    }
                    this.checkConformity();
                }

                this.gridUpdated();

            }

        },

        async tradeNameSelected(item) {

            // if it's a new register update the url of the material
            if (!this.$route.params.id) {
                item.tradeName.url = this.materials.filter(material => material.tradeName == item.tradeName.name)[0]._links.self.href;
            }

            if (item.tradeName.url != "") {
                let material = await axios.get(item.tradeName.url)
                let prefixId = material.data.prefixId

                //update the prefixID of the material
                let index = this.gridFormulationData.indexOf(item)
                this.gridFormulationData[index].prefixId = prefixId

                //update the status
                this.gridFormulationData[index].status = material.data.status

                //update the price
                let materialDistributors = await axios.get(item.tradeName.url + "/materialDistributors")
                //if the material have no price or distributor
                if (materialDistributors.data._embedded.materialDistributor.length > 0) {
                    let price = materialDistributors.data._embedded.materialDistributor[0].price
                    this.gridFormulationData[index].price = price
                } else {
                    this.gridFormulationData[index].price = 0
                }

                //update the inci
                let materialIngredients = await axios.get(material.data._links.materialIngredients.href)
                let inciMaterial = []
                let ingredients = []
                //test if material have ingredient
                if (materialIngredients.data._embedded.materialIngredient.length == 0) {
                    let inci = "no ingredient"
                    inciMaterial.push(inci)
                } else {
                    for (let j = 0; j < materialIngredients.data._embedded.materialIngredient.length; j++) {
                        // let ingredient = await axios.get(materialIngredients.data._embedded.materialIngredient[j]._links.ingredient.href)

                        let ingredientResponse = await axios.get(materialIngredients.data._embedded.materialIngredient[j]._links.ingredient.href)
                        ingredients.push(ingredientResponse.data.name)

                        let incis = await axios.get(ingredientResponse.data._links.incis.href)
                        //test if ingredient have INCI
                        if (incis.data._embedded.inci.length > 0) {
                            let inci = incis.data._embedded.inci[0].inciName
                            inciMaterial.push(inci)
                        } else {
                            let inci = "no inci found"
                            inciMaterial.push(inci)
                        }
                    }
                }
                this.gridFormulationData[index].ingredient = ingredients
                this.gridFormulationData[index].inci = inciMaterial

                //update materialOrganic
                let materialOrganic = await axios.get(item.tradeName.url + "/materialOrganic")
                this.gridFormulationData[index].materialOrganic = materialOrganic.data

                this.isIngredientBlacklisted(item);
            }

        },

        isIngredientBlacklisted(item) {

            item.blacklistedIngredients = []

            for (let i = 0; i < item.inci.length; i++) {
                let response = this._props.projectBlacklist.filter(ingredient => ingredient.name == item.ingredient[i]);
                if (response.length != 0) {
                    item.blacklistedIngredients.push(response[0].name)
                }
            }

        },

        calculQsp(item) {

            //if we calcul a qsp this mean that the % of item has change so we need to save it
            if (item != null) {
                let x = parseFloat(item.percentage)
                if (x > 100) {
                    item.percentage = 100
                } else if (isNaN(x)) {
                    item.percentage = 0
                } else {
                    item.percentage = Math.abs(x)
                }

                this.saveMaterial(item)
            }

            let totalNonQsp = 0;
            for (let i = 0; i < this.gridFormulationData.length; i++) {
                if (this.gridFormulationData[i].qsp == false) {
                    totalNonQsp += this.gridFormulationData[i].percentage;
                }
            }

            let qsp = (100 - totalNonQsp);

            if (qsp < 0) {
                this.$bvModal.msgBoxOk('QSP will be more than 100% Please edit you percentage')
                return;
            }

            for (let i = 0; i < this.gridFormulationData.length; i++) {
                if (this.gridFormulationData[i].qsp == true) {
                    this.gridFormulationData[i].percentage = qsp;
                    //we need to save the new %
                    this.saveMaterial(this.gridFormulationData[i]);
                    break
                }
            }

        },

        selectedQsp(item) {

            // look for the old qsp and save it as false
            for (let i = 0; i < this.gridFormulationData.length; i++) {
                if (this.gridFormulationData[i].qsp == true) {
                    this.gridFormulationData[i].qsp = false;
                    this.saveMaterial(this.gridFormulationData[i])
                }
            }
            // set the new qsp to true
            item.qsp = true;
            this.calculQsp(item);
        },

        async checkConformity() {
            let conformityData = await axios.get('formula/' + this.$route.params.id + '/checkConformityGetMaterial/0');

            //for each material check if there is any issues with ingredients
            for (let i = 0; i < this.gridFormulationData.length; i++) {
                var materialIdArray = this.gridFormulationData[i].tradeName.url.split('/');
                var materialId = materialIdArray[materialIdArray.length - 1];
                this.gridFormulationData[i].conformity = "";
                this.gridFormulationData[i].conformityIssue = [];
                for (let j = 0; j < conformityData.data.length; j++) {
                    if (materialId == conformityData.data[j].materialId) {
                        this.gridFormulationData[i].conformity = conformityData.data[j].conformityStatus;
                        for (let k = 0; k < conformityData.data[j].ingredients.length; k++) {
                            this.gridFormulationData[i].conformityIssue.push(conformityData.data[j].ingredients[k]);
                        }
                    }
                }
            }
        },

        closeMaterialReplacementModal(){
            this.$bvModal.hide('material-replacement-modal')
            this.getData()
        }
    },
    created() {
        this.$root.$refs.FormulationGrid = this;
    }
};
</script>

<style scoped lang='scss'>
.red {
    color: crimson;
}

.green {
    color: green;
}

.yellow {
    color: rgb(207, 207, 0);
}

.gray {
    color: gray;
}

.input-group-text {
    padding: 0.375rem 0.1rem !important;
}

.input-formulation-table-center {
    text-align: center;
}

.prefixIdClass {
    width: 7%;
}

.prefixIDColClass {
    padding-right: 0px !important;

}

.inputPrefixId {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important
}

.tradeNameColClass {
    padding-left: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.form-group {
    text-align: left;
    font-weight: bold;
}

.inputTradeName {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    background-color: var(--input-text-background) !important;
}

.percentageClass {
    width: 3%;
}

.border-append {
    border: 1px solid #ced4da !important;
}

.border-append-round-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.no-bullet {
    list-style-type: none;
    display:inline;
}
</style>
