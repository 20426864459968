<template>
<div v-can="'Read_Product'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-left">{{$tc('general.product', 2)}}</h1>
        <MainGrid gridTitle="Products" objectNodeName="entityModels" endpoint="product" :gridColumns="columns" :filter="filter" formUrl="/product" :queryByFilter="true" :customOrderBy="true" deletedataToDisplay="productName" :deleteAssociatedData="$store.state.product.associatedData"></MainGrid>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MainGrid from '../components/MainGrid'

export default {
  name: 'Products',
  components: {
    MainGrid
  },
  data() {
    return {
      title: 'Products',
      columns: [
        {
          label: this.$t('product.productName'),
          key: "productName",
          sortable: true,
          initial_sort: true
        },
        {
          label: this.$t('general.clientName'),
          key: "clientName",
          sortable: true,
          initial_sort: false
        },
        {
          label: this.$t('product.targetDate'),
          key: "targetDateString",
          sortable: true,
          initial_sort: false,
          slot_name: "date"
        },
        {
          label: this.$t('general.status'),
          key: "status",
          sortable: true,
          initial_sort: false,
        },
        {
          label: this.$t('formulation.formulationName'),
          key: "formulaName",
          sortable: true,
          initial_sort: false
        },
        {
          key: "actionButtons",
          label: "",
        }
      ],
      filter: [{
        method: "findByProductNameOrClientNameOrTargetDateOrStatusOrFormula",
        params: ["searchValue"]
      }]
    }
  }
}
</script>

<style>
.btn-icon{
  cursor: pointer;
  margin: 0px 5px;
}

</style>
