<template>
<div :id="accordionName">

    <div class="card mb-4 bg-background">

        <div class="card-header" @click.prevent="accordionToggle" data-toggle="collapse" data-target="accordionTarget">
            <h5 ref="title" class="float-left my-auto">
                {{title}}
            </h5>

            <span class="float-left ml-2" @click.prevent="accordionToggle">
                <slot name="header-options"></slot>
            </span>

            <span class="float-right" v-show="!this.accordionActive">
                <font-awesome-icon class="btn-icon" icon="chevron-left" /></span>
            <span class="float-right" v-show="this.accordionActive">
                <font-awesome-icon class="btn-icon" icon="chevron-down" /></span>
        </div>

        <div id="accordionTarget" class="collapse show" v-show="this.accordionActive">
            <div class="card-body">
                <slot>
                    <!-- The content will be here -->
                </slot>
            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'LayoutCard',
    data() {
        return {
            accordionActive: null
        }
    },
    props: {
        title: {
            required: true
        },
        active: {
            default: false
        }
    },
    computed: {
        //this allow to have multiple instance of the component of the same page by generating the id of the accordion
        accordionName() {
            return this.title.toLowerCase().replace(/ /g, '-');
        },
        //this allow to have multiple instance of the component of the same page by generating the id of the targeted body
        accordionTarget() {
            return '#target' + this.title.toLowerCase().replace(/ /g, '-');
        },
    },
    methods: {
        // asign the value of the props to data to avoid mutation of props
        assignPropsToData() {
            this.accordionActive = this.active;
        },
        accordionToggle() {
            this.accordionActive = !this.accordionActive;
        }
    },
    // require to avoid mutation of props. lauch assignPropsToData() at the creation of component
    created() {
        this.assignPropsToData();
    }
}
</script>

<style scoped lang="scss">
.btn {
    margin: 10px 10px;
}

.card {
    border: 1px solid var(--header-background)
}

.card-header {
    padding: 10px 20px;
    background-color: var(--header-background);
    color: var(--header-text);
}
</style>
