export default {

  data() {
    return {
      dismissSecs: 2,
      dismissCountDown: 0,
      alertType: "",

      feedbackMessage: "",
      }
    },

  methods: {
    countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    errorAlert(error){
      if(Object.prototype.hasOwnProperty.call(error.response.data, "content")){
        this.feedbackMessage = error.response.data.content.toString();
      } else {
        this.feedbackMessage = "API error";
      }
      this.alertType = "danger";
      this.showAlert();
    },

    succesAlert(){
      this.feedbackMessage = "Data saved successfully";
      this.alertType = "success";
      this.showAlert();
    },

    printAlert(){
      this.feedbackMessage = this.$t('general.pdfSaved');
      this.alertType = "success";
      this.showAlert();
    }
  }
};
