import Vuex from 'vuex'
import Vue from 'vue'

import auth from './modules/auth';
import status from './modules/status';
import {version} from '../../package'
import {date} from '../../package'

Vue.use(Vuex)

export default new Vuex.Store({

    modules: {
        auth,
        status
    },

    state: {

        galenitVersion: version,
        galenitDate: date,

        contact: {
            associatedData: [{
                    endpoint: "client",
                    filter: "/search/findByClientCountryContact",
                    result: []
                },
                {
                    endpoint: "distributor",
                    filter: "/search/findByDistributorNameOrSupplierNameOrContactFirstNameOrContactLastName",
                    result: []
                },
                {
                    endpoint: "supplier",
                    filter: "/search/findBySupplierDistributorContact",
                    result: []
                }
            ],
        },

        client: {
            associatedData: [{
                endpoint: "project",
                filter: "",
                result: []
            }, ]
        },

        distributor: {
            associatedData: [{
                    endpoint: "materialDistributor",
                    filter: "",
                    result: []
                },
                {
                    endpoint: "supplier",
                    filter: "/search/findBySupplierDistributorContact",
                    result: []
                },
            ],
        },

        supplier: {
            associatedData: [{
                endpoint: "material",
                filter: "",
                result: []
            }, ],
        },

        ingredient: {
            associatedData: [{
                endpoint: "materialIngredient",
                filter: "",
                result: []
            }, ],

            endpointToDelete: [{
                    endpoint: "incis",
                    embeddedName: "inci"
                },
                // {
                //     endpoint: "ingredientItemsOfFormulation",
                //     embeddedName: "ingredientItemOfFormulation"
                // },
            ],
        },

        material: {
            associatedData: [{
                endpoint: "formulaMaterial",
                filter: "",
                result: []
            }, ],

            endpointToDelete: [{
                    endpoint: "materialIngredients",
                    embeddedName: "materialIngredient"
                },
                {
                    endpoint: "materialDistributors",
                    embeddedName: "materialDistributor"
                }
            ],
        },

        project: {
            associatedData: [{
                endpoint: "product",
                filter: "",
                result: []
            }, ],
        },

        product: {
            associatedData: [{
                endpoint: "formula",
                filter: "",
                result: []
            }, ],
        },

        formulation: {
            associatedData: [{
                endpoint: "formulaTest",
                filter: "",
                result: []
            }, ],

            endpointToDelete: [{
                    endpoint: "formulaMaterials",
                    embeddedName: "formulaMaterial"
                },
                {
                    endpoint: "ingredientItemsOfFormulation",
                    embeddedName: "ingredientItemOfFormulation"
                },
            ],
        },
        test:{
            endpointToDelete: [
            ],
        }
    },

    getters: {
        appVersion: (state) => {
           return state.galenitVersion
         },

        appDate: (state) => {
            return state.galenitDate
          }
    },

    mutations: {

    },

    actions: {

    }
});
