<template>
<div v-can="'Read_Distributor'">

  <div class="container-fluid">
    <div class="row">

      <div class="col-md-12">
        <h1 class="text-left">{{$tc('general.distributor', 2)}}</h1>
        <MainGrid gridTitle="Distributor" objectNodeName="entityModels" endpoint="distributor" :gridColumns="columns" :filter="filter" formUrl="/distributor" :queryByFilter="true" :customOrderBy="true" deletedataToDisplay="distributorName" deleteSearchValue="distributorName" :deleteAssociatedData="$store.state.distributor.associatedData"></MainGrid>
      </div>

    </div>
  </div>

</div>
</template>

<script>
import MainGrid from '../components/MainGrid'

export default {
  name: 'Distributors',

  components: {
    MainGrid
  },
  data() {
    return {
      title: "Distributors",

      columns: [{
          label: this.$t('distributor.companyName'),
          key: "distributorName",
          sortable: true,
          initial_sort: false
        },
        {
          label: this.$t('supplier.supplierName'),
          key: "supplier[0].supplierName",
          sortable: true,
        },
        {
          label: this.$t('contact.firstname'),
          key: "contact[0].firstName",
          sortable: true,
        },
        {
          label: this.$t('contact.lastName'),
          key: "contact[0].lastName",
          sortable: true,
        },
        {
          key: "actionButtons",
          label: "",
        }
      ],
      filter: [{
        method: "findByDistributorNameOrSupplierNameOrContactFirstNameOrContactLastName",
        params: ["searchValue"]
      }]
    }
  },
  mounted() {
  }
};
</script>

<style>

</style>
