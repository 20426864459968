<template>
<div>
    <multiselect ref='multiselect' v-can:multiselect="_props.permission" v-model="value" :options="options" :hide-selected="true" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" :placeholder="$t('material.selectFamilies')"
        label="name" track-by="name" :preselect-first="false" :taggable="false" :loading="this.loading" @search-change="searchFamily($event)" @select="onSelect($event)" @remove="onRemove($event)">
    </multiselect>
</div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

window.axios = require("axios");

const LIST_SIZE = 300
const LIST_SORT = 'name, asc'

export default {
    name: 'FamilyList',
    components: {
        Multiselect
    },

    props: {
        savedFamilies: {
            type: Array,
            required: true
        },
        permission:{
            type: String,
        }
    },
    data() {
        return {
            value: [],
            options: [],
            loading: true,
        }
    },
    methods: {
        onSelect(data){
            this.$emit('newMaterialFamily', data)
        },

        onRemove(data){
            this.$emit('removeMaterialFamily', data)
        },


        loadMaterialFamily() {
            for (let i = 0; i < this._props.savedFamilies.length; i++) {
              let id = this._props.savedFamilies[i].familyLink.split("/").pop();
              axios
                .get('family/' + id)
                .then((response) => {
                  this.value.push(response.data)
                })
                .catch((error) => {
                  console.log(error.response)
                })
            }
        },

        searchFamily(query){
            this.loading = true
            var filter = "/search/findByNameContainingIgnoreCase";
            var paramValue = {
              "name": query,
            };
            axios
              .get("family" + filter, {
                params: paramValue
              })
              .then((response) => {
                  this.options = response.data._embedded.family;
                  this.loading = false
              })
              .catch((error) => {
                console.log(error.response)
              })
        },

        async getMaterialFamilyList() {
            axios
            .get("family", {
                params: {
                    size: LIST_SIZE,
                    sort: LIST_SORT
                }
            })
            .then((response) => {
                this.options = response.data._embedded.family;
                this.loading = false
            })
            .catch((error) => {
              console.log(error.response)
            })
        },
    },

    mounted() {

        this.getMaterialFamilyList();

        this.loadMaterialFamily();

    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
