<template>
<div>
  <div class="d-flex flex-row-reverse mb-2">
    <font-awesome-icon v-can="_props.permission" icon="minus-circle" class="clickable" @click="removeColumn" />
    <font-awesome-icon v-can="_props.permission" icon="plus-circle" class="clickable mr-2" @click="addColumn" />
  </div>

  <table class="table table-sm table-bordered">
    <tbody>
      <tr v-for="(row, idx1) in items" :key="idx1">
        <td v-for="(col, idx2) in row" :key="idx2">
          <b-form-textarea v-can:input="_props.permission" rows="2" v-if="idx1== 0" @change="edited" type="text" size="sm" class="form-control font-weight-bold" v-model="items[idx1][idx2]" />
          <b-form-textarea v-can:input="_props.permission" rows="2" v-else-if="idx2 == 0" @change="edited" type="text" size="sm" class="form-control font-weight-bold" v-model="items[idx1][idx2]" />
          <b-form-textarea v-can:input="_props.permission" rows="2" v-else-if="items[idx1][idx2] == '--'" @change="edited" type="text" size="sm" class="form-control bg-secondary" v-model="items[idx1][idx2]" />
          <b-form-textarea v-can:input="_props.permission" rows="2" v-else-if="items[idx1][idx2] == 'OK'" @change="edited" type="text" size="sm" class="form-control text-success" v-model="items[idx1][idx2]" />
          <b-form-textarea v-can:input="_props.permission" rows="2" v-else-if="items[idx1][idx2] == 'NOK'" @change="edited" type="text" size="sm" class="form-control text-danger" v-model="items[idx1][idx2]" />
          <b-form-textarea v-can:input="_props.permission" rows="2" v-else @change="edited" type="text" size="sm" class="form-control" v-model="items[idx1][idx2]" />
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex flex-row">
    <font-awesome-icon v-can="_props.permission" icon="plus-circle" class="clickable mr-2" @click="addRow" />
    <font-awesome-icon v-can="_props.permission" icon="minus-circle" class="clickable" @click="removeRow" />
  </div>

  <div v-if="$route.name != 'Formulation'" class="d-flex flex-row mt-2">
    <p v-can="_props.permission">
      <span style="color: green" class="mr-2">OK : will be green;</span>
      <span style="color: red" class="mr-2">NOK : will be red;</span>
      <span>Use "--" to disable cell</span>
    </p>
  </div>
</div>
</template>

<script>

export default {
  name: 'EditableGridJson',
  components: {

  },

  props: {
    array: {
      type: Array,
      required: true
    },
    permission:{
        type: String,
    }
  },
  data() {
    return {
      items: this._props.array
    }
  },
  methods: {
    addRow() {
      if (this.items[0] == undefined || this.items[0].length == 0) {
        this.items = [
          ['']
        ]
      } else {
        this.items.push(new Array(this.items[0].length))
      }
    },

    removeRow() {
      this.items.splice(this.items.length - 1, 1)
      this.edited()
    },

    addColumn() {
      if (this.items[0] == undefined || this.items[0][0] == undefined) {
        this.items = [
          ['']
        ]
      } else {
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].push('')
        }
      }
    },
    removeColumn() {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].splice(this.items[i].length - 1, 1)
      }
      if (this.items[0].length == 0) {
        this.items = []
      }
      this.edited()
    },

    edited() {
      this.$emit('update:value', this.items)
      this.$emit('edited')
    },


  },

  mounted() {


  }
};
</script>
<style>
</style>
