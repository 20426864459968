import axios from 'axios'
export default {

  data() {
    return {
      loading: true,
      loaded: false,
      isMod: false,

      saveBtnClicked: false

    }
  },

  methods: {
    getEdited() {
      this.isMod = true;
    },

    getClicked(clickedValue) {
      if (clickedValue == 'save') {
        this.saveBtnClicked = true;
        this.save();
        this.saveBtnClicked = false;

      }
      if (clickedValue == 'cancel') {
        location.reload()
      }
      if (clickedValue == 'delete') {
        //delete later
      }
    },

    save() {
      // check is the form contain error
      if (this.$route.params.id || this.saveBtnClicked == true) {
        this.$v.$touch();
        if (this.$v.$anyError) {
          return;
        }
      }

      if (this.$route.params.id) {
        let data = this.processForm();
        let endpoint = this.endpoint + '/' + this.$route.params.id;
        this.putData(endpoint, data);
      } else if (this.saveBtnClicked == true) {
        let data = this.processForm();
        this.postData(this.endpoint, data)
      } else {
        this.isMod = true
      }

    },
    // this function is to set the state of the vue-boostrap input
    validateState(field) {
      const {
        $dirty,
        $error
      } = field;
      return $dirty ? !$error : null;
    },

    validateStatus(field) {
        if (field.required && field.$dirty) {
            return 'vselect-valid'
        } else if (!field.required && field.$dirty) {
            return 'vselect-invalid'
        } else {
            return ""
        }
    },

    validatePrefix(field){
      if (field.isPrefixSelected && field.$dirty) {
          return 'vselect-valid'
      } else if (!field.required && field.$dirty) {
          return 'vselect-invalid'
      } else {
          return ""
      }
    },

    exportCvs(url) {
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = response.headers["content-disposition"].split("\"")[1]
        document.body.appendChild(link);
        link.click();
      });
    },
  }
};
