<template>
  <div style="text-align:left">
    <h3>File Manager</h3>
    <p>This is the file upload component that can be used in other parts of the system.</p>
    <p>It needs layout improvements.</p>
    <p>The "file listing" is in the same component, but a separate component can be created for the listing.</p>
    <br>
    <FileManager repositoryName="formulamock" :pathIdList="[1]"  acceptExtensions=".doc, .docx, .txt, .pdf" />
    <!-- pathIdList is a list of Ids of the entities those the file is related, usually will be filled by a dynamic way. -->
    <!-- Ex: Formula Id = 1  FormulaTest Id = 1  == [1, 1] -->
  </div>
</template>

<script>
import FileManager from '../components/FileManager'

export default {
    name: 'FileUploadMock',
    components: {
      FileManager
    },
    data() {
        return {

        };
    },
    methods: {

    },
};
</script>

<style scoped>

</style>