<template>
    <div style="text-align:left">
        <div v-can="'Create_FileManager'">
        <b-form-file v-can:file="_props.permission" :placeholder="$t('fileManager.placeholderDisplay')" :drop-placeholder="$t('fileManager.placeholderMessage')" @input="uploadFile" :accept="acceptExtensions" ref="file-input"></b-form-file>
            <!-- The "accept" attribute only filters the files to be listed by operating system window, but does not prevent files from being sent with another extension. -->
            <!-- However, the "accept" filters out drag and drop. If a file is dropped that is not in the "accept" list, nothing happens. -->
            <br>
            <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                    {{ feedbackMessage }}
                </b-alert>
            </div>
            <br>
        </div>
        <div>
            <ul class="list-unstyled row ml-2">
                <li class="list-item col-xl-6 col-lg-12 py-1" v-for="item in filesInRepository" :key="item.name">
                    <span v-if="item.name.length > 25" v-b-tooltip.hover="item.name"><a
                            :href="fullDownloadEndpoint(item.endpoint)" target="_blank">{{
                            item.name.substring(0,25)+'... .'+ item.name.split(".").pop() }}</a></span>
                    <span v-else><a :href="fullDownloadEndpoint(item.endpoint)" target="_blank">{{
                    item.name }}</a></span>
                    <span v-can="'Delete_FileManager'">
                        <font-awesome-icon class="btn-icon ml-3" @click="deleteFile(item.endpoint)" icon="trash-alt" />
                    </span>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import Feedback from '../mixins/Feedback.js'

window.axios = require("axios");

export default {
    name: 'FileManager',
    mixins: [Feedback],
    props: {
        repositoryName: {
            type: String,
            required: true,
            default: ""
        },
        pathIdList: {
            type: Array,
            required: true,
            default: () => []
        },
        acceptExtensions: {
            type: String,
            required: false,
            default: ""
        },
        permission: {
            type: String
        }
    },
    data() {
        return {
            filesInRepository: []
        };
    },
    mounted: function() {
        this.listFiles();
    },
    methods: {
        fullDownloadEndpoint(endpoint) {
            return axios.defaults.baseURL + endpoint;
        },
        deleteFile(endpoint) {
            let deleteEndpoint = axios.defaults.baseURL + endpoint.replace('download', 'delete');

            var _self = this;

            axios.delete(deleteEndpoint).then(function() {
                _self.succesAlert();
                _self.listFiles();
            }).catch(function(error) {
                _self.errorAlert(error);
            });
        },
        uploadFile(file) {
            var error = {
                response: {
                    data: {
                        content: ""
                    }
                }
            };

            if (this.repositoryName == "") {
                error.response.data.content = this.$t('fileManager.repositoryNameMessage');
                this.errorAlert(error);
                return;
            }

            if (this.pathIdList.length == 0) {
                error.response.data.content = this.$t('fileManager.pathIdListMessage');
                this.errorAlert(error);
                return;
            }

            if (file == undefined || file == null) {
                return;
            }

            //The backend has a file size block greater than 10MB, see appliction.properties configuration file.
            //However, this treatment is native to the framework and does not allow a friendly message to be returned to the front end.
            //Thus, to show a friendly message to the user, the treatment below must be carried out.
            if (file.size > 10000000) {
                error.response.data.content = this.$t('fileManager.fileSizeMessage');
                this.errorAlert(error);
                return;
            }

            //The backend has a block of extensions that are not allowed, see appliction.properties configuration file.
            //However, if you want to filter the incoming files extensions, you can use the "accept" attribute combined with the "if" below.

            //To ensure that the file of unwanted extension is not sent, treatment should be performed here.
            // var fileExtension = file.name.split('.')[1];
            // if(fileExtension not in list_Of_Forbidden_Extensions){
            //     this.errorAlert(error);
            //     return;
            // }

            var _self = this;

            var formData = new FormData();
            formData.append("file", file);
            formData.append("repositoryName", this.repositoryName);

            this.pathIdList.forEach(item => {
                formData.append("pathId", item);
            });

            axios.post(axios.defaults.baseURL + 'fileManager/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function() {
                //If you want do something with the file, it is possible use response.data. Else, we can remove this console.log.

                _self.$refs['file-input'].reset();
                _self.succesAlert();

                _self.listFiles();
            }).catch(function(error) {
                _self.errorAlert(error);
            });
        },
        listFiles() {

            let path = this.repositoryName;

            this.pathIdList.forEach(item => {
                path = path + "/" + item;
            });

            var _self = this;

            axios.get(axios.defaults.baseURL + '/fileManager/files/' + path, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(response) {
                _self.filesInRepository = response.data.content;
            }).catch(function(error) {
                console.log(error);
                _self.errorAlert(error);
            });
        },
    },
};
</script>

<style scoped>


</style>
