<template>
<div>

    <div class="form-row centered">

        <div class="form-group col-md-5">
            <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search" class="my-auto"></b-form-input>

                <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
            </b-input-group>
        </div>

        <div class="form-group col-md-4">
            <b-pagination v-model="currentPage" :total-rows="filteredRows == null ? totalRows : filteredRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
        </div>
        <div class="form-group col-md-2">
            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
        </div>

        <div class="form-group col-md-1 text-center">
            <button type="button" class="btn btn-outline-primary btn-sm" @click="showModal('distributor')">{{$t('general.add')}}</button>
        </div>

    </div>


    <b-table ref="distributorTable" responsive="sm" hover :items="distributors" :fields="distributorColumn" :busy.sync="isBusy" :filter="filter" @filtered="onFiltered" :no-provider-filtering="true" :no-provider-paging="true" :no-provider-sorting="true"
        :sort-by="sortBy" :sort-desc="false" :current-page="currentPage" :per-page="perPage">

        <template #cell(delete)="row">
            <font-awesome-icon class="btn-icon" @click="distributorModal(row.item)" icon="eye" />
            <font-awesome-icon v-can="'Delete_'+_props.endpoint.charAt(0).toUpperCase() + _props.endpoint.slice(1)+'Distributors'" @click="deleteDistributor(row.item)" class="btn-icon" icon="trash-alt" />
        </template>


    </b-table>

    <b-card v-if="distributors.length == 0">
        {{$t('general.noRecord')}}
    </b-card>

    <ModalWindow v-show="openedModal === 'distributor'" :title="$t('general.search')" @close="openedModal = null">
        <MainGrid gridTitle="Distributor" objectNodeName="entityModels" endpoint="distributor" :gridColumns="columnsMainGrid" :filter="filterMainGrid" formUrl="/distributor" :queryByFilter="true" :customOrderBy="true" @close="openedModal = null" @selectValue="setDistributor"></MainGrid>
    </ModalWindow>

    <b-modal ref="distributor-more-info-modal" size="xl" scrollable :title="$tc('general.distributor', 1)">
        <Distributor :distributorId="modalDistributorId"></Distributor>
        <template #modal-footer="{ ok }">
            <b-button variant="primary" @click="ok()">OK</b-button>
        </template>
    </b-modal>

</div>
</template>


<script>
import ModalWindow from '../components/ModalWindow.vue';
import MainGrid from '../components/MainGrid.vue';
import Distributor from '../views/Distributor.vue';
import axios from 'axios'
//
import Feedback from '../mixins/Feedback.js'

window.axios = require("axios");

export default {
    name: 'ListingDistributor',

    components: {
        ModalWindow,
        Distributor,
        MainGrid
    },

    mixins: [Feedback],

    data() {
        return {
            fields: this._props.column,

            sortBy: 'lastName',

            filter: null,

            isBusy: false,

            filteredRows: null,

            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],

            openedModal: null,
            modalDistributorId: null,

            distributorColumn: [{
                    key: 'distributorName',
                    label: this.$t('distributor.distributorName'),
                    sortable: true
                },
                {
                    key: 'contact[0].firstName',
                    label: this.$t('contact.firstname'),
                    sortable: true
                },
                {
                    key: 'contact[0].lastName',
                    label: this.$t('contact.lastName'),
                    sortable: true
                },
                {
                    key: 'contact[0].email',
                    label: this.$t('contact.email'),
                    sortable: true
                },
                {
                    key: 'contact[0].phoneNumber',
                    label: this.$t('contact.phoneNumber'),
                    sortable: true
                },
                {
                    key: 'delete',
                    label: "",
                    sortable: false
                },
            ],

            columnsMainGrid: [{
                label: this.$t('distributor.companyName'),
                key: "distributorName",
                sortable: true,
                initial_sort: false
              },
              {
                label: this.$t('supplier.supplierName'),
                key: "supplier[0].supplierName",
                sortable: true,
              },
              {
                label: this.$t('contact.firstname'),
                key: "contact[0].firstName",
                sortable: true,
              },
              {
                label: this.$t('contact.lastName'),
                key: "contact[0].lastName",
                sortable: true,
              },
              {
                key: "returnButton",
              }
            ],
            filterMainGrid: [{
              method: "findByDistributorNameOrSupplierNameOrContactFirstNameOrContactLastName",
              params: ["searchValue"]
            }]
        }
    },
    props: {
        distributors: {
            type: Array,
            required: true
        },
        nameToDisplay: {
            type: String,
            required: true
        },
        totalRows: {
            type: Number,
            required: true,
        },
        endpoint: {
            type: String,
            required: true
        }

    },
    methods: {

        async setDistributor(value) {

            if (this.$route.params.id) {
                let responseDistributors = await axios.get(this._props.endpoint + '/' + this.$route.params.id + '/distributors')

                //add if to detect is value is already in the contacts
                let distributorsLink = value

                for (let i = 0; i < responseDistributors.data._embedded.distributor.length; i++) {
                    distributorsLink = distributorsLink + '\n' + responseDistributors.data._embedded.distributor[i]._links.self.href
                }

                try {
                    axios.put(this._props.endpoint + '/' + this.$route.params.id + '/distributors', distributorsLink, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                    this.$emit('saved')

                    let responseDistributor = await axios.get(value)
                    this.localDistributors.push(responseDistributor.data)


                } catch (error) {
                    console.log(error);
                }
            } else {
                let reponseDistributor = await axios.get(value)
                this.localDistributors.push(reponseDistributor.data)
            }

        },

        deleteDistributor(item) {

            if (this.distributors.length == 1) {
                this.$bvModal.msgBoxOk(this.$t('general.cantDelete') + ' ' + this._props.nameToDisplay, {
                    title: this.$t('general.addNew'),
                })
                return
            }

            let index = this.distributors.indexOf(item);

            if (this.$route.params.id) {
                this.localDistributors.splice(index, 1)

                let distributorsLink

                for (let i = 0; i < this.distributors.length; i++) {
                    distributorsLink += this.distributors[i]._links.self.href + '\n'
                }

                if(distributorsLink == undefined){
                    distributorsLink = ""
                }

                try {
                    axios.put(this._props.endpoint + '/' + this.$route.params.id + '/distributors', distributorsLink, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                    this.$emit('saved')

                } catch (error) {
                    console.log(error);
                }
            } else {
                this.localDistributors.splice(index, 1)
            }

        },

        showModal(type) {
            this.openedModal = type
        },

        distributorModal(url) {
            this.modalDistributorId = url._links.self.href.split('/').pop()
            this.$refs['distributor-more-info-modal'].show()
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.filteredRows = filteredItems.length
            this.currentPage = 1
        },

    },

    mounted() {

    },

    computed: {
        localDistributors: {
            get() {
                return this.distributors
            },
            set(newValue) {
                this.$emit('update:distributors', newValue)
            },
        }
    }

};
</script>

<style lang="css" scoped>

.btn-icon {
  cursor: pointer;
  margin: 0px 5px;
}

</style>
