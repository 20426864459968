<template>
<div>

  <p class="text-left" ref="creation">
    This {{dataType}} was created by <em>{{addBy}}</em> on <em>{{addDate | moment("LLLL")}}</em>
  </p>
  <p class="text-left" ref="not-modified" v-if="lastMod == null">
    This {{dataType}} was never modified
  </p>
  <p class="text-left" ref="modified" v-else>
    This {{dataType}} was last modified by <em>{{lastModBy}}</em> on <em>{{lastMod | moment("LLLL")}}</em>
  </p>
</div>


</template>

<script>

export default {
  name: 'History',
  data() {
    return {
    }
  },
  props: {
    dataType: {
      type: String,
      required: true
    },
    addBy: {
      type: String,
      required: true
    },
    addDate: {
      type: String,
      required: true
    },
    lastModBy: {
      type: String,
      required: true
    },

    lastMod:{

      required: true
    }
  },
  methods: {

  },
  computed: {

  },
}
</script>



<style scoped>


</style>
