<template>
<div>

    <b-dropdown :variant="blacklistedIngredientsList.length !=0 || missingWhitelistIngredient.length !=0 ? 'outline-danger':'outline-primary'" size="sm" text="Validation" right class="m-2">
        <b-dropdown-form>
            <b-dropdown-group :header="$t('project.blacklist')">
                <b-dropdown-text v-if="blacklistedIngredientsList.length != 0" style="width: 240px;">{{$t('formulation.pleaseRemove')}}</b-dropdown-text>
                <b-dropdown-text v-else style="width: 300px;">{{$t('formulation.noBlacklist')}}</b-dropdown-text>
                <ul id="blacklist-ing-list">
                    <li v-for="(item, id) in blacklistedIngredientsList" :key="'black-ing-'+id">
                        <span class="text-danger">{{ item }}</span>
                    </li>
                </ul>
            </b-dropdown-group>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-group :header="$t('project.whitelist')">
                <b-dropdown-text v-if="missingWhitelistIngredient.length != 0">{{$t('formulation.pleaseAdd')}}</b-dropdown-text>
                <b-dropdown-text v-else style="width: 300px;">{{$t('formulation.whitelistInclude')}}</b-dropdown-text>
                <ul id="missing-white-list">
                    <li v-for="(item, id) in missingWhitelistIngredient" :key="'white-ing-'+id">
                        <span class="text-danger">{{ item.name }}</span>
                    </li>
                </ul>
            </b-dropdown-group>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-group :header="$tc('general.regulation', 1)">
                <b-dropdown-text>No issue found</b-dropdown-text>
            </b-dropdown-group>

        </b-dropdown-form>
    </b-dropdown>

</div>
</template>

<script>


export default {
    name: 'DropdownValidation',
    mixins: [],
    props: {
        gridFormulationData: {
            type: Array,
            required: true
        },
        projectBlacklist: {
            type: Array,
            required: true
        },
        projectWhitelist: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            loading: true,

        }

    },
    computed: {
        blacklistedIngredientsList() {

            let foundIngredients = []

            for (let i = 0; i < this.gridFormulationData.length; i++) {
                for (let j = 0; j < this.gridFormulationData[i].ingredient.length; j++) {
                    let response = this.projectBlacklist.filter(ingredient => ingredient.name == this.gridFormulationData[i].ingredient[j]);
                    if (response.length != 0) {
                        foundIngredients.push(this.gridFormulationData[i].ingredient[j])
                    }
                }
            }

            return [...new Set(foundIngredients)]

        },

        missingWhitelistIngredient() {

            let foundWhitelistIngredient = []

            for (let i = 0; i < this.gridFormulationData.length; i++) {
                for (let j = 0; j < this.gridFormulationData[i].ingredient.length; j++) {
                    let response = this.projectWhitelist.filter(ingredient => ingredient.name == this.gridFormulationData[i].ingredient[j]);
                    if (response.length != 0) {
                        foundWhitelistIngredient.push(this.gridFormulationData[i].ingredient[j])
                    }
                }
            }
            return this.projectWhitelist.filter(ingredient => !foundWhitelistIngredient.includes(ingredient.name))

        },

    },
    mounted: function() {


    },
    methods: {


    },
};
</script>

<style scoped>


</style>
