<template>
<div v-can="'Read_FormulaTestType'">

    <MainGrid :gridTitle="$t('tests.testType')" objectNodeName="formulaTestType" endpoint="formulaTestType" :gridColumns="columns" :filter="filter" formUrl="/setup-test-type" deletedataToDisplay="name"></MainGrid>

</div>
</template>

<script>
import MainGrid from '../components/MainGrid'
import Feedback from '../mixins/Feedback.js'

export default {
    name: 'SetupTemplateTestsType',

    mixins: [Feedback],

    components: {
        MainGrid,
    },
    data() {
        return {
            columns: [{
                    label: this.$t('tests.testType'),
                    key: "name",
                    sortable: true,
                },
                {
                    key: "actionButtons",
                    label: ""
                }
            ],
            filter: [{
                method: "findByNameContainingIgnoreCase",
                params: ["name"]
            }],

            selectedItem: {}

        }
    },
    methods: {

    }
}
</script>

<style>
.btn-icon {
    cursor: pointer;
    margin: 0px 5px;
}
</style>
