<template>
<div v-can="'Read_InciAuxiliary'">

    <MainGrid :gridTitle="$t('ingredient.nomenclature')" objectNodeName="formulaPrefix" endpoint="formulaPrefix" :gridColumns="prefixColumns" :filter="prefixFilter" formUrl="/formulaPrefix" deletedataToDisplay="name"
        @selectedItem="SelectedPrefix"></MainGrid>


    <b-modal id="modal-edit" ref="modal" title="Edit prefix" @ok="saveNewPrefix">
        <form ref="form">
            <b-form-group label="Name" label-for="name-input">
                <b-form-input v-can:input="'Update_FormulaPrefix'" id="name-input" v-model="selectedItem.name"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from 'axios'
import MainGrid from '../components/MainGrid'
import Feedback from '../mixins/Feedback.js'

window.axios = require("axios");

export default {
    name: 'SetupPrefixFormula',

    mixins: [Feedback],

    components: {
        MainGrid,
    },
    data() {
        return {

            prefixColumns: [{
                    key: 'addBy',
                    label: this.$t('general.addBy'),
                    sortable: true,
                },
                {
                    key: 'modBy',
                    label: this.$t('general.modBy'),
                    sortable: true,
                },
                {
                    key: 'modDate',
                    label: this.$t('general.modOn'),
                    sortable: true,
                    slot_name: "mod-date"
                },
                {
                    key: 'name',
                    label: this.$t('prefix.prefix'),
                    sortable: true,
                },
                {
                    key: "modalButton",
                    label: ""
                }
            ],

            prefixFilter: [{
                method: "findByNameContainingIgnoreCase",
                params: ["name"]
            }],

            selectedItem: {}
        }
    },
    methods: {
        SelectedPrefix(item) {
            this.selectedItem = item
        },
        saveNewPrefix() {
            let data = {
                name: this.selectedItem.name
            }
            let id = this.selectedItem._links.self.href.split("/").pop();
            let endpoint = 'formulaPrefix/' + id

            try {
                axios.put(endpoint, data)
                    .then(
                        this.succesAlert()
                    )
            } catch (error) {
                console.log(error);
            }
        },

    }
}
</script>

<style>
.btn-icon {
    cursor: pointer;
    margin: 0px 5px;
}
</style>
