<template>
<div v-can="'Read_Material'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <ListingGrid :column="materialColumn" :endpoint=' "/material/search/findByIngredientId?ingredientId=" + this.$route.params.id + "&searchValue=" ' dataObject="entityModels" redirectUrl="/material" :parentData="this.$route.params.id"></ListingGrid>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ListingGrid from '../components/ListingGrid.vue';

export default {
    name: 'MaterialGrid',
    components: {
        ListingGrid
    },
    data() {
        return {
        title: 'Raw Materials',
        materialColumn: [{
                label: this.$t('material.tradeName'),
                key: 'tradeName',
                sortable: true
            },
            {
                label: this.$t('general.percentage'),
                key: 'percentage',
                sortable: true
            },
            {
                label: this.$t('general.status'),
                key: 'status',
                sortable: true
            },
            {
                key: 'goTo',
                sortable: false
            }]
        }
    },
    props: {
        ingredientId: {
            type: String,
            required: true
        }
    }
}
</script>

<style>
.btn-icon{
  cursor: pointer;
  margin: 0px 5px;
}

</style>