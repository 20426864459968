<template>
<div>
    <div class="container login-container">
        <div class="row justify-content-md-center">
            <div class="col-md-6 login-form">
                <div class="row justify-content-md-center mb-3">
                    <img src="../assets/galenit-logo.png" class="logo" />
                </div>
                <h3>Login</h3>
                <form @keyup.enter="login()">
                    <div class="form-group">
                        <input type="text" class="form-control username" v-model="input.username" placeholder="Username" />
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control password" v-model="input.password" placeholder="Password" />
                        <div v-show="$store.getters.isCredentialIncorrect" id="credential-incorrect" class="error">
                            {{$t('login.incorrect')}}
                        </div>
                        <div v-show="credentialMissing" id="credential-missing" class="error">
                            {{$t('login.mustBePresent')}}
                        </div>
                        <div v-show="$store.getters.getLicence.isValid == false" id="credential-incorrect" class="error mt-2">
                            {{$t('login.licenceExpired')}}
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="button" class="btnSubmit float-right btn btn-primary" @click.prevent="login()">Login</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import {
    mapActions
} from "vuex";

export default {
    name: 'Login',

    data() {
        return {
            input: {
                username: "",
                password: ""
            },
            credentialMissing: false
        }
    },

    computed: {
        test: function() {
            return this.input.username
        }
    },

    methods: {
        ...mapActions(["logIn"]),

        async login() {
            this.credentialMissing = false;

            if (this.input.username != "" && this.input.password != "") {

                let user = {
                    username: this.input.username,
                    password: this.input.password
                }
                try {
                    axios.defaults.withCredentials = true;
                    await this.logIn(user);
                } catch (error) {
                    console.log(error);
                    axios.defaults.withCredentials = false;
                }

            } else {
                this.credentialMissing = true
            }
        }
    },
}
</script>

<style scoped>
.logo {
    max-width: 90%
}

.login-container {
    margin-top: 1%;
    margin-bottom: 5%;
}

.login-form {
    padding: 5%;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
    background-color: var(--login-container-background);
}

.login-form h3 {
    text-align: center;
    color: var(--text-input-text);
}

.login-container form {
    padding: 10%;
}

.btnSubmit {
    width: 40%;
    border-radius: 1rem;
    padding: 1.5%;
    font-weight: 600;
}

.error {
    color: red;
    font-weight: bold;
    font-size: small;
}
</style>
