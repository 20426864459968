import { render, staticRenderFns } from "./Ingredient.vue?vue&type=template&id=b933a106&scoped=true&"
import script from "./Ingredient.vue?vue&type=script&lang=js&"
export * from "./Ingredient.vue?vue&type=script&lang=js&"
import style0 from "./Ingredient.vue?vue&type=style&index=0&id=b933a106&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b933a106",
  null
  
)

export default component.exports