<template>

  <footer class="footer mt-auto py-3">
    <div class="text-center container">
      <span class="text-muted">Galenit {{year}} - {{ $store.getters.appVersion }}</span>
    </div>
  </footer>

</template>

<script>
export default {
    name: 'AppFooter',
    data() {
        return {
            year: this.setYear()
        };
    },
    methods: {
      setYear(){
        var date = new Date();
        return date.getFullYear();
      }
    }
}

</script>
