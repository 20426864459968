<template>
<div v-can="'Read_User'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-left">{{$tc('user.user', 2)}}</h1>
        <MainGrid gridTitle="Users" objectNodeName="user" endpoint="user" :gridColumns="columns" :filter="filter" formUrl="/user" :queryByFilter="true" :customOrderBy="true" deletedataToDisplay="userName" :deleteAssociatedData="$store.state.project.associatedData"></MainGrid>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MainGrid from '../components/MainGrid'

export default {
  name: 'Users',
  components: {
    MainGrid
  },
  data() {
    return {
      title: 'Users',
      columns: [
        {
          label: this.$t('contact.firstname'),
          key: "userName",
          sortable: true,
          initial_sort: true
        },
        {
          label: this.$t('contact.firstname'),
          key: "firstName",
          sortable: true,
        },
        {
          label: this.$t('contact.lastName'),
          key: "lastName",
          sortable: true,
        },
        {
          label: this.$t('contact.email'),
          key: "mail",
          sortable: true,
        },
        {
          key: "actionButtons",
        }
      ],
      filter: [{
        method: "findByUserNameContainingIgnoreCaseOrFirstNameContainingIgnoreCaseOrLastNameContainingIgnoreCaseOrEmailContainingIgnoreCase",
        params: ["userName","firstName","lastName","email"]
      }]
    }
  }
}
</script>

<style>
.btn-icon{
  cursor: pointer;
  margin: 0px 5px;
}

</style>
