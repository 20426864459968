<template>
<div v-can="'Read_Regulation'">

    <MainGrid :gridTitle="$tc('general.regulation', 2)" objectNodeName="entityModels" endpoint="regulation" :gridColumns="columns" :filter="filter" formUrl="/regulation" :queryByFilter="true" :customOrderBy="true" deletedataToDisplay="name"></MainGrid>

</div>
</template>

<script>

import MainGrid from '../components/MainGrid'
import Feedback from '../mixins/Feedback.js'

export default {
    name: 'RegulationsRegulationsGrid',

    mixins: [Feedback],

    components: {
        MainGrid,
    },
    data() {
        return {
            title: 'RegulationsRegulationGrid',

            columns: [{
                    label: this.$t('regulation.regulationName'),
                    key: "name",
                    sortable: true,
                },
                // {
                //   label: this.$t('regulation.countryName'),
                //   key: "countryName",
                //   sort: true,
                // },
                {
                    key: "actionButtons",
                    label: "",
                }
            ],
            filter: [{
                method: "findByNameOrCountry",
                params: ["searchValue"]
            }],

            selectedItem: {}
        }
    },
    methods: {

    }
}
</script>

<style>
.btn-icon {
    cursor: pointer;
    margin: 0px 5px;
}
</style>
