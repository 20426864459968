<template>
<div id="app" class="bg-background text-contrast">
  <NavBar />
  <router-view class="views-padding" />
  <AppFooter id="footer" />
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import AppFooter from '@/components/AppFooter.vue'


export default {
  components: {
    NavBar,
    AppFooter
  },
  data() {
    return {

    }
  },
  mounted() {

    if (localStorage.mode == "dark") {
      import("@/scss/dark.scss/");
    } else if (localStorage.mode == "contrast") {
      import("@/scss/contrast.scss/");
    } else {
      import("@/scss/app.scss/");
    }

  },

  methods: {


  }
}
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    position: relative;
    min-height: 100vh;
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
}

.nav-link {
    font-weight: 600;
}

.form-control {
    background-color: var(--input-text-background) !important;
    color: var(--text-input-text) !important;
}

.clickable {
    cursor: pointer;
}

.views-padding {
    padding: 4rem 1px 2.5rem 1rem;
}

.loader {
    /* Loader Div Class */
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #eceaea;
    background-image: url("assets/ajax-loader.gif");
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);
}

/* b-table component styling */

.b-table tbody tr:hover {
    background: var(--b-table-row-background-hover) !important;
    color: var(--text-mainGrid-over) !important;
}

.b-table thead th {
    color: var(--text-mainGrid) !important;
}

/* v-select component styling */

.vselect .vs__selected {
    color: var(--text-vselect) !important;
}

.vselect .vs__clear,
.vselect .vs__open-indicator {
    fill: var(--text-vselect) !important;
}

.vselect .vs__dropdown-option {
    font-weight: normal;
    color: var(--text-vselect);
    background: var(--input-text-background);
}

.vselect .vs__dropdown-option:hover {
    font-weight: normal;
    color: var(--text-vselect-dropdown-over) !important;
    background: var(--background-vselect-dropdown-over) !important;
}

.vselect .vs__dropdown-toggle {
    height: calc(1.5em + 0.75rem + 2px);
    font-weight: normal !important;
    line-height: 1.5;
    background: var(--input-text-background)!important;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: var(--vselect-border);
}

.vselect-invalid .vs__dropdown-toggle {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: crimson;
}

.vselect-valid .vs__dropdown-toggle {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: #29a745;
}
</style>
