<template>
<div>

    <b-badge pill :variant="variant" class="width-badge">{{statusMsg}}</b-badge>

</div>
</template>

<script>
export default {
    name: 'PillStatus',
    data() {
        return {

        }
    },
    props: {
        status: {
            required: true,
            default: "FAILED"
        },

    },
    computed: {
        variant: function() {

            let statusCapitalized = this._props.status.toUpperCase()

            if (statusCapitalized == "IN_DEVELOPMENT" || statusCapitalized == "IN DEVELOPMENT" || statusCapitalized == "TENTATIVE") {
                return "info"
            } else if (statusCapitalized == "SUBMITTED" || statusCapitalized == "PENDING" || statusCapitalized == "REVIEWED") {
                return "primary"
            } else if (statusCapitalized == "IN_REVIEW" || statusCapitalized == "IN REVIEW") {
                return "secondary"
            } else if (statusCapitalized == "ACCEPTED" || statusCapitalized == "PASS" || statusCapitalized == "ALLOWED" || statusCapitalized == "VALIDATED") {
                return "success"
            } else if (statusCapitalized == "RESTRICTED") {
                return "warning"
            } else if (statusCapitalized == "REJECT" | statusCapitalized == "PROHIBITED") {
                return "danger"
            } else if (statusCapitalized == "FAILED" || statusCapitalized == "FAIL" || statusCapitalized == "ABANDONED") {
                return "dark"
            } else {
                return "warning"
            }

        },

        statusMsg: function() {
            let statusCapitalized = this._props.status.toUpperCase()

            if (statusCapitalized == "IN_DEVELOPMENT" || statusCapitalized == "IN DEVELOPMENT") {
                return this.$t('status.inDevelopment').toUpperCase()
            } else if (statusCapitalized == "SUBMITTED") {
                return this.$t('status.submitted').toUpperCase()
            } else if (statusCapitalized == "IN_REVIEW" || statusCapitalized == "IN REVIEW") {
                return this.$t('status.inReview').toUpperCase()
            } else if (statusCapitalized == "ACCEPTED") {
                return this.$t('status.accepted').toUpperCase()
            } else if (statusCapitalized == "REJECT") {
                return this.$t('status.reject').toUpperCase()
            } else if (statusCapitalized == "VALIDATED") {
                return this.$t('status.validated').toUpperCase()
            } else if (statusCapitalized == "FAILED" || statusCapitalized == "FAIL") {
                return this.$t('status.failed').toUpperCase()
            } else if (statusCapitalized == "PASS") {
                return this.$t('status.pass').toUpperCase()
            } else if (statusCapitalized == "PENDING") {
                return this.$t('status.pending').toUpperCase()
            } else if (statusCapitalized == "TENTATIVE") {
                return this.$t('status.tentative').toUpperCase()
            } else if (statusCapitalized == "REVIEWED") {
                return this.$t('status.reviewed').toUpperCase()
            } else if (statusCapitalized == "RESTRICTED") {
                return this.$t('status.restricted').toUpperCase()
            } else if (statusCapitalized == "ALLOWED") {
                return this.$t('status.allowed').toUpperCase()
            } else if (statusCapitalized == "PROHIBITED") {
                return this.$t('status.prohibited').toUpperCase()
            } else if (statusCapitalized == "ABANDONED") {
                return this.$t('status.abandoned').toUpperCase()
            } else {
                return this.$t('status.unknown').toUpperCase()
            }
        },

    },
    methods: {


    },
    created() {}
}
</script>

<style scoped>

.width-badge{
    width: 120px;
}

</style>
