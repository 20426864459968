<template>
<div>
  <b-dropdown variant="outline-primary" size="sm" text="Converter" right class="m-2">
    <b-dropdown-form>

      <div class="form-group row" style="width: 400px">
        <div class="col-sm-8">
          <input type="text" @keyup="convertion(input)" class="form-control input-data" v-model.number="input">
        </div>
        <div class="col-sm-4">
          <b-form-select v-model="selectedInputUnit" id="input-unit" @change="convertion(input)" :options="inputUnit" />
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-8">
          <input type="text" class="form-control result-data" placeholder="%" v-model.number="percent">
        </div>
        <label class="col-sm-4 col-form-label">%</label>
      </div>

    </b-dropdown-form>
  </b-dropdown>
</div>
</template>

<script>
export default {
  name: 'Converter',

  data() {
    return {

      input: null,
      percent: null,

      selectedInputUnit: 'ppm',
      inputUnit: [
        {
          value: 'decimal',
          text: 'decimal'
        },
        {
          value: 'ppm',
          text: 'ppm'
        },
        {
          value: 'ppb',
          text: 'ppb'
        },
        {
          value: 'g/L',
          text: 'g/L'
        },
        {
          value: 'mg/L',
          text: 'mg/L'
        },
        {
          value: 'mL/L',
          text: 'mL/L'
        },
        {
          value: 'mg/mL',
          text: 'mg/mL'
        },
        {
          value: 'ug/mL',
          text: '\u03BCg/mL'
        },
      ]
    };
  },

  methods: {

    convertion(value) {

      switch (this.selectedInputUnit) {
        case 'decimal':
          this.percent = value * 0.01;
          break;
        case 'ppm':
          this.percent = value * 0.0001;
          break;
        case 'ppb':
          this.percent = value * 0.0000001;
          break;
        case 'g/L':
          this.percent = value * 0.1;
          break;
        case 'mg/L':
          this.percent = value * 0.0001;
          break;
        case 'mL/L':
          this.percent = value * 0.1;
          break;
        case 'mg/mL':
          this.percent = value * 0.1;
          break;
        case 'ug/mL':
          this.percent = value * 0.0001;
          break;
        default:
          this.percent = null;
      }


    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
